import { makeRequest } from "../../jason-proof-of-concept/shared/utils";

export const triggerAi = async ({ authToken, id }: { authToken: string; id: number }) => {
    const response = await makeRequest({
        method: "post",
        path: `/process-mandates/${id}`,
        authToken,
    });

    return true;
};
