import { useEffect, useState } from "react";
import { Entry } from "../../../layout/add-to-list";
import BadgeDismissible from "../../../layout/badge-dismissible";
import ButtonNeoGen from "../../../layout/button-neogen";
import InputControlled from "../../../layout/input-controlled";
import ModalDialog from "../../../layout/modal-dialog";
import ModalSingleText from "../../../layout/modal-single-text";
import PrintPre from "../../../layout/print-pre";
import SelectNeoGen from "../../../layout/select-neogen";
import TableNeogen from "../../../layout/table-neogen";
import TextAreaNeoGenControlled from "../../../layout/text-area-controlled";
import AddCalculation from "./add-calculation";
import { v4 as uuidv4 } from "uuid";
import { SaveDetailsType } from "../../../typings/save-details-type";

export type Calculation = {
    field1: string;
    field2: string;
    operator: string;
    field1number: string | undefined;
    field2number: string | undefined;
};

export default function ModalValueCalculator(props: ModalValueCalculatorProps) {
    const [field, setField] = useState<string>(props.field ?? "");
    const [label, setLabel] = useState<string>(props.value ?? "");
    const [description, setDescription] = useState<string>(props.description ?? "");
    const [selectBoxEntries, setSelectBoxEntries] = useState<string[]>(
        props.entry?.selectBoxEntries ? props.entry.selectBoxEntries : [],
    );
    const [showAddCalculation, setShowAddCalculation] = useState<boolean>(false);
    const [required, setRequired] = useState<boolean>(props.entry?.required ?? false);
    const [widthOf300Cols, setWidthOf300Cols] = useState<string>(props.entry?.widthOf300Cols ?? "150");
    const [calculations, setCalculations] = useState<Calculation[]>([]);
    // const [calculations, setCalculations] = useState<string[]>(props.entry?.calculations ?? []);

    useEffect(() => {
        setCalculations(JSON.parse(props.entry?.value ?? "[]") ?? []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    function saveRecord() {
        // console.log(data);
        if (props.isEdit && props.saveEdit) {
            props.saveEdit({
                field,
                value: JSON.stringify(calculations),
                description,
                required,
                widthOf300Cols,
                selectBoxEntries,
                helpDocument: props.entry?.helpDocument ?? "",
                password: false,
                ruleGroupsAreOr: props.entry?.ruleGroupsAreOr ?? false,
                defaultVal: "",
                hideWhenFilled: false,
            });
        } else {
            props.save({
                field,
                value: JSON.stringify(calculations),
                description,
                required,
                widthOf300Cols,
                selectBoxEntries,
                helpDocument: props.entry?.helpDocument ?? "",
                password: false,
                ruleGroupsAreOr: props.entry?.ruleGroupsAreOr ?? false,
                defaultVal: "",
                hideWhenFilled: false,
            });
        }
    }

    function showAddCalculationModal() {
        setShowAddCalculation(true);
    }

    function addNewCalculation(
        field1: string,
        field2: string,
        operator: string,
        field1number?: string | undefined,
        field2number?: string | undefined,
    ): void {
        const calculation = {
            field1,
            field1number,
            operator,
            field2,
            field2number,
        };
        setCalculations([...calculations, calculation]);
        setShowAddCalculation(false);
    }

    return (
        <>
            <ModalDialog
                title={props.title}
                close={props.close}
                size="md"
                show={props.show}
                okAction={saveRecord}
                okText="Close"
                showCancel={false}
            >
                <InputControlled label="Calculation Name" value={field} setValue={setField} required={true} />
                <ButtonNeoGen onClick={showAddCalculationModal}>Add Calculation</ButtonNeoGen>
                {/* <PrintPre>
                    {calculations}
                </PrintPre> */}
                <TableNeogen
                    entries={calculations.map((e: any, idx: number) => {
                        return {
                            calculation: (idx + 1).toString(),
                            field1: e.field1,
                            operator: e.operator,
                            field2: e.field2,

                            field1number: e.field1number,
                            field2number: e.field2number,
                        };
                    })}
                />
            </ModalDialog>
            <AddCalculation
                entries={props.entries}
                show={showAddCalculation}
                close={() => setShowAddCalculation(false)}
                addCalculation={addNewCalculation}
                calculations={calculations}
            />
        </>
    );
}

type ModalValueCalculatorProps = {
    title: string;
    show: boolean;
    close: () => void;
    label: string;
    description: string;
    name: string;
    isEdit?: boolean;
    field?: string;
    value?: string;
    widthOf300Cols: string;
    entries: Entry[];
    save: ({
        field,
        value,
        description,
        required,
        widthOf300Cols,
        selectBoxEntries,
        helpDocument,
        password,
        ruleGroupsAreOr,
    }: SaveDetailsType) => void;
    saveEdit?: ({
        field,
        value,
        description,
        required,
        widthOf300Cols,
        selectBoxEntries,
        helpDocument,
        password,
        ruleGroupsAreOr,
    }: SaveDetailsType) => void;
    // isSelect?: boolean;
    entry: Entry | undefined;
};
