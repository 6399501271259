import { useMutation } from "@tanstack/react-query";
import { deleteCompanyLineItem } from "../actions/delete-company-line-item";

export const useDeleteCompanyLineItem = () => {
    const mutation = useMutation({
        mutationFn: async ({ authToken, id }: { authToken: string; id: number }) => {
            await deleteCompanyLineItem({ authToken, id });
        },
    });
    return mutation;
};
