import React, { useMemo, useState } from "react";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import ModalDialog from "../../layout/modal-dialog";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutFormContents from "./checkoutFormContents";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");
export default function CheckoutForm({
    show,
    close,
    clientSecret,
}: {
    show: boolean;
    close: () => void;
    clientSecret: string;
}): JSX.Element {
    const options = useMemo(() => {
        return {
            // passing the client secret obtained in step 3
            clientSecret: clientSecret,
            // Fully customizable with appearance API.
            appearance: {
                /*...*/
            },
        };
    }, [clientSecret]);
    return (
        <ModalDialog title="Checkout" close={close} show={show}>
            <Elements stripe={stripePromise} options={options}>
                <CheckoutFormContents clientSecret={clientSecret} />
            </Elements>
        </ModalDialog>
    );
}
