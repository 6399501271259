import PropTypes from "prop-types";
import React, { Component } from "react";

import { EditorState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import ButtonNeoGen from "../../../layout/button-neogen";
import ResponseVariableModal from "./response-variable-modal";
class CustomOption extends Component {
    static propTypes = {
        editorState: PropTypes.object,
        onChange: PropTypes.func,
    };
    constructor(props) {
        super(props);
        this.state = {
            showResponseVariable: false,
            processFlowGroup: 0,
            fieldName: "",
        };
    }
    // const [showResponseVariable, setShowResponseVariable] = React.useState(false);

    addStar = () => {
        const { editorState, onChange } = this.props;
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            "⭐",
            editorState.getCurrentInlineStyle(),
        );
        onChange(EditorState.push(editorState, contentState, "insert-characters"));
    };

    addResponseVariable = (value) => {
        const { editorState, onChange } = this.props;
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            "{{" + value + "}}",
            editorState.getCurrentInlineStyle(),
        );
        onChange(EditorState.push(editorState, contentState, "insert-characters"));
    };

    render() {
        // return <div onClick={this.addStar}>Variable</div>;
        return (
            <>
                <ButtonNeoGen
                    className="mb-2"
                    onClick={() => {
                        this.setState({ showResponseVariable: true });
                    }}
                >
                    Insert Variable
                </ButtonNeoGen>
                <ResponseVariableModal
                    setValue={this.addResponseVariable}
                    show={this.state.showResponseVariable}
                    hide={() => this.setState({ showResponseVariable: false })}
                />
            </>
        );
    }
}
export default CustomOption;
