import { useQuery, useQueryClient } from "@tanstack/react-query";
import lineItemsService, { LineItemsType } from "../services/line-items.service";
import TableNeogen from "../layout/table-neogen";
import PageDescription from "../layout/page-description";
import { useState } from "react";
import AddEditLineItem from "./modals/addEditLineItem";
import SwalNeogenFire from "../layout/swal-neogen";

function LineItems() {
    const [showAddLineItem, setShowAddLineItem] = useState(false);
    const [lineItem, setLineItem] = useState<LineItemsType | undefined>(undefined);
    const queryCache = useQueryClient();
    const lineItemsQuery = useQuery(["lineItems"], async () => {
        const response = await lineItemsService.getAll();
        if (response) {
            return response.data;
        }
    });
    if (lineItemsQuery.isLoading) {
        return <div>Loading...</div>;
    }
    return (
        <>
            <PageDescription
                title="Line Items"
                description="Billing Line Items"
                buttons={[
                    {
                        label: "Add Line Item",
                        onClick: () => {
                            setLineItem(undefined);
                            setShowAddLineItem(true);
                        },
                        icon: "fal fa-plus",
                    },
                ]}
            />
            <TableNeogen
                entries={lineItemsQuery.data || []}
                formatters={[
                    { field: "isCommisionable", type: "Boolean" },
                    { field: "isPublic", type: "Boolean" },
                ]}
                actions={[
                    {
                        label: "Edit",
                        onClick: (lineItem) => {
                            setLineItem(lineItemsQuery.data?.find((li) => li.id === lineItem));
                            setShowAddLineItem(true);
                        },
                    },
                    {
                        label: "Delete",
                        type: "danger",
                        onClick: async (lineItem) => {
                            // delete
                            SwalNeogenFire({
                                title: "Are you sure?",
                                text: "This action is irreversible",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Yes, delete it!",
                                cancelButtonText: "No, cancel!",
                            }).then(async (result) => {
                                if (result.isConfirmed) {
                                    await lineItemsService.deleteByID(lineItem);
                                    queryCache.invalidateQueries(["lineItems"]);
                                }
                            });
                        },
                    },
                ]}
            />
            <AddEditLineItem
                show={showAddLineItem}
                close={() => setShowAddLineItem(false)}
                title="Add Line Item"
                lineItem={lineItem}
            />
        </>
    );
}

export default LineItems;
