import { makeRequest } from "../../jason-proof-of-concept/shared/utils";

export const deleteHelpDocTemplate = async ({ id, authToken }: { id: number; authToken: string }) => {
    const response = await makeRequest({
        method: "delete",
        path: `/help-docs-templates/${id}`,
        authToken,
    });

    return response;
};
