/* eslint-disable react/prop-types */
import React, { Component } from "react";
import ReactDOM from "react-dom";

import { FixedSizeList as List } from "react-window";

// import "./select-virtualized.css";

const height = 35;

export class MenuList extends Component {
    render() {
        const { options, children, maxHeight, getValue } = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;

        return (
            <List height={maxHeight} itemCount={children.length} itemSize={height} initialScrollOffset={initialOffset}>
                {({ index, style }) => (
                    <div style={style} className="dark:bg-slate-900">
                        {children[index]}
                    </div>
                )}
            </List>
        );
    }
}

// export const SelectVirtualized = (options) =>;
