import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { helpDocsTemplateItemSchema } from "../domain/help-docs-template-item";

export const getHelpDocsTemplateItems = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: `/help-docs-template-items`,
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });
    const helpDocsTemplateItems = parseSchema(z.array(helpDocsTemplateItemSchema), response.data);
    return helpDocsTemplateItems;
};
