import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { userResponseSchema } from "../../jason-proof-of-concept/users/actions/get-users";

export const setCompanyIndustries = async ({
    authToken,
    id,
    data,
}: {
    authToken: string;
    id: number;
    data: { industryIds: number[] };
}) => {
    const response = await makeRequest({
        method: "post",
        path: `/companies/${id}/set-industries`,
        authToken,
        data,
    });
};
