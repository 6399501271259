import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { helpDocsTemplateSchema } from "../domain/help-docs-template";
import { helpDocsTemplateItemSchema } from "../domain/help-docs-template-item";

const updateHelpDocsTemplateSchema = helpDocsTemplateSchema
    .partial({ id: true })
    .extend({ templateItems: z.array(helpDocsTemplateItemSchema) });

export type UpdateHelpDocsTemplateData = z.infer<typeof updateHelpDocsTemplateSchema>;

export const updateHelpDocTemplate = async ({
    id,
    authToken,
    data,
}: {
    id: number;
    authToken: string;
    data: UpdateHelpDocsTemplateData;
}) => {
    const response = await makeRequest({
        method: "patch",
        path: `/help-docs-templates/${id}`,
        authToken,
        data,
    });

    return response;
};
