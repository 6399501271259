import { useQuery } from "@tanstack/react-query";
import lineItemsService from "../../services/line-items.service";

export const useLineItems = () => {
    const query = useQuery({
        queryKey: ["lineItems"],
        queryFn: async () => {
            const lineItems = await lineItemsService.getAll();
            return lineItems;
        },
    });

    return query;
};
