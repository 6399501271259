import APIService from "./api.service";

export type LineItemsType = {
    id?: number;
    name: string;
    description: string;
    isCommisionable: number;
    price: number;
    isPublic: number;
    percentageOfFinal?: number;
};

class LineItemsService extends APIService<LineItemsType> {
    constructor() {
        super("/line-items");
    }
}

export default new LineItemsService();
