import React, { useEffect, useState } from "react";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { SortableItem } from "./sortable-item";
import PageDescription from "../../layout/page-description";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import helpDocsTemplateItemsService from "../../services/help-docs-template-items.service";
import helpDocsTemplateService from "../../services/help-docs-template.service";
import helpDocsService from "../../services/help-docs.service";
import packageSectionRulesService from "../../services/package-section-rules.service";
import { NeoGenHelpDocs } from "../../typings/api";
import Loader2 from "../utilities/Loader2";
import { Select } from "../../layout/form/select-input";
import AddTemplate from "./modals/add-template";
import DeleteFaq from "./modals/delete-faq";

export default function HelpDoc() {
    const queryCache = useQueryClient();
    const [showDeleteFaq, setShowDeleteFaq] = useState(false);
    const [existing, setExisting] = useState<null | NeoGenHelpDocs>(null);
    const [selectedTemplate, setSelectedTemplate] = useState<number | null>(
        Number(window.localStorage.getItem("selectedTemplate")) ?? null,
    );
    const [helpDocs, setHelpDocs] = useState<NeoGenHelpDocs[]>([]);
    const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);
    const [editing, setEditing] = useState<boolean>(false);

    const navigate = useNavigate();
    const helpDocsTemplatesQuery = useQuery(["help-doc-templates"], async () => {
        const response = await helpDocsTemplateService.getAll();
        return response?.data ?? [];
    });
    const helpDocsTemplateItemsQuery = useQuery(["help-docs-template-items", selectedTemplate], async () => {
        const response = await helpDocsTemplateItemsService.getFilteredWhere({
            templateId: selectedTemplate,
        });
        return (
            response?.data.sort((a, b) => {
                if ((a?.order ?? 0) > (b?.order ?? 0)) return 1;
                if ((a?.order ?? 0) < (b?.order ?? 0)) return -1;

                return 0;
            }) ?? []
        );
    });
    const helpDocsQuery = useQuery(
        ["help-docs", "sorted"],
        async () => {
            const response = await helpDocsService.getAll();
            if (response) {
                return response.data.sort((a, b) => {
                    const templateItemA = helpDocsTemplateItemsQuery.data?.find((e) => e.helpDocId === a.id);
                    const templateItemB = helpDocsTemplateItemsQuery.data?.find((e) => e.helpDocId === b.id);
                    if ((templateItemA?.order ?? 0) > (templateItemB?.order ?? 0)) return 1;
                    if ((templateItemA?.order ?? 0) < (templateItemB?.order ?? 0)) return -1;

                    return 0;
                });
            }
        },
        { enabled: helpDocsTemplatesQuery.isSuccess && helpDocsTemplateItemsQuery.isSuccess },
    );

    useEffect(() => {
        // Create an array of helpDocs that are in the templateItemsQuery
        const helpDocTemplateSelected = helpDocsTemplateItemsQuery.data?.map((e) => e.helpDocId) ?? [];
        console.log({ helpDocTemplateSelected });
        const helpDocsFiltered = helpDocsTemplateItemsQuery.data
            ?.map((e) => {
                return helpDocsQuery.data?.find((h) => h.id === e.helpDocId);
            })
            // Sort by order field
            .sort((a, b) => {
                const templateItemA = helpDocsTemplateItemsQuery.data?.find((e) => e.helpDocId === a?.id);
                const templateItemB = helpDocsTemplateItemsQuery.data?.find((e) => e.helpDocId === b?.id);
                if ((templateItemA?.order ?? 0) > (templateItemB?.order ?? 0)) return 1;
                if ((templateItemA?.order ?? 0) < (templateItemB?.order ?? 0)) return -1;

                return 0;
            });
        console.log({
            helpDocsFiltered,
            helpDocsQuery: helpDocsQuery.data,
            helpDocsTemplateItemsQuery: helpDocsTemplateItemsQuery.data,
        });
        setHelpDocs((helpDocsFiltered as NeoGenHelpDocs[]) ?? []);
    }, [helpDocsQuery.data, helpDocsTemplateItemsQuery.data]);
    // const [items, setItems] = useState([1, 2, 3]);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    return (
        <>
            <div>
                <div>
                    {helpDocsQuery.isLoading ? (
                        <Loader2 />
                    ) : (
                        <>
                            <PageDescription
                                title="Sections"
                                description="Package Sections"
                                buttons={[
                                    {
                                        label: "Add Section",
                                        onClick: () => {
                                            navigate("/faq/new");
                                        },
                                    },
                                    // Add a template
                                    {
                                        label: "Add Template",
                                        onClick: () => {
                                            setEditing(false);
                                            setShowAddTemplateModal(true);
                                        },
                                    },
                                    // Edit Template (Basically the same as add template)
                                    {
                                        label: "Edit Template",
                                        onClick: () => {
                                            setEditing(true);
                                            setShowAddTemplateModal(true);
                                        },
                                    },
                                    // Reset Order
                                    {
                                        label: "Reset Order",
                                        onClick: () => {
                                            const templateItems = helpDocsTemplateItemsQuery.data;
                                            if (templateItems) {
                                                templateItems.forEach((item, idx) => {
                                                    item.order = idx;
                                                    helpDocsTemplateItemsService.update(item.id, item);
                                                });
                                                queryCache.invalidateQueries(["help-docs-template-items"]);
                                                helpDocsTemplateItemsQuery.refetch();
                                            }
                                        },
                                    },
                                ]}
                            />
                            <div className="mb-5">
                                <Select
                                    label="Template"
                                    options={
                                        helpDocsTemplatesQuery.data?.map((e) => {
                                            return {
                                                label: e.name ?? "",
                                                value: e.id ?? -1,
                                            };
                                        }) ?? []
                                    }
                                    onChange={(e) => {
                                        console.log(e);
                                        window.localStorage.setItem("selectedTemplate", (e ?? "").toString());
                                        setSelectedTemplate(Number(e) ?? null);
                                    }}
                                    value={selectedTemplate}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext
                    items={(helpDocsTemplateItemsQuery.data ?? []).map((i) => i.id ?? -1)}
                    strategy={verticalListSortingStrategy}
                >
                    {helpDocsTemplateItemsQuery.data?.map((item) => (
                        <SortableItem key={item.id} item={item} />
                    ))}
                </SortableContext>
            </DndContext>
            <AddTemplate
                show={showAddTemplateModal}
                close={() => setShowAddTemplateModal(false)}
                template={
                    editing
                        ? selectedTemplate
                            ? helpDocsTemplatesQuery.data?.find((e) => e.id === selectedTemplate)
                            : undefined
                        : undefined
                }
            />
        </>
    );

    function handleDragEnd(event: { active: any; over: any }) {
        const { active, over } = event;

        if (active.id !== over.id) {
            console.log({ active, over });
            // setItems((items) => {
            const oldIndex = helpDocsTemplateItemsQuery.data?.map((i) => i.id ?? -1).indexOf(active.id);
            const newIndex = helpDocsTemplateItemsQuery.data?.map((i) => i.id ?? -1).indexOf(over.id);
            console.log({ oldIndex, newIndex });
            console.log({ items: helpDocsTemplateItemsQuery.data });
            const newArray = arrayMove(helpDocsTemplateItemsQuery.data ?? [], oldIndex ?? -1, newIndex ?? -1);
            console.log({ newArray });
            for (const [idx, item] of newArray.entries()) {
                item.order = idx;
                helpDocsTemplateItemsService.update(item.id, item);
            }
            queryCache.invalidateQueries(["help-docs-template-items"]);
            helpDocsTemplateItemsQuery.refetch();
            // return arrayMove(helpDocsTemplateItemsQuery.data ?? [], oldIndex, newIndex);
            // });
        }
    }
}
