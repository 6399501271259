import APIService from "./api.service";

export type RepairStageCategoriesType = {
    id?: number;
    name: string;
    description: string;
    isPublic: number;
    isAccountant: number;
    requiredRoleGroup: number;
};

class RepairStageCategoriesService extends APIService<RepairStageCategoriesType> {
    constructor() {
        super("/repair-stage-categories");
    }
}

export default new RepairStageCategoriesService();
