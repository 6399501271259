import { useMutation } from "@tanstack/react-query";
import ModalDialog from "../../layout/modal-dialog";
import { triggerAi } from "../actions/trigger-ai";
import { useAuth } from "../../auth/use-auth";
import Loader2 from "../../sections/utilities/Loader2";

export const TriggerAIModal = ({
    onClose,
    mandateId,
    onProcessed,
}: {
    onClose: () => void;
    mandateId: number;
    onProcessed: () => void;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();

    const triggerAiMutation = useMutation({
        mutationFn: () => triggerAi({ authToken, id: mandateId }),
    });

    const handleTriggerAi = async () => {
        await triggerAiMutation.mutateAsync();
        onProcessed();
    };

    return (
        <ModalDialog title="TriggerAI" show close={onClose} okAction={handleTriggerAi} okText="Process Mandate">
            <div style={{ paddingBottom: 20 }}>Process this mandate with AI?</div>
            {triggerAiMutation.isLoading && <Loader2 />}
        </ModalDialog>
    );
};
