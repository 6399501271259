import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { getCompanyMandateAssignments } from "../actions/get-company-mandate-assignments";
import { VmaFilters, getVirtualMandateAssignments } from "../actions/get-virtual-mandate-assignments";
import { useMemo } from "react";
import { uniqBy } from "lodash";
import { VirtualMandateAssignment } from "../domain/company-mandate-assignment";

type Params = {
    authToken: string;
    filters?: any;
};

const getCompanyMandateAssignmentsQueryKey = ({ authToken, filters }: Params) => {
    return ["companyMandateAssignments", { authToken, filters }];
};

export const useCompanyMandateAssignments = ({ authToken, filters }: Params, options?: { enabled?: boolean }) => {
    const query = useQuery({
        queryKey: getCompanyMandateAssignmentsQueryKey({ authToken, filters }),
        queryFn: async () => {
            const companyMandateAssignments = await getCompanyMandateAssignments({ authToken, filters });
            return companyMandateAssignments;
        },
        keepPreviousData: true,
        ...options,
    });
    return query;
};

const getVirtualMandateAssignmentsQueryKey = ({ authToken, filters }: Params) => {
    return ["virtualMandateAssignments", { authToken, filters }];
};

export const useVirtualMandateAssignments = (
    { authToken, filters }: { authToken: string; filters?: VmaFilters },
    options?: { enabled?: boolean },
) => {
    const query = useQuery({
        queryKey: getVirtualMandateAssignmentsQueryKey({ authToken, filters }),
        queryFn: async () => {
            const vmas = await getVirtualMandateAssignments({ authToken, filters });
            return vmas.data;
        },
        keepPreviousData: true,
        ...options,
    });
    return query;
};

const getInfiniteMandateAssignmentsQueryKey = ({ authToken, filters }: Params) => {
    return ["virtualMandateAssignments", { infinite: true, authToken, filters }];
};

export const useInfiniteVirtualMandateAssignments = ({ authToken, filters }: Params) => {
    const query = useInfiniteQuery({
        queryKey: getInfiniteMandateAssignmentsQueryKey({ authToken, filters }),
        getNextPageParam: (lastPage: {
            hasNextPage: boolean;
            data: VirtualMandateAssignment[];
            pageNumber: number;
        }) => {
            return lastPage.hasNextPage ? lastPage.pageNumber + 1 : lastPage.pageNumber;
        },
        keepPreviousData: true,
        queryFn: async ({ pageParam = 0 }) => {
            const limit = 30;
            const offset = pageParam * limit;
            const response = await getVirtualMandateAssignments({
                authToken,
                filters: { ...filters, limit, offset },
            });
            return { ...response, hasNextPage: true, pageNumber: pageParam };
        },
    });

    const mandatePages = useMemo(() => query.data?.pages || [], [query.data?.pages]);
    const vmas = useMemo(
        () =>
            uniqBy(
                mandatePages.reduce<VirtualMandateAssignment[]>((acc, page) => [...acc, ...page.data], []),
                (vma) => vma.id,
            ),
        [mandatePages],
    );

    return { ...query, vmas };
};
