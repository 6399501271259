import { useQuery } from "@tanstack/react-query";
import PageDescription from "../../../layout/page-description";
import userAtom from "../../../atoms/userAtom";
import { useRecoilState } from "recoil";
import processflowProgressService from "../../../services/processflow-progress.service";
import Loader3 from "../../utilities/Loader3";
import PrintPre from "../../../layout/print-pre";
import processflowGroupService from "../../../services/processflow-group.service";
import TableNeogen from "../../../layout/table-neogen";
import { ProcessFlowProgressData } from "../../../typings/api/processflow-progress-data";
import { ProcessFlowProgress } from "../../../typings/api/processflow-progress";

export default function DeletePP() {
    const [loggedInUser, setUser] = useRecoilState(userAtom);
    const ppQuery = useQuery(["processflow-progress", loggedInUser.id], async () => {
        const response = await processflowProgressService.getAllProgress(loggedInUser.id ?? "");
        if (response) {
            return response.data;
        }
        return [];
    });
    const ppGroupQuery = useQuery(["processflow-progress-group"], async () => {
        const response = await processflowGroupService.getAll();
        if (response) {
            return response.data;
        }
        return [];
    });
    return (
        <>
            <PageDescription
                title="Delete Processflow Progress"
                description="This page allows you to permanentlty remove any processflow progress from your account."
            />
            {ppQuery.isLoading || ppGroupQuery.isLoading ? (
                <Loader3 />
            ) : (
                <>
                    {/* <PrintPre>{ppGroupQuery.data}</PrintPre> */}
                    {/* <PrintPre>{ppQuery.data}</PrintPre> */}
                    <TableNeogen
                        entries={
                            ppQuery.data?.map((e) => {
                                return {
                                    id: e.id,
                                    // id2: e.id,
                                    // name: e.name,
                                    group: ppGroupQuery.data?.find((g) => g.id === e.group)?.name ?? "Unknown",
                                    data: e.data.substring(0, 100),
                                    // action: () => {
                                    //     processflowProgressService.delete(e.id ?? 0).then(() => {
                                    //         ppQuery.refetch();
                                    //     });
                                    // },
                                };
                            }) ?? []
                        }
                        actions={[
                            {
                                label: "Delete",
                                onClick: (id) => {
                                    const e = ppQuery.data?.find((e) => e.id === id);
                                    processflowProgressService.deleteByID(id).then(() => {
                                        const group = ppGroupQuery.data?.find((g) => g.id === e?.group);
                                        if (group) {
                                            const blankEntry: ProcessFlowProgress = {
                                                // completedSteps: [],
                                                // entriesByField: {},
                                                data: JSON.stringify({
                                                    completedSteps: [],
                                                    entriesByField: {},
                                                    group: group.id ?? -1,
                                                }),
                                                group: group.id ?? -1,
                                                userId: loggedInUser.id,
                                                client: 0,
                                                currentStep: 0,
                                                currentStage: 0,
                                            };
                                            processflowProgressService.create(blankEntry).then(() => {
                                                ppQuery.refetch();
                                            });
                                        }
                                    });
                                },
                            },
                        ]}
                    />
                </>
            )}
        </>
    );
}
