/* eslint-disable indent */
import { z } from "zod";
import { makeRequest, parseSchema } from "../../shared/utils";
import { processflowProgressSchema } from "../domain/processflow-progress";

export const processflowProgressResponseSchema = z.object({
    id: z.number(),
    group: z.number(),
    currentStep: z.number().nullable().optional(),
    currentStage: z.number().nullable().optional(),
    userId: z.string(),
    data: z.any().nullable().optional(),
    status: z.string().nullable().optional(),
    client: z.number().nullable().optional(),
});

export type ProcessflowProgressData = z.infer<typeof processflowProgressResponseSchema>;

const getProcessflowProgressesResponseSchema = z.array(processflowProgressResponseSchema);

export const getProcessflowProgresses = async ({
    authToken,
    filters,
}: {
    authToken?: string;
    filters?: {
        id?: number;
        userId?: string;
        userIds?: string[];
        group?: number;
        companyId?: number;
        companyIds?: number[];
    };
}) => {
    try {
        const response = await makeRequest({
            method: "get",
            path: "/processflow-progresses",
            authToken,
            queryParams: filters
                ? {
                      filter: JSON.stringify({
                          where: {
                              and: [
                                  ...(filters.id ? [{ id: filters.id }] : []),
                                  ...(filters.userId ? [{ userId: filters.userId }] : []),
                                  ...(filters.group !== undefined ? [{ group: filters.group }] : []),
                                  ...(filters.userIds ? [{ userId: { inq: filters.userIds } }] : []),
                                  ...(filters.companyId ? [{ companyId: filters.companyId }] : []),
                                  ...(filters.companyIds ? [{ companyId: { inq: filters.companyIds } }] : []),
                              ],
                          },
                          order: ["id DESC"],
                      }),
                  }
                : undefined,
        });

        const processflowProgressDtos = parseSchema(getProcessflowProgressesResponseSchema, response.data);

        const processflowProgresses = parseSchema(
            z.array(processflowProgressSchema),
            processflowProgressDtos.map((dto) => {
                try {
                    const d = JSON.parse(dto.data);
                    return {
                        ...dto,
                        data: dto.data
                            ? {
                                  ...d,
                                  id: dto.id,
                              }
                            : undefined,
                    };
                } catch (e) {
                    console.error(`Failed to parse JSON for pf-progress.`, dto);
                    throw e;
                }
            }),
        );

        return processflowProgresses;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
export const getAllProcessflowProgresses = async ({
    authToken,
    filters,
}: {
    authToken?: string;
    filters?: { group?: number };
}) => {
    try {
        const response = await makeRequest({
            method: "get",
            path: "/processflow-progresses",
            authToken,
            queryParams: filters
                ? {
                      filter: JSON.stringify({
                          where: {
                              and: [...(filters.group !== undefined ? [{ group: filters.group }] : [])],
                          },
                          order: ["id DESC"],
                      }),
                  }
                : undefined,
        });

        const processflowProgressDtos = parseSchema(getProcessflowProgressesResponseSchema, response.data);

        const processflowProgresses = parseSchema(
            z.array(processflowProgressSchema),
            processflowProgressDtos.map((dto) => {
                try {
                    const d = JSON.parse(dto.data);
                    return {
                        ...dto,
                        data: dto.data
                            ? {
                                  ...d,
                                  id: dto.id,
                              }
                            : undefined,
                    };
                } catch (e) {
                    console.error(`Failed to parse JSON for pf-progress.`, dto);
                    throw e;
                }
            }),
        );

        return processflowProgresses;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
