import { z } from "zod";

export const companyLocationSchema = z.object({
    id: z.number(),
    companyId: z.number(),
    city: z.string().nullish(),
    state: z.string().nullish(),
    notes: z.string().nullish(),
    percentOfBusiness: z.number().nullish(),
});

export type CompanyLocation = z.infer<typeof companyLocationSchema>;
