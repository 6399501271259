import React, { useState } from "react";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";

export default function DateRangePickerTest() {
    const [value, setValue] = useState<DateValueType>({
        startDate: new Date(),
        endDate: new Date(),
    });

    const handleValueChange = (newValue: DateValueType) => {
        const handleValueChange = (newValue: any) => {
            console.log("newValue:", newValue);
            setValue(newValue);
        };
    };
    return (
        <div>
            <Datepicker value={value} onChange={handleValueChange} displayFormat={"MM/DD/YYYY"} />
        </div>
    );
}
