import React, { useEffect } from "react";
import PageDescription from "../layout/page-description";
import { useQuery } from "@tanstack/react-query";
import transactionService from "../services/transaction.service";
import TableNeogen from "../layout/table-neogen";
import { useRecoilState } from "recoil";
import userAtom from "../atoms/userAtom";
import PrintPre from "../layout/print-pre";
import { roleGroups } from "../services/role-group.service";
import companyService from "../services/company.service";
import ButtonNeoGen from "../layout/button-neogen";
import paymentsService from "../services/payments.service";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./modals/checkoutForm";

// import CheckoutForm from "./CheckoutForm";

function Transactions() {
    const [user] = useRecoilState(userAtom);
    const [showForm, setShowForm] = React.useState(false);
    const usersRoleGroups = (user?.roleGroups || []) as any[];
    const isSuperUser = !!usersRoleGroups.find((rg) => rg.id === roleGroups.SuperUser);
    const [clientSecret, setClientSecret] = React.useState("");
    const [balanceOfAccount, setBalanceOfAccount] = React.useState(0);

    // const myTransactionsQuery = useQuery(["transactions", user.id], async () => {

    // });
    const companiesQuery = useQuery(["companies"], async () => {
        const response = await companyService.getAll();
        if (response) {
            return response.data;
        }
    });
    const allTransactionsQuery = useQuery(["transactions", "mapped"], async () => {
        if (isSuperUser) {
            const response = await transactionService.getAll();
            if (response) {
                return response.data;
            }
        } else {
            const response = await transactionService.getMyTransactions();
            if (response) {
                return response.data.map((t: any) => {
                    return {
                        ...t,
                        amount: t.toClearErc ? 0 - t.amount : t.amount,
                        type: t.toClearErc ? "Credit" : "Debit",
                    };
                });
            }
        }
    });
    useEffect(() => {
        setBalanceOfAccount(0);
        allTransactionsQuery.data?.map((t: any) => {
            setBalanceOfAccount((c) => c + t.amount);
        });
    }, [allTransactionsQuery.data]);
    if (companiesQuery.isLoading || allTransactionsQuery.isLoading) {
        return <div>Loading...</div>;
    }
    return (
        <>
            <PageDescription
                title="Transactions"
                description="Billing Transactions"
                buttons={
                    isSuperUser
                        ? [
                              {
                                  label: "Add Transaction",
                                  onClick: () => {
                                      console.log("Add Transaction");
                                  },
                              },
                          ]
                        : []
                }
            />
            <div className="bg-white rounded-xl my-5 p-5 border border-gray-400 shadow flex">
                Balance Of Your Account:{" "}
                {balanceOfAccount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                })}
                {balanceOfAccount > 0 && (
                    <ButtonNeoGen
                        className="ml-5"
                        onClick={async () => {
                            console.log("Pay Now");
                            const clientSecretIn = await paymentsService.makePayment();
                            // console.log(clientSecret);
                            setClientSecret(clientSecretIn.data);
                            setShowForm(true);
                        }}
                    >
                        Pay Now
                    </ButtonNeoGen>
                )}
            </div>
            <TableNeogen
                entries={allTransactionsQuery.data || []}
                ignoreFields={[
                    "paymentId",
                    "createdById",
                    "fromUserId",
                    "toUserId",
                    "toClearErc",
                    "fromClearErc",
                    "companyId",
                    "fromCompanyId",
                    "toCompanyId",
                    "to",
                    "from",
                    "createdAt",
                    "updatedAt",
                ]}
                formatters={[
                    { field: "createdAt", type: "DateTime" },
                    { field: "updatedAt", type: "DateTime" },
                    { field: "date", type: "DateTime" },
                    { field: "fromCompanyId", type: "Company" },
                    { field: "toCompanyId", type: "Company" },
                    { field: "companyId", type: "Company" },
                    { field: "amount", type: "Currency" },
                ]}
            />
            <CheckoutForm show={showForm} close={() => setShowForm(false)} clientSecret={clientSecret} />
        </>
    );
}

export default Transactions;
