import { useEffect, useState } from "react";
import ModalDialog from "../../layout/modal-dialog";
import lineTemsService, { LineItemsType } from "../../services/line-items.service";
import InputControlled from "../../layout/input-controlled";
import TextAreaNeoGenControlled from "../../layout/text-area-controlled";
import DollarInput from "../../layout/dollar-input";
import SelectNeoGen from "../../layout/select-neogen";
import { useQueryClient } from "@tanstack/react-query";

function AddEditLineItem({
    show,
    close,
    title,
    lineItem,
}: {
    show: boolean;
    close: () => void;
    title: string;
    lineItem?: LineItemsType;
}) {
    const [name, setName] = useState(lineItem ? lineItem.name : "");
    const [description, setDescription] = useState(lineItem ? lineItem.description : "");
    const [isPublic, setIsPublic] = useState(lineItem ? lineItem.isPublic : false);
    const [isCommisionable, setIsCommisionable] = useState(lineItem ? lineItem.isCommisionable : false);
    const [price, setPrice] = useState(lineItem ? lineItem.price : 0);
    const [percentageOfFinal, setPercentageOfFinal] = useState(lineItem ? lineItem.percentageOfFinal : 0);
    const queryCache = useQueryClient();
    useEffect(() => {
        if (lineItem) {
            setName(lineItem.name);
            setDescription(lineItem.description);
            setIsPublic(lineItem.isPublic === 1);
            setIsCommisionable(lineItem.isCommisionable === 1);
            setPrice(lineItem.price);
            setPercentageOfFinal(lineItem.percentageOfFinal);
        }
    }, [lineItem]);
    return (
        <>
            <ModalDialog
                okAction={async () => {
                    // save
                    if (lineItem) {
                        // update
                        await lineTemsService.update(lineItem.id, {
                            name,
                            description,
                            isPublic: isPublic ? 1 : 0,
                            isCommisionable: isCommisionable ? 1 : 0,
                            price,
                            percentageOfFinal,
                        });
                        await queryCache.invalidateQueries(["lineItems"]);
                        close();
                    } else {
                        // add
                        await lineTemsService.create({
                            name,
                            description,
                            isPublic: isPublic ? 1 : 0,
                            isCommisionable: isCommisionable ? 1 : 0,
                            price,
                            percentageOfFinal,
                        });
                        await queryCache.invalidateQueries(["lineItems"]);
                        close();
                    }
                }}
                close={() => close()}
                show={show}
                title={lineItem ? "Edit Line Item" : "Add Line Item"}
            >
                <InputControlled label="Name" value={name} setValue={setName} />
                <TextAreaNeoGenControlled label="Description" value={description} setValue={setDescription} />
                <DollarInput value={price.toString()} setValue={(e) => setPrice(Number(e))} />
                <SelectNeoGen
                    className="my-5"
                    options={[
                        { id: "1", name: "Yes" },
                        { id: "0", name: "No" },
                    ]}
                    label="Is Public"
                    value={isPublic ? "1" : "0"}
                    onChange={(e) => setIsPublic(e === "1")}
                />

                <SelectNeoGen
                    className="my-5"
                    options={[
                        { id: "1", name: "Yes" },
                        { id: "0", name: "No" },
                    ]}
                    label="Is Commisionable"
                    value={isCommisionable ? "1" : "0"}
                    onChange={(e) => setIsCommisionable(e === "1")}
                />
                <InputControlled
                    label="Percentage Of Final Return"
                    value={percentageOfFinal?.toString() ?? "0"}
                    setValue={(v) => setPercentageOfFinal(Number(v))}
                />
            </ModalDialog>
        </>
    );
}

export default AddEditLineItem;
