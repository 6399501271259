import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { CompanyLineItem, companyLineItemSchema } from "../domain/company-line-item";

export type CreateCompanyLineItemData = Partial<CompanyLineItem>;

export const createCompanyLineItem = async ({
    authToken,
    data,
}: {
    authToken: string;
    data: CreateCompanyLineItemData;
}) => {
    const response = await makeRequest({
        method: "post",
        path: `/company-line-items`,
        authToken,
        data,
    });

    const companyLineItem = parseSchema(companyLineItemSchema, response.data);

    return companyLineItem;
};
