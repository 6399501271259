/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function DollarInput({
    value,
    setValue,
    autoComplete,
}: {
    value: string;
    setValue: (e: string) => void;
    autoComplete?: string;
}) {
    return (
        <div>
            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-800 dark:text-gray-400">
                Price
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    type="text"
                    name="price"
                    id="price"
                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 dark:ring-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 dark:text-gray-500"
                    placeholder="0.00"
                    autoComplete={autoComplete}
                />
            </div>
        </div>
    );
}
