import { useQuery } from "@tanstack/react-query";
import { getCompanyLineItems } from "../actions/get-company-line-items";
type Params = {
    authToken: string;
    filters?: any;
};

const getCompanyLineItemsQueryKey = ({ authToken, filters }: Params) => {
    return ["company-line-items", { authToken, filters }];
};

export const useCompanyLineItems = ({ authToken, filters }: Params, options?: { enabled?: boolean }) => {
    const query = useQuery({
        queryKey: getCompanyLineItemsQueryKey({ authToken, filters }),
        queryFn: async () => {
            const companyLineItems = await getCompanyLineItems({ authToken, filters });
            return companyLineItems;
        },
        ...options,
    });
    return query;
};
