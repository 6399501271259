import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { HelpDocTemplateItem } from "../domain/help-docs-template-item";
import { getHelpDocsTemplateItems } from "../actions/get-help-docs-template-items";

type Filters = { where?: any };

const getHelpDocsTemplateItemsQueryKey = ({ authToken, filters }: { authToken: string; filters?: Filters }) => {
    return ["help-docs-template-items", { authToken, filters }];
};

export const useHelpDocsTemplateItems = (
    { authToken, filters }: { authToken: string; filters?: Filters },
    options?: UseQueryOptions<HelpDocTemplateItem[]>,
) => {
    const query = useQuery({
        queryKey: getHelpDocsTemplateItemsQueryKey({ authToken, filters }),
        queryFn: async () => {
            const helpDocsTemplateItems = await getHelpDocsTemplateItems({ authToken, filters });
            return helpDocsTemplateItems;
        },
        ...options,
    });
    return query;
};
