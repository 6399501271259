import { QueryCache, useQuery, useQueryClient } from "@tanstack/react-query";
import helpDocsService from "../../services/help-docs.service";
import Loader2 from "../utilities/Loader2";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect, useState } from "react";
import { NeoGenHelpDocs } from "../../typings/api";
import DeleteFaq from "./modals/delete-faq";
import { useNavigate } from "react-router-dom";
import PageDescription from "../../layout/page-description";
import TableNeogen from "../../layout/table-neogen";
import packageSectionRulesService from "../../services/package-section-rules.service";
import helpDocsTemplateService from "../../services/help-docs-template.service";
import helpDocsTemplateItemsService from "../../services/help-docs-template-items.service";
import { Select } from "../../layout/form/select-input";
import PrintPre from "../../layout/print-pre";
import AddTemplate from "./modals/add-template";

export default function Faq() {
    const queryCache = useQueryClient();
    const [showDeleteFaq, setShowDeleteFaq] = useState(false);
    const [existing, setExisting] = useState<null | NeoGenHelpDocs>(null);
    const [selectedTemplate, setSelectedTemplate] = useState<number | null>(
        Number(window.localStorage.getItem("selectedTemplate")) ?? null,
    );
    const [helpDocs, setHelpDocs] = useState<NeoGenHelpDocs[]>([]);
    const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);
    const [editing, setEditing] = useState<boolean>(false);

    const navigate = useNavigate();
    const helpDocsTemplatesQuery = useQuery(["help-doc-templates"], async () => {
        const response = await helpDocsTemplateService.getAll();
        return response?.data ?? [];
    });
    const helpDocsTemplateItemsQuery = useQuery(["help-docs-template-items", selectedTemplate], async () => {
        const response = await helpDocsTemplateItemsService.getFilteredWhere({
            templateId: selectedTemplate,
        });
        return (
            response?.data.sort((a, b) => {
                if ((a?.order ?? 0) > (b?.order ?? 0)) return 1;
                if ((a?.order ?? 0) < (b?.order ?? 0)) return -1;

                return 0;
            }) ?? []
        );
    });
    const helpDocsQuery = useQuery(
        ["help-docs", "sorted"],
        async () => {
            const response = await helpDocsService.getAll();
            if (response) {
                return response.data.sort((a, b) => {
                    const templateItemA = helpDocsTemplateItemsQuery.data?.find((e) => e.helpDocId === a.id);
                    const templateItemB = helpDocsTemplateItemsQuery.data?.find((e) => e.helpDocId === b.id);
                    if ((templateItemA?.order ?? 0) > (templateItemB?.order ?? 0)) return 1;
                    if ((templateItemA?.order ?? 0) < (templateItemB?.order ?? 0)) return -1;

                    return 0;
                });
            }
        },
        { enabled: helpDocsTemplatesQuery.isSuccess && helpDocsTemplateItemsQuery.isSuccess },
    );

    const rulesQuery = useQuery(["package-section-rules"], async () => {
        const response = await packageSectionRulesService.getAll();
        return response?.data ?? [];
    });
    useEffect(() => {
        // Create an array of helpDocs that are in the templateItemsQuery
        const helpDocTemplateSelected = helpDocsTemplateItemsQuery.data?.map((e) => e.helpDocId) ?? [];
        console.log({ helpDocTemplateSelected });
        const helpDocsFiltered = helpDocsTemplateItemsQuery.data
            ?.map((e) => {
                return helpDocsQuery.data?.find((h) => h.id === e.helpDocId);
            })
            // Sort by order field
            .sort((a, b) => {
                const templateItemA = helpDocsTemplateItemsQuery.data?.find((e) => e.helpDocId === a?.id);
                const templateItemB = helpDocsTemplateItemsQuery.data?.find((e) => e.helpDocId === b?.id);
                if ((templateItemA?.order ?? 0) > (templateItemB?.order ?? 0)) return 1;
                if ((templateItemA?.order ?? 0) < (templateItemB?.order ?? 0)) return -1;

                return 0;
            });
        console.log({
            helpDocsFiltered,
            helpDocsQuery: helpDocsQuery.data,
            helpDocsTemplateItemsQuery: helpDocsTemplateItemsQuery.data,
        });
        setHelpDocs((helpDocsFiltered as NeoGenHelpDocs[]) ?? []);
    }, [helpDocsQuery.data, helpDocsTemplateItemsQuery.data]);

    return (
        <div>
            <div>
                {helpDocsQuery.isLoading ? (
                    <Loader2 />
                ) : (
                    <>
                        <PageDescription
                            title="Sections"
                            description="Package Sections"
                            buttons={[
                                {
                                    label: "Add Section",
                                    onClick: () => {
                                        navigate("/faq/new");
                                    },
                                },
                                // Add a template
                                {
                                    label: "Add Template",
                                    onClick: () => {
                                        setEditing(false);
                                        setShowAddTemplateModal(true);
                                    },
                                },
                                // Edit Template (Basically the same as add template)
                                {
                                    label: "Edit Template",
                                    onClick: () => {
                                        setEditing(true);
                                        setShowAddTemplateModal(true);
                                    },
                                },
                                // Reset Order
                                {
                                    label: "Reset Order",
                                    onClick: () => {
                                        const templateItems = helpDocsTemplateItemsQuery.data;
                                        if (templateItems) {
                                            templateItems.forEach((item, idx) => {
                                                item.order = idx;
                                                helpDocsTemplateItemsService.update(item.id, item);
                                            });
                                            queryCache.invalidateQueries(["help-docs-template-items"]);
                                            helpDocsTemplateItemsQuery.refetch();
                                        }
                                    },
                                },
                            ]}
                        />
                        <div className="mb-5">
                            <Select
                                label="Template"
                                options={
                                    helpDocsTemplatesQuery.data?.map((e) => {
                                        return {
                                            label: e.name ?? "",
                                            value: e.id ?? -1,
                                        };
                                    }) ?? []
                                }
                                onChange={(e) => {
                                    console.log(e);
                                    window.localStorage.setItem("selectedTemplate", (e ?? "").toString());
                                    setSelectedTemplate(Number(e) ?? null);
                                }}
                                value={selectedTemplate}
                            />
                        </div>
                        {/* <PrintPre>{helpDocsTemplateItemsQuery?.data}</PrintPre> */}
                        <TableNeogen
                            entries={
                                helpDocs.map((e: any, idx: number) => {
                                    const templateItem = helpDocsTemplateItemsQuery.data?.find(
                                        (ex) => ex.helpDocId === e?.id,
                                    );

                                    if (e) {
                                        return {
                                            id: e.id,
                                            title: e.title,
                                            // tags: e.tags,
                                            updated: e.updated,
                                            created: e.created,
                                            // order: idx,
                                            order: templateItem?.order,
                                        };
                                    } else {
                                        return {};
                                    }
                                }) ?? []
                            }
                            formatters={[
                                { field: "updated", type: "DateTime" },
                                { field: "created", type: "DateTime" },
                            ]}
                            actions={[
                                {
                                    label: "Set Order",
                                    icon: "fas fa-sort-numeric-up",
                                    onClick: (e) => {
                                        const entry = helpDocsQuery.data?.find((h) => h.id === e);
                                        if (entry) {
                                            setExisting(entry);
                                            navigate("/faq/order/" + entry.id);
                                        }
                                    },
                                },
                                {
                                    label: "Edit",
                                    icon: "fas fa-edit",
                                    onClick: (e) => {
                                        const entry = helpDocsQuery.data?.find((h) => h.id === e);
                                        if (entry) {
                                            setExisting(entry);
                                            navigate("/faq/edit/" + entry.id);
                                        }
                                    },
                                },
                                {
                                    labelActive: (id) => {
                                        return (
                                            "Rules (" + rulesQuery.data?.filter((i) => i.helpDoc === id).length + ")"
                                        );
                                    },
                                    label: "",
                                    icon: "fas fa-gavel",
                                    type: "info",
                                    onClick: (e) => {
                                        const entry = helpDocsQuery.data?.find((h) => h.id === e);
                                        if (entry) {
                                            navigate("/package-section-rules/" + entry.id);
                                        }
                                    },
                                },
                                {
                                    label: "",
                                    icon: "fas fa-caret-up",
                                    onClick: (e) => {
                                        const entry = helpDocsTemplateItemsQuery.data?.find((h) => h.helpDocId === e);
                                        if (entry) {
                                            // Find the index of the current help doc template item
                                            const index = helpDocsTemplateItemsQuery.data?.findIndex(
                                                (h) => h.helpDocId === e,
                                            );
                                            const temp = helpDocsTemplateItemsQuery.data?.[(index ?? 1) - 1];
                                            console.error({ index, temp, entry });
                                            // Swap the order of the current and previous help doc template items

                                            if (temp) {
                                                entry.order = (index ?? 1) - 1;
                                                helpDocsTemplateItemsService.update(entry.id, entry);
                                                temp.order = index ?? 0;
                                                helpDocsTemplateItemsService.update(temp.id, temp);
                                                helpDocsTemplateItemsQuery.refetch();
                                                queryCache.invalidateQueries(["help-docs-template-items"]);
                                            }
                                        }
                                    },
                                },
                                {
                                    label: "",
                                    icon: "fas fa-caret-down",
                                    onClick: async (e) => {
                                        const entry = helpDocsTemplateItemsQuery.data?.find((h) => h.helpDocId === e);
                                        if (entry) {
                                            // Swap the order of the current and next help doc template items
                                            const index = helpDocsTemplateItemsQuery.data?.findIndex(
                                                (h) => h.helpDocId === e,
                                            );

                                            const temp = helpDocsTemplateItemsQuery.data?.[(index ?? 0) + 1];
                                            console.error({ index, temp, entry });
                                            if (temp) {
                                                entry.order = entry.order + 1;
                                                helpDocsTemplateItemsService.update(entry.id, entry);
                                                temp.order = temp.order - 1;
                                                helpDocsTemplateItemsService.update(temp.id, temp);
                                                helpDocsTemplateItemsQuery.refetch();
                                                queryCache.invalidateQueries(["help-docs-template-items"]);
                                            }
                                        }
                                    },
                                },
                                {
                                    label: "Delete",
                                    icon: "fas fa-trash",
                                    type: "danger",
                                    onClick: (e) => {
                                        const entry = helpDocsQuery.data?.find((h) => h.id === e);
                                        if (entry) {
                                            setExisting(entry);

                                            setShowDeleteFaq(true);
                                        }
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            {/* <DeleteFaq show={showDeleteFaq} hide={() => setShowDeleteFaq(false)} existingEntry={existing} /> */}
            <AddTemplate
                show={showAddTemplateModal}
                close={() => setShowAddTemplateModal(false)}
                template={
                    editing
                        ? selectedTemplate
                            ? helpDocsTemplatesQuery.data?.find((e) => e.id === selectedTemplate)
                            : undefined
                        : undefined
                }
            />
        </div>
    );
}
