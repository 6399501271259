import { useMutation } from "@tanstack/react-query";
import { UpdateHelpDocsTemplateData, updateHelpDocTemplate } from "../actions/update-help-docs-template";

export const useUpdateHelpDocsTemplateMutation = () => {
    const mutation = useMutation({
        mutationFn: async ({
            authToken,
            data,
            id,
        }: {
            authToken: string;
            data: UpdateHelpDocsTemplateData;
            id: number;
        }) => {
            const helpDocsTemplate = await updateHelpDocTemplate({ authToken, data, id });
            return helpDocsTemplate;
        },
    });
    return mutation;
};
