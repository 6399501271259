import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import processflowStageService from "../../services/processflow-stage.service";
import processflowService from "../../services/processflow.service";
import { ProcessFlow } from "../../typings/api/processflow";
import { ProcessFlowStage } from "../../typings/api/processflow-stage";
import ModalDialog from "../modal-dialog";
import SelectNeoGen from "../select-neogen";

type Fields = { name: string; id: string; stage: number };

export default function ResponseVariable(props: ResponseVariableProps) {
    const [value, setValue] = useState("");
    const [fields, setFields] = useState<Fields[]>([]);
    const { processflowGroup: id } = useParams();
    useEffect((): void => {
        setValue("");
    }, []);

    // Get all the stages for the process flow group
    const stageQuery = useQuery(["processflow-stages", "getAllByGroupId", id], async () => {
        const response = await processflowStageService.getAllByGroupIdIncludingRuleGroups(Number(id));
        if (response) {
            return response.data;
        } else {
            Swal.fire({
                title: "Error",
                text: "There was an error getting the stages for this process flow group",
                icon: "error",
                confirmButtonText: "OK",
            });
            return [];
        }
    });

    // Get the entries for the current stage and process flow group
    const entriesQuery = useQuery(
        ["processflow-entries-all-stages", id],
        async () => {
            const response = await processflowService.getAllByGroupId(Number(id));

            if (response && response.data.length > 0) {
                const mapped = response.data.map((entry: ProcessFlow) => {
                    entry.stageName =
                        stageQuery.data?.find((stage: ProcessFlowStage) => stage.id === entry.stage)?.name ?? "Unknown";
                    return entry;
                });
                return mapped;
            } else {
                Swal.fire({
                    title: "Is there a process flow?",
                    text: "Could not load process flow from hook entries for group: " + id,
                    icon: "error",
                    confirmButtonText: "OK",
                });

                return [];
            }
        },
        { enabled: stageQuery.isSuccess },
    );

    useEffect(() => {
        if (entriesQuery.isSuccess) {
            const dbFields: Fields[] = [];
            entriesQuery.data.forEach((entry: ProcessFlow) => {
                try {
                    const parsed = JSON.parse(entry.entries ?? "");
                    console.error({ parsed });
                    for (const question of parsed) {
                        dbFields.push({
                            name: question.field,
                            id: question.field,
                            stage: entry.stage,
                        });
                    }
                } catch (e) {
                    console.error(e);
                }
                //
            });
            console.error({ fields: dbFields });
            setFields(dbFields);
            setValue("");
        }
    }, [entriesQuery.data, entriesQuery.isSuccess, props.show]);

    return (
        <ModalDialog
            title="Response Variable"
            close={props.close}
            show={props.show}
            okAction={(): void => {
                props.setValue(value);
                props.close();
            }}
        >
            <SelectNeoGen
                label="Question"
                value={value}
                onChange={(e: string | number): void => {
                    setValue(e.toString());
                }}
                options={fields}
            />
        </ModalDialog>
    );
}

type ResponseVariableProps = {
    setValue: (value: string) => void;
    show: boolean;
    close: () => void;
};
