import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { CompanyLocation, companyLocationSchema } from "../domain/company-location";

export type UpdateCompanyLocationData = Partial<CompanyLocation>;

export const updateCompanyLocation = async ({
    authToken,
    id,
    data,
}: {
    authToken: string;
    id: number;
    data: UpdateCompanyLocationData;
}) => {
    const response = await makeRequest({
        method: "patch",
        path: `/company-locations/${id}`,
        authToken,
        data,
    });

    const companyLocation = parseSchema(companyLocationSchema, response.data);

    return companyLocation;
};
