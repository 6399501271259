import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { CompanyLocation, companyLocationSchema } from "../domain/company-location";

export type CreateCompanyLocationData = Partial<CompanyLocation>;

export const createCompanyLocation = async ({
    authToken,
    data,
}: {
    authToken: string;
    data: CreateCompanyLocationData;
}) => {
    const response = await makeRequest({
        method: "post",
        path: `/company-locations`,
        authToken,
        data,
    });

    const companyLocation = parseSchema(companyLocationSchema, response.data);

    return companyLocation;
};
