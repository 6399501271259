import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { TextField } from "../../../layout/form/text-field";
import { TextAreaField } from "../../../layout/form/text-area-field";
import { useMutation } from "@tanstack/react-query";
import { createCompanyLocation } from "../../../company-locations/actions/create-company-location";
import { useAuth } from "../../../auth/use-auth";
import { CompanyLocation } from "../../../company-locations/domain/company-location";
import { NumberField } from "../../../layout/form/number-field";

const schema = z.object({
    city: z.string().nullish(),
    state: z.string(),
    notes: z.string().nullish(),
    percentOfBusiness: z.number(),
});

type Data = z.infer<typeof schema>;

export const NewCompanyLocationModal = ({
    onClose,
    onCreate,
    companyId,
}: {
    onClose: () => void;
    onCreate: (location: CompanyLocation) => void;
    companyId: number;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const form = useForm({ schema });

    const mutation = useMutation({
        mutationFn: async (data: Data) => {
            const companyLocation = await createCompanyLocation({ authToken, data: { ...data, companyId } });
            return companyLocation;
        },
    });

    const handleSubmit = form.handleSubmit(async (data) => {
        const location = await mutation.mutateAsync(data);
        onCreate(location);
    });

    return (
        <Form onSubmit={handleSubmit} error={mutation.error as any}>
            <ModalDialog title="New Location" show close={onClose} okAction={handleSubmit} okText="Add Location">
                <TextField label="City" {...form.getFieldProps("city")} />
                <TextField label="State" {...form.getFieldProps("state")} />
                <NumberField label="Percent of Business" {...form.getFieldProps("percentOfBusiness")} />
                <TextAreaField label="Notes" {...form.getFieldProps("notes")} />
            </ModalDialog>
        </Form>
    );
};
