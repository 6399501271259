import APIService from "./api.service";

export type MandateEffect = {
    id?: number;
    effectName?: string;
    notes?: string;
};

class MandateEffectService extends APIService<MandateEffect> {
    constructor() {
        super("/mandate-effects");
    }
}

export default new MandateEffectService();
