import { makeRequest } from "../../jason-proof-of-concept/shared/utils";
import { CompanyMandateAssignment, companyMandateAssignmentSchema } from "../domain/company-mandate-assignment";

export type UpdateCompanyMandateAssignment = Partial<CompanyMandateAssignment>;

export const updateCompanyMandateAssignment = async ({
    id,
    data,
    authToken,
}: {
    id: number;
    data: UpdateCompanyMandateAssignment;
    authToken: string;
}) => {
    const response = await makeRequest({
        method: "patch",
        path: `/company-mandate-assignments/${id}`,
        data,
        authToken,
    });
    const companyMandateAssignment = companyMandateAssignmentSchema.parse(response.data);
    return companyMandateAssignment;
};
