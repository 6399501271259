/* eslint-disable prettier/prettier */
/**
 * API Parent Class
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import axios, { AxiosError, AxiosResponse } from "axios";
import th from "date-fns/esm/locale/th/index.js";
import authHeader from "./auth-header";
export interface APIKeyParams {
    [key: string]: string | boolean | number;
}

// const API_URL = process.env.REACT_APP_API_URL ?? "http://localhost:4000";
export const API_URL = process.env.REACT_APP_API_URL ?? "https://api.clearerc.com/";

class API<T> {
    endpoint: string;
    debug: boolean;
    /**
     * Creates an instance of an API (normally done from child class)
     *
     * @param {String} endpoint - The endpoint URL in the REST API
     */
    constructor(endpoint: string) {
        this.endpoint = endpoint;
        this.debug = process.env.NODE_ENV === "development";
    }

    async getURL(URL: string): Promise<void | AxiosResponse<any, any>> {
        axios.get(API_URL + URL, { headers: authHeader() });
        try {
            return axios.get(API_URL + URL, { headers: authHeader() }).catch((error) => {
                console.warn(error);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                    console.error("Logout3");
                    // localStorage.removeItem("user");
                    // window.location.reload();
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message, API_URL);
                }
                console.log(error.config);
                console.log(error);
                console.warn(error.response);
                if (error.response?.status === 401 || error.response?.status === 403) {
                    console.error("Logout4");
                    // alert(this.endpoint);
                    localStorage.removeItem("user");
                    window.location.href = `/login/7?redirectUri=${encodeURIComponent(window.location.pathname)}`;
                    throw new Error("Unauthorized");
                    // window.location.reload();
                }
            });
        } catch (e) {
            const error = <AxiosError>(<any>e);
            console.error(error);

            if (error.response) {
                // if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                console.warn(error.response.status);
                if (error.response.status === 401 || error.response.status === 403) {
                    console.error("Logout5");
                    localStorage.removeItem("user");
                    window.location.href = `/login/1?redirectUri=${encodeURIComponent(window.location.pathname)}`;
                    throw new Error("Unauthorized");
                    // window.location.reload();
                }
                // }
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.error(error.request);
                // localStorage.removeItem("user");
                // window.location.reload();
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
            }
            console.log(error.config);
            console.log(error);
            // }
        }
    }

    async postURL(URL: string, data: any) {
        // console.error(data);
        return axios.post(API_URL + URL, data, { headers: authHeader() });
    }
    async deleteURL(URL: string) {
        return axios.delete(API_URL + URL, { headers: authHeader() });
    }

    async patchURL(URL: string, data: any) {
        return axios.patch(API_URL + URL, data, { headers: authHeader() });
    }
    async putURL(URL: string, data: any) {
        return axios.put(API_URL + URL, data, { headers: authHeader() });
    }

    /**
     * Gets a single instance of a child by ID
     *
     * @param {Number} id - the ID of the child to return
     */
    async getOne(id: number | string): Promise<void | AxiosResponse<T>> {
        try {
            // console.log("Getting One:", API_URL + this.endpoint + "/" + id);
            const response = await axios.get(API_URL + this.endpoint + "/" + id, { headers: authHeader() });
            // console.log(response);
            return response;
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Gets the number of rows
     */
    async getCount(): Promise<any> {
        return axios.get(API_URL + this.endpoint + "/count", { headers: authHeader() });
    }

    /**
     * Gets a single instance of a child by ID
     *
     * @param {Number} id - the ID of the child to return
     */
    getOneSync(id: number): Promise<any> {
        return axios.get(API_URL + this.endpoint + "/" + id, { headers: authHeader() });
    }

    /**
     * Returns all entries from this endpoint
     *
     */
    getAll(filter?: any): Promise<void | AxiosResponse<T[]>> {
        const url = filter
            ? API_URL + this.endpoint + "/?filter=" + encodeURIComponent(JSON.stringify(filter))
            : API_URL + this.endpoint;
        return axios
            .get<T[]>(url, {
                headers: authHeader(),
            })
            .catch((e) => {
                console.error(API_URL);
                console.error(e);
                console.error(e?.response);
                console.error(e?.response?.status);
                if (e?.response?.status === 401 || e?.response?.status === 403) {
                    console.error("Logout6");
                    localStorage.removeItem("user");
                    window.location.href = `/login/2?redirectUri=${encodeURIComponent(window.location.pathname)}`;
                    throw new Error("Unauthorized");
                    // window.location.reload();
                }
            });
    }

    /**
     * Returns all entries from this endpoint
     *
     */
    async getSome(offset: number, count: number): Promise<void | AxiosResponse<T[]>> {
        return axios
            .get(
                API_URL +
                    this.endpoint +
                    "?filter=" +
                    encodeURIComponent(`{"offset": ${offset}, "limit": ${count}, "order": ["id DESC"]}`),
                { headers: authHeader() },
            )
            .catch((e) => {
                console.log(e.response);
                if (e.response.status === 401 || e.response.status === 403) {
                    console.error("Logout71");
                    localStorage.removeItem("user");
                    window.location.href = `/login/3?redirectUri=${encodeURIComponent(window.location.pathname)}`;
                    throw new Error("Unauthorized");
                    // window.location.reload();
                }
            });
    }

    async getFiltered(filter: any): Promise<void | AxiosResponse<T[]>> {
        if (filter === undefined || filter === null) {
            throw new Error("Filter is undefined");
        }

        return axios
            .get(API_URL + this.endpoint + "/?filter=" + encodeURIComponent(JSON.stringify(filter)), {
                headers: authHeader(),
            })
            .catch((e) => {
                console.log(e.response);
                if (e.response.status === 401 || e.response.status === 403) {
                    console.error("Logout72");
                    // alert(JSON.stringify({auth: authHeader()}))
                    // alert(this.endpoint);
                    localStorage.removeItem("user");
                    window.location.href = `/login/4?redirectUri=${encodeURIComponent(window.location.pathname)}`;
                    throw new Error("Unauthorized");
                    // window.location.reload();
                }
            });
    }

    async getPaginatedFiltered(filter: any, additionalURLComponent?: string): Promise<void | AxiosResponse<T>> {
        if (filter === undefined || filter === null) {
            throw new Error("Filter is undefined");
        }
        
        // console.error(API_URL + this.endpoint + "/?filter=" + encodeURIComponent(JSON.stringify(filter) + additionalURLComponent))
        return axios
            .get(API_URL + this.endpoint + "/?filter=" + encodeURIComponent(JSON.stringify(filter) )+(additionalURLComponent??""), {
                headers: authHeader(),
            })
            .catch((e) => {
                console.log(e.response);
                if (e.response.status === 401 || e.response.status === 403) {
                    localStorage.removeItem("user");
                    window.location.href = "/login";
                    throw new Error("Unauthorized");
                }
            });
    }

    async getFilteredWhere(filter: any): Promise<void | AxiosResponse<T[]>> {
        if (filter === undefined || filter === null) {
            throw new Error("Filter is undefined");
        }
        const response = await axios
            .get(API_URL + this.endpoint + "/?filter=" + encodeURIComponent(JSON.stringify({ where: filter })), {
                headers: authHeader(),
            })
            .catch((e) => {
                console.log(e.response);
                if (e.response.status === 401 || e.response.status === 403) {
                    console.error("Logout73");
                    localStorage.removeItem("user");
                    window.location.href = `/login/5?redirectUri=${encodeURIComponent(window.location.pathname)}`;
                    throw new Error("Unauthorized");
                    // window.location.reload();
                }
            });
        // console.error({ response, filter })
        return response;
    }
    async updateFilteredWhere(filter: any, data: any): Promise<any> {
        if (filter === undefined || filter === null) {
            throw new Error("Filter is undefined");
        }
        const response = await axios
            .patch(
                API_URL + this.endpoint + "/?filter=" + encodeURIComponent(JSON.stringify({ where: filter })),
                data,
                { headers: authHeader() },
            )
            .catch((e) => {
                console.log(e.response);
                if (e.response.status === 401 || e.response.status === 403) {
                    console.error("Logout74");
                    localStorage.removeItem("user");
                    window.location.href = `/login/6?redirectUri=${encodeURIComponent(window.location.pathname)}`;
                    throw new Error("Unauthorized");
                    // window.location.reload();
                }
            });
        // console.error({ response, filter })
        return response;
    }

    /**
     * Removes null or undefined parameters
     * @param obj
     */
    clean(obj: any) {
        for (const propName in obj) {
            if (!Object.prototype.hasOwnProperty.call(obj, propName)) {
                continue;
            }
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
    }

    /**
     * Saves a child instance to the database
     */
    async create(fields: any, throwError = false): Promise<void | AxiosResponse<T>> {
        delete fields.id;
        // TODO: Not happy with this - Loopback 4 doesn't understand nullable things
        this.clean(fields);
        // console.log(this.constructor.name + " Creating with %O", fields);
        return axios.post(API_URL + this.endpoint, fields, { headers: authHeader() }).catch((e) => {
            console.error(e);
            console.log(e.response);
            if (throwError) {
                throw e;
            }
        });
    }
    /**
     * Saves a child instance to the database
     */
    async createWithId(fields: any, throwError = false): Promise<void | AxiosResponse<T>> {
        // TODO: Not happy with this - Loopback 4 doesn't understand nullable things
        this.clean(fields);
        // console.log(this.constructor.name + " Creating with %O", fields);
        return axios.post(API_URL + this.endpoint, fields, { headers: authHeader() }).catch((e) => {
            console.error(e);
            console.log(e.response);
            if (throwError) {
                throw e;
            }
        });
    }

    async update(id: any, data: any, throwError = false) {
        return axios.patch(API_URL + this.endpoint + "/" + id, data, { headers: authHeader() }).catch((e) => {
            console.error(e);
            console.log(e.response);
            if (throwError) {
                throw e;
            }
        });
    }

    /*This should be used instead of manually type a cache key
    If you find a scenario where you need to manually type a cache key, please add it here
    Note that the keys should go from largest to smallest
    For example, if you want a cache key for a user in a group, you should use the group ID,
    then the User ID. That way if someone pulled all the users in a group, it would be cached
    */
    getCacheKey(keyObject: APIKeyParams) {
        if (keyObject) {
            return [this.endpoint, keyObject];
        } else {
            return [this.endpoint];
        }
    }

    // /**
    //  * Saves a child instance to the database
    //  */
    // create(fields) {
    //     delete (fields.id);
    //     // TODO: Not happy with this - Loopback 4 doesn't understand nullable things
    //     this.clean(fields);
    //     console.log(this.constructor.name + " Creating with %O", fields);
    //     return axios.post(API_URL + this.endpoint, fields, {headers: authHeader()}).catch(e => {
    //         console.log(e.response);
    //     });
    // }

    /**
     * Uses introspection to get class fields
     */
    getFields(object: any) {
        return Object.getOwnPropertyNames(object);
    }
    deleteWhere(url: string, where: any) {
        // return axios.delete(this.url + url, { headers: stAuthHeader() });
        return this.delete(url + "?where=" + encodeURIComponent(JSON.stringify(where)));
    }
    get(url: string) {
        return axios.get(API_URL + url, { headers: authHeader() });
    }
    delete(url: string) {
        return axios.delete(API_URL + url, { headers: authHeader() });
    }
    async deleteByID(id?: number): Promise<any> {
        if (!id) {
            throw new Error("You did not provide an id" + id);
        }
        const fullUrl = API_URL + this.endpoint + "/" + id;
        const header = authHeader();
        // console.log(fullUrl, header);
        return axios
            .delete(fullUrl, { headers: header })
            .catch((e) => {
                console.log("Delete Error: ", e.response);
                console.log("Delete Error: ", e);
            })
            .then((response) => {
                console.log("Delete Response: ", response);
            });
    }

    // deleteByID(id) {
    //     const fullUrl = API_URL + this.endpoint + "/" + id;
    //     const header = authHeader();
    //     console.log(fullUrl,header);
    //     return axios.delete(fullUrl, {headers: header}).catch(e => {
    //         console.log("Delete Error: ",e.response);
    //         console.log("Delete Error: ",e);
    //     }).then(response => {
    //         console.log("Delete Response: ",response);
    //     });
    // }
}

export default API;
