import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { getCompanies } from "../actions/get-companies";
import { CompanyFilters } from "../components/modals/company-filters-modal";
import { getPaginatedCompanies } from "../actions/get-paginated-companies";
import { useMemo } from "react";
import { uniqBy } from "lodash";
import { Company } from "../domain/company";

type Params = {
    authToken: string;
    filters?: any;
    adHocFilters?: CompanyFilters;
};

const getCompaniesQueryKey = ({ authToken, filters, adHocFilters }: Params) => {
    return ["companies", { authToken, filters, adHocFilters }];
};

export const useCompanies = ({ authToken, filters, adHocFilters }: Params, options?: { enabled?: boolean }) => {
    const query = useQuery({
        queryKey: getCompaniesQueryKey({ authToken, filters, adHocFilters }),
        queryFn: async () => {
            const companies = await getCompanies({ authToken, filters, adHocFilters });
            return companies;
        },
        ...options,
    });
    return query;
};

const getInfiniteCompaniesQueryKey = ({ authToken, filters, adHocFilters }: Params) => {
    return ["companies", { infinite: true, authToken, filters, adHocFilters }];
};

export const useInfiniteCompanies = ({ authToken, filters, adHocFilters }: Params) => {
    const query = useInfiniteQuery({
        queryKey: getInfiniteCompaniesQueryKey({ authToken, filters, adHocFilters }),
        getNextPageParam: (lastPage: { hasNextPage: boolean; data: Company[]; pageNumber: number }) => {
            return lastPage.hasNextPage ? lastPage.pageNumber + 1 : lastPage.pageNumber;
        },
        keepPreviousData: true,
        queryFn: async ({ pageParam = 0 }) => {
            const limit = 30;
            const offset = pageParam * limit;
            const response = await getPaginatedCompanies({
                authToken,
                filters: { ...filters, limit, offset },
                adHocFilters,
            });
            return { ...response, hasNextPage: true, pageNumber: pageParam };
        },
    });

    const companyPages = useMemo(() => query.data?.pages || [], [query.data?.pages]);
    const companies = useMemo(
        () =>
            uniqBy(
                companyPages.reduce<Company[]>((acc, page) => [...acc, ...page.data], []),
                (company) => company.id,
            ),
        [companyPages],
    );

    return { ...query, companies };
};
