import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import ModalDialog from "./modal-dialog";
import { v4 as uuidv4 } from "uuid";
import { Entry } from "./add-to-list";
import { SaveDetailsType } from "../typings/save-details-type";
import OptionsDropDown from "./options-dropdown";

export default function ModalContractPicker(props: ModalHtmlEditorProps) {
    const [value, setValue] = useState(props.isHelpDocument ? props.entry?.helpDocument ?? "" : props.value);
    const quillRef = useRef<ReactQuill | null>(null);
    const modules = {
        toolbar: [
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript

            ["link", "image"],
            ["clean"],
        ],
    };

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "background",
        "clean",
        "link",
        "image",
        "color",
        "size",
        "align",
        "font",
        "code-block",
        "script",
    ];

    function saveRecord() {
        if (props.isEdit) {
            console.error({ value });
            // { field, value, description, required, widthOf300Cols, selectBoxEntries, helpDocument, password, ruleGroupsAreOr }
            props.saveEdit({
                field: props.field && props.field.length > 0 ? props.field : uuidv4(),
                value: props.isHelpDocument ? props.value : value,
                description: "",
                required: false,
                widthOf300Cols: "300",
                selectBoxEntries: props.entry?.selectBoxEntries ?? [],
                helpDocument: props.isHelpDocument ? value : props.entry?.helpDocument ?? "",
                password: false,
                ruleGroupsAreOr: props.entry?.ruleGroupsAreOr ?? false,
                defaultVal: "",
                hideWhenFilled: false,
            });
        } else {
            const field: any = props.field && props.field.length > 0 ? props.field : uuidv4();
            props.save({
                field,
                value: props.isHelpDocument ? props.value : value,
                description: "",
                required: false,
                widthOf300Cols: "300",
                selectBoxEntries: props.entry?.selectBoxEntries ?? [],
                helpDocument: props.isHelpDocument ? value : props.entry?.helpDocument ?? "",
                password: false,
                ruleGroupsAreOr: props.entry?.ruleGroupsAreOr ?? false,
                defaultVal: "",
                hideWhenFilled: false,
            });
        }
    }

    function addVariable(val: string) {
        if (quillRef.current) {
            quillRef.current.focus();

            const range = quillRef.current.editor?.getSelection();
            if (range) {
                quillRef.current.editor?.insertText(range.index, "{{" + val + "}}");
            }
        }
    }

    return (
        <>
            <ModalDialog
                title={props.title ?? ""}
                close={props.close}
                show={props.show}
                okAction={saveRecord}
                size="md"
            >
                <div className="mb-2 w-full">
                    <OptionsDropDown
                        options={[
                            { label: "Company name", action: () => addVariable("companyName") },
                            { label: "Company address", action: () => addVariable("companyAddress") },
                            { label: "Email", action: () => addVariable("email") },
                            { label: "Owner first name", action: () => addVariable("ownerFirstName") },
                            { label: "Owner last name", action: () => addVariable("ownerLastName") },
                            { label: "City", action: () => addVariable("city") },
                            { label: "State", action: () => addVariable("state") },
                            { label: "Country", action: () => addVariable("country") },
                        ]}
                    />
                </div>
                <ReactQuill
                    ref={quillRef}
                    className="bg-white dark:bg-gray-800 dark:text-gray-400 text-gray-200 rounded-lg shadow-md h-96 mb-20"
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    defaultValue={value}
                    onChange={(content, delta, source, editor) => {
                        setValue(content);
                    }}
                />
            </ModalDialog>
        </>
    );
}

type ModalHtmlEditorProps = {
    title?: string;
    show: boolean;
    close: () => void;
    label?: string;
    name?: string;
    save: ({
        field,
        value,
        description,
        required,
        widthOf300Cols,
        selectBoxEntries,
        helpDocument,
        password,
        ruleGroupsAreOr,
    }: SaveDetailsType) => void;
    saveEdit: ({
        field,
        value,
        description,
        required,
        widthOf300Cols,
        selectBoxEntries,
        helpDocument,
        password,
        ruleGroupsAreOr,
    }: SaveDetailsType) => void;
    isHelpDocument?: boolean;
    entries?: Entry[];
    entry: Entry | undefined;
    field?: string;
    value?: any;
    isEdit?: boolean;
    // saveEdit?: any;
};
