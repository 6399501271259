import { useState } from "react";
import ButtonNeoGen from "./button-neogen";
import ModalFieldValue from "./modal-field-value";
import ModalValueCalculator from "../sections/processflows/modals/modal-value-calculator";
import ModalHtmlEditor from "./modal-html-editor";
import { BusinessRuleGroup } from "../typings/api/business-rule-group";
import ButtonCreator from "./button-creator";
import { SaveDetailsType } from "../typings/save-details-type";
import ModalVideoSelector from "./modal-video-selector";
import ModalContractPicker from "./modal-contract-picker";

export function AddToList(props: AddToListProps) {
    // const [entries, setEntries] = useState<string[]>([]);
    const [showAddEntry, setShowAddEntry] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [idx, setIdx] = useState<number>(-1);
    const [field, setField] = useState<string>("");
    const [defaultVal, setDefaultVal] = useState<string>("");
    const [value, setValue] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [entry, setEntry] = useState<Entry>();
    const [required, setRequired] = useState<boolean>(false);
    const [widthOf300Cols, setWidthOf300Cols] = useState<string>("");

    function addToList({
        field,
        value,
        description,
        required,
        widthOf300Cols,
        selectBoxEntries,
        helpDocument,
        password,
        ruleGroupsAreOr,
        defaultVal,
        hideWhenFilled,
    }: SaveDetailsType) {
        // single;
        // setEntries([...entries, newEntry]);
        const newEntry: Entry = {
            id: field,
            field: field,
            required: required,
            value: value,
            description: description,
            selectBoxEntries: selectBoxEntries,
            type: props.type,
            icon: props.icon,
            password,
            widthOf300Cols: widthOf300Cols,
            helpDocument: helpDocument ?? "",
            ruleGroupsAreOr: ruleGroupsAreOr,
            defaultVal: defaultVal,
            hideWhenFilled,
        };
        setShowAddEntry(false);

        props.setEntries([...(props.entries ?? []), newEntry]);
    }
    function saveEdit({
        field,
        value,
        description,
        required,
        widthOf300Cols,
        selectBoxEntries,
        helpDocument,
        password,
        ruleGroupsAreOr,
        defaultVal,
        hideWhenFilled,
    }: SaveDetailsType) {
        // single;
        // setEntries([...entries, newEntry]);
        const existing = props.entries ?? [];
        const newEntry: Entry = {
            id: field,
            field: field,
            value: value,
            required: required,
            description: description,
            selectBoxEntries: selectBoxEntries,
            type: props.type,
            icon: props.icon,
            password,
            widthOf300Cols: widthOf300Cols,
            helpDocument: helpDocument ?? "",
            ruleGroupsAreOr: ruleGroupsAreOr,
            defaultVal: defaultVal,
            hideWhenFilled,
        };
        existing[idx] = newEntry;
        props.setEntries([...existing]);
        setShowAddEntry(false);
        // props.setEntries([...props.entries, newEntry]);
    }
    return (
        <>
            {/* <span className=""> */}
            {/* <p className="text-lg mb-5">
                    {props.title}
                </p> */}

            <ButtonNeoGen
                text={props.title}
                size="md"
                icon={props.icon + " fa-sm"}
                type="outline-primary"
                className="shadow-md rounded-md basis-1/6"
                onClick={() => {
                    setIsEdit(false);
                    setShowAddEntry(true);
                }}
            />

            {/* <PrintPre>
                {props.type}
            </PrintPre> */}
            {/* </span> */}
            {/* {showAddEntry && props.type === "wysiwyg" && <ModalHtmlEditor entries={entries} entry={entry} title={entry.field} label="Text" name={entry.field} field={entry.field} value={value} isEdit={isEdit} saveEdit={saveEdit} show={showAddEntry} close={() => setShowAddEntry(false)} save={addToList} />} */}
            {showAddEntry && props.type === "wysiwyg" && (
                <ModalHtmlEditor
                    entries={props.entries ?? []}
                    entry={entry}
                    title={props.title}
                    label="Text"
                    name="entry"
                    field={field}
                    value={value}
                    isEdit={isEdit}
                    saveEdit={saveEdit}
                    show={showAddEntry}
                    close={() => setShowAddEntry(false)}
                    save={addToList}
                />
            )}
            {showAddEntry && props.type === "video" && (
                <ModalVideoSelector
                    entry={entry}
                    isSelect={props.isSelect}
                    title={props.title}
                    label="Text"
                    name="entry"
                    field={field}
                    value={value}
                    isEdit={isEdit}
                    widthOf300Cols={widthOf300Cols}
                    saveEdit={saveEdit}
                    show={showAddEntry}
                    close={() => setShowAddEntry(false)}
                    save={addToList}
                    description={description}
                />
            )}
            {showAddEntry && props.type === "contractPicker" && (
                <ModalContractPicker
                    entries={props.entries ?? []}
                    entry={entry}
                    title={props.title}
                    label="Text"
                    name="entry"
                    field={field}
                    value={value}
                    isEdit={isEdit}
                    saveEdit={saveEdit}
                    show={showAddEntry}
                    close={() => setShowAddEntry(false)}
                    save={addToList}
                />
            )}
            {showAddEntry && props.type === "button" && (
                <ButtonCreator
                    entries={props.entries ?? []}
                    entry={entry}
                    title={props.title}
                    label="Text"
                    name="entry"
                    field={field}
                    value={value}
                    isEdit={isEdit}
                    saveEdit={saveEdit}
                    show={showAddEntry}
                    close={() => setShowAddEntry(false)}
                    save={addToList}
                />
            )}
            {showAddEntry && props.type === "valueCalculator" && (
                <ModalValueCalculator
                    entries={props.entries ?? []}
                    entry={entry}
                    title={props.title}
                    label="Text"
                    name="entry"
                    field={field}
                    value={value}
                    isEdit={isEdit}
                    widthOf300Cols={widthOf300Cols}
                    saveEdit={saveEdit}
                    show={showAddEntry}
                    close={() => setShowAddEntry(false)}
                    save={addToList}
                    description={description}
                />
            )}
            {showAddEntry &&
                props.type !== "valueCalculator" &&
                props.type !== "video" &&
                props.type !== "wysiwyg" &&
                props.type !== "button" &&
                props.type !== "contractPicker" && (
                    <ModalFieldValue
                        entry={entry}
                        isSelect={props.isSelect}
                        title={props.title}
                        label="Text"
                        name="entry"
                        field={field}
                        value={value}
                        isEdit={isEdit}
                        widthOf300Cols={widthOf300Cols}
                        saveEdit={saveEdit}
                        show={showAddEntry}
                        close={() => setShowAddEntry(false)}
                        save={addToList}
                        description={description}
                        defaultVal={defaultVal}
                    />
                )}
        </>
    );
}

export type typeOptions =
    | "textDisplay"
    | "checkbox"
    | "textfield"
    | "textarea"
    | "yesNo"
    | "selectBox"
    | "fileUpload"
    | "state"
    | "datePicker"
    | "valueCalculator"
    | "video"
    | "wysiwyg"
    | "daterangePicker"
    | "industryPicker"
    | "industryGroupPicker"
    | "customTable"
    | "reviewedBy"
    | "dollar"
    | "button"
    | "contractPicker"
    | "currency";

export type Entry = {
    fieldValue?: any; // Only used for internal - raw objects
    errors?: string[];
    id: any;
    field: string;
    value: string;
    description: string;
    selectBoxEntries: string[];
    /* It's too late to start storing the processflow id and you don't really need it anyway.
  First up, you will need it to narrow down the initial rules, and I was concerned you'd need it
  when processing a rule, but the rule doesn't just get processed based on the field names, but
  when asked to potentially block something.  This means that even if the same combination of
  fields turns up later on, it doesn't necessarily mean it will get the same treatment.
  */
    // processflowId?: number;

    // datePickerEntries: string[];
    type: typeOptions;
    icon: string;
    required: boolean;
    widthOf300Cols: string;
    ruleGroups?: BusinessRuleGroup[];
    ruleGroupsAreOr: boolean;
    helpDocument: string;
    password: boolean;
    hideWhenFilled: boolean;
    defaultVal: string;
};
type AddToListProps = {
    title: string;
    entries?: Entry[];
    icon: string;
    setEntries: (entries: Entry[]) => void;
    type: typeOptions;
    isSelect?: boolean;
};
