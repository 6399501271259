import { z } from "zod";

export const processflowStageSchema = z.object({
    id: z.number(),
    name: z.string(),
    description: z.string(),
    order: z.number(),
    group: z.number(),
    isPublic: z.boolean(),
    businessRuleGroups: z.optional(z.array(z.any())),
    ruleGroupsAreOr: z.optional(z.boolean()),
});

export type ProcessflowStage = z.infer<typeof processflowStageSchema>;
