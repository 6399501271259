import { orderBy } from "lodash";

export const states = orderBy(
    [
        {
            id: 1,
            name: "New York",
        },
        {
            id: 2,
            name: "California",
        },
        {
            id: 3,
            name: "Illinois",
        },
        {
            id: 4,
            name: "Florida",
        },
        {
            id: 5,
            name: "Texas",
        },
        {
            id: 6,
            name: "Pennsylvania",
        },
        {
            id: 7,
            name: "Georgia",
        },
        {
            id: 8,
            name: "District of Columbia",
        },
        {
            id: 9,
            name: "Massachusetts",
        },
        {
            id: 10,
            name: "Arizona",
        },
        {
            id: 11,
            name: "Michigan",
        },
        {
            id: 12,
            name: "Washington",
        },
        {
            id: 13,
            name: "Minnesota",
        },
        {
            id: 14,
            name: "Colorado",
        },
        {
            id: 15,
            name: "Nevada",
        },
        {
            id: 16,
            name: "Maryland",
        },
        {
            id: 17,
            name: "Missouri",
        },
        {
            id: 18,
            name: "Oregon",
        },
        {
            id: 19,
            name: "Puerto Rico",
        },
        {
            id: 20,
            name: "Indiana",
        },
        {
            id: 21,
            name: "Ohio",
        },
        {
            id: 22,
            name: "Virginia",
        },
        {
            id: 23,
            name: "North Carolina",
        },
        {
            id: 24,
            name: "Wisconsin",
        },
        {
            id: 25,
            name: "Rhode Island",
        },
        {
            id: 26,
            name: "Tennessee",
        },
        {
            id: 27,
            name: "Utah",
        },
        {
            id: 28,
            name: "Oklahoma",
        },
        {
            id: 29,
            name: "Connecticut",
        },
        {
            id: 30,
            name: "Kentucky",
        },
        {
            id: 31,
            name: "Louisiana",
        },
        {
            id: 32,
            name: "Nebraska",
        },
        {
            id: 33,
            name: "Alabama",
        },
        {
            id: 34,
            name: "New Mexico",
        },
        {
            id: 35,
            name: "South Carolina",
        },
        {
            id: 36,
            name: "Iowa",
        },
        {
            id: 37,
            name: "Kansas",
        },
        {
            id: 38,
            name: "Arkansas",
        },
        {
            id: 39,
            name: "Idaho",
        },
        {
            id: 40,
            name: "New Jersey",
        },
        {
            id: 41,
            name: "Hawaii",
        },
        {
            id: 42,
            name: "Mississippi",
        },
        {
            id: 43,
            name: "Alaska",
        },
        {
            id: 44,
            name: "New Hampshire",
        },
        {
            id: 45,
            name: "North Dakota",
        },
        {
            id: 46,
            name: "Maine",
        },
        {
            id: 47,
            name: "South Dakota",
        },
        {
            id: 48,
            name: "West Virginia",
        },
        {
            id: 49,
            name: "Montana",
        },
        {
            id: 50,
            name: "Delaware",
        },
        {
            id: 51,
            name: "Vermont",
        },
        {
            id: 52,
            name: "Wyoming",
        },
    ],
    (s) => s.name.toLowerCase(),
);
