import PlusIcon from "../icons/PlusIcon";
import { useEffect, useMemo, useState } from "react";
import { Column, Id, Task } from "../types";
import ColumnContainer from "./ColumnContainer";
import {
    DndContext,
    DragEndEvent,
    DragOverEvent,
    DragOverlay,
    DragStartEvent,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { useQuery } from "@tanstack/react-query";
import companyService from "../../services/company.service";
import companyRepairStagesService from "../../services/company-repair-stages.service";
import repairStagesService from "../../services/repair-stages.service";

import SelectNeoGen from "../select-neogen";
import repairStageCategoriesService from "../../services/repair-stage-categories.service";

import repairStageHistoryService, { RepairStageHistoryType } from "../../services/repair-stage-history.service";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { getAuthToken } from "../../services/auth-header";
import { getProcessflowProgresses } from "../../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
// const levels: string[] = [
//     "Pending",
//     "Ready for review",
//     "Active ",
//     "Sales appointment scheduled. ",
//     "Sales appointment needs to be rescheduled. ",
//     "Sales appointment completed Scheduled with substantiation team. ",
//     "Substantiation appointment needs to be rescheduled. ",
//     "Initial substantiation meeting complete Substation in progress. ",
//     "Substantiation complete ",
//     "Sent to Hedge Fund ",
// ];

function KanbanBoard() {
    const [level, setLevel] = useState(0);
    const [parent, setParent] = useState(0);
    const [columns, setColumns] = useState<string[]>([]);
    const [selectedCategory, setSelectedCategory] = useState(-1);
    const [title, setTitle] = useState("Customer Onboarding");
    const [description, setDescription] = useState("Customer Onboarding");
    const [tasks, setTasks] = useState<Task[]>([]);
    const [isDropDisabled, setIsDropDisabled] = useState(false);
    const [activeColumn, setActiveColumn] = useState<Column | null>(null);
    const [movedFrom, setMovedFrom] = useState<Id | null>(null);

    const [activeTask, setActiveTask] = useState<Task | null>(null);
    const [user] = useRecoilState(userAtom);
    const authToken = getAuthToken();
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 1,
            },
        }),
    );

    const companiesQuery = useQuery(["companies"], async () => {
        const response = await companyService.getAll();
        if (response) {
            return response.data;
        }
    });
    const companyRepairStageQuery = useQuery(["companyRepairStages"], async () => {
        const response = await companyRepairStagesService.getAll();
        if (response) {
            return response.data;
        }
    });
    const repairStagesQuery = useQuery(["repairStages"], async () => {
        const response = await repairStagesService.getAll();
        if (response) {
            return response.data;
        }
    });
    const repairStageCategoriesQuery = useQuery(["repairStageCategories"], async () => {
        const response = await repairStageCategoriesService.getAll();
        if (response) {
            return response.data;
        }
    });

    useEffect(() => {
        if (companiesQuery.data && companyRepairStageQuery.data && repairStagesQuery.data) {
            setColumns(
                repairStagesQuery.data.map(
                    (repairStage) => (repairStage.title ?? "") + " " + repairStage.id?.toString(),
                ),
            );
            setTasks(
                companyRepairStageQuery.data.map((companyRepairStage) => {
                    const repairStage = repairStagesQuery.data?.find(
                        (repairStage) => repairStage.id === companyRepairStage.stage,
                    );
                    const company = companiesQuery.data?.find((company) => company.id === companyRepairStage.id);
                    return {
                        id: companyRepairStage.id ?? -1,
                        columnId: (repairStage?.title ?? "") + " " + repairStage?.id?.toString(),
                        content: company?.name ?? "",
                    };
                }),
            );
        }
    }, [companiesQuery.data, companyRepairStageQuery.data, repairStagesQuery.data]);

    useEffect(() => {
        if (repairStagesQuery.data) {
            console.log({ selectedCategory, data: repairStagesQuery.data });
            if (selectedCategory === -1) {
                setColumns(
                    repairStagesQuery.data.map(
                        (repairStage) => (repairStage.title ?? "") + " " + repairStage.id?.toString(),
                    ),
                );
            } else {
                setColumns(
                    repairStagesQuery.data
                        .filter((r) => r.parent === selectedCategory)
                        ?.map((repairStage) => (repairStage.title ?? "") + " " + repairStage.id),
                );
            }
        }
    }, [repairStagesQuery.data, selectedCategory]);

    if (
        companiesQuery.isLoading ||
        companyRepairStageQuery.isLoading ||
        repairStagesQuery.isLoading ||
        repairStageCategoriesQuery.isLoading
    ) {
        return <div>Loading...</div>;
    }

    // useEffect(() => {
    //     setColumns(levels[parent][level]);
    //     // switch (level) {
    //     //     case 0:
    //     // setColumns(defaultCols);
    //     //         setTasks(defaultTasks);
    //     //         break;
    //     // }
    // }, [level, parent]);

    function onDragStart(event: DragStartEvent) {
        // if (event.active.data.current?.type === "Column") {
        //     setActiveColumn(event.active.data.current.column);
        //     return;
        // }
        // setIsDropDisabled(event === "xyz");
        // console.log("From " + tasks[activeIndex].columnId + " to " + overId);
        // setMovedFrom(tasks[activeIndex].columnId);
        if (event.active.data.current?.type === "Task") {
            setActiveTask(event.active.data.current.task);
            setMovedFrom(event.active.data.current.task.columnId);
            return;
        }
    }

    async function onDragEnd(event: DragEndEvent) {
        // console.error(event);

        setActiveColumn(null);
        setActiveTask(null);

        const { active, over } = event;
        if (!over) return;

        const activeId = active.id;
        const overColumn = over.data.current?.task?.columnId;
        const overColumnId = repairStagesQuery.data?.find(
            (repairStage) => repairStage.title + " " + repairStage.id === overColumn,
        )?.id;
        const overId = over.id;

        const group = 28;
        const companyResult = await companyService.getOne(activeId);
        if (companyResult) {
            const ownerUserId = companyResult.data.ownedById ?? "";
            const [usersProgress] = await getProcessflowProgresses({
                authToken,
                filters: { userId: ownerUserId, group },
            });

            if (!overColumnId) return;
            companyRepairStagesService.update(activeId, { stage: overColumnId });
            companyRepairStageQuery.refetch();
            // console.error({ Company: activeId, overColumnId });

            console.log({ movedFrom, overColumn });
            const from = Number((movedFrom as any)?.replace(/\D/g, ""));
            const to = Number((overColumn as any)?.replace(/\D/g, ""));
            console.log({ from, to });
            const repairStageHistoryEntry: RepairStageHistoryType = {
                processflow: usersProgress.id,
                stageFrom: from,
                stageTo: to,
                changeWhen: new Date(),
                syncChange: 0,
                changeBy: user.id ?? "",
                notes: "",
            };
            repairStageHistoryService.create(repairStageHistoryEntry);
            console.log({ repairStageHistoryEntry });
        }

        if (activeId === overId) return;

        // const isActiveAColumn = active.data.current?.type === "Column";
        // if (!isActiveAColumn) return;

        console.log("DRAG END");

        // setColumns((columns) => {
        //     const activeColumnIndex = columns.findIndex((col) => col.id === activeId);

        //     const overColumnIndex = columns.findIndex((col) => col.id === overId);

        //     return arrayMove(columns, activeColumnIndex, overColumnIndex);
        // });
    }

    function onDragOver(event: DragOverEvent) {
        const { active, over } = event;
        if (!over) return;

        const activeId = active.id;
        const overId = over.id;

        if (activeId === overId) return;

        const isActiveATask = active.data.current?.type === "Task";
        const isOverATask = over.data.current?.type === "Task";

        if (!isActiveATask) return;

        // Im dropping a Task over another Task
        if (isActiveATask && isOverATask) {
            setTasks((tasks) => {
                const activeIndex = tasks.findIndex((t) => t.id === activeId);
                const overIndex = tasks.findIndex((t) => t.id === overId);

                if (tasks[activeIndex].columnId != tasks[overIndex].columnId) {
                    // if (checkCanMove)
                    tasks[activeIndex].columnId = tasks[overIndex].columnId;
                    return arrayMove(tasks, activeIndex, overIndex - 1);
                }

                return arrayMove(tasks, activeIndex, overIndex);
            });
        }

        const isOverAColumn = over.data.current?.type === "Column";

        // Im dropping a Task over a column
        if (isActiveATask && isOverAColumn) {
            setTasks((tasks) => {
                const activeIndex = tasks.findIndex((t) => t.id === activeId);

                tasks[activeIndex].columnId = overId;
                // console.log("DROPPING TASK OVER COLUMN", { activeIndex, overId, id: tasks[activeIndex] });
                // console.error(event);
                // const overColumn = over.data.current?.task?.columnId;
                const overColumnId = repairStagesQuery.data?.find(
                    (repairStage) => repairStage.title + " " + repairStage.id === overId,
                )?.id;
                companyRepairStagesService.update(event.active.id, { stage: overColumnId });
                // companyRepairStageQuery.refetch();

                return arrayMove(tasks, activeIndex, activeIndex);
            });
        }
    }

    return (
        <>
            <div className="mb-5">
                <SelectNeoGen
                    label="Section"
                    value={selectedCategory}
                    options={[{ id: -1, name: "All Categories" }].concat(
                        repairStageCategoriesQuery.data?.map((category) => {
                            return { id: category.id ?? -1, name: category.name };
                        }) ?? [],
                    )}
                    onChange={function (idx: string | number): void {
                        setSelectedCategory(idx as number);
                    }}
                ></SelectNeoGen>
            </div>
            {/* <PrintPre>{repairStageCategoriesQuery.data}</PrintPre> */}
            <div
                className="
        mb-auto
        flex
        pt-0
        w-full
        items-center
        overflow-x-auto
        overflow-y-hidden
        
    "
            >
                <DndContext
                    autoScroll={{
                        acceleration: 1,
                        threshold: {
                            x: 0.01,
                            y: 0.02,
                        },
                        interval: 100,
                    }}
                    sensors={sensors}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    onDragOver={onDragOver}
                >
                    <div className="mx-auto flex gap-4">
                        <div className="flex gap-4">
                            {/* <SortableContext items={columnsId}> */}
                            {columns.map((col) => (
                                <ColumnContainer
                                    key={col}
                                    column={col}
                                    tasks={tasks.filter((task) => task.columnId === col)}
                                />
                            ))}
                            {/* </SortableContext> */}
                        </div>
                    </div>
                </DndContext>
            </div>
        </>
    );
}

export default KanbanBoard;
