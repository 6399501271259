import React, { useEffect, useState } from "react";
import KanbanBoard from "./components/KanbanBoard";
import companyRepairStagesService from "../services/company-repair-stages.service";
import PageDescription from "./page-description";
import { useQuery } from "@tanstack/react-query";
import companyService from "../services/company.service";
import repairStagesService from "../services/repair-stages.service";
import UserCompanyPicker from "./user-company-picker";

export default function Kanban() {
    const [showCompanyPicker, setShowCompanyPicker] = useState(false);
    const companiesQuery = useQuery(["companies"], async () => {
        const response = await companyService.getAll();
        if (response) {
            return response.data;
        }
    });
    const companyRepairStageQuery = useQuery(["companyRepairStages"], async () => {
        const response = await companyRepairStagesService.getAll();
        if (response) {
            return response.data;
        }
    });
    const repairStagesQuery = useQuery(["repairStages"], async () => {
        const response = await repairStagesService.getAll();
        if (response) {
            return response.data;
        }
    });

    if (companiesQuery.isLoading || companyRepairStageQuery.isLoading || repairStagesQuery.isLoading) {
        return <div>Loading...</div>;
    }
    async function createCompanyRepairStage(newEntry: { id: number; stage: number }) {
        await companyRepairStagesService.createWithId(newEntry);
        await companyRepairStageQuery.refetch();
        setShowCompanyPicker(false);
    }
    return (
        <div className=" ">
            <PageDescription
                title={"ERC Repair Stage"}
                description="ERC Repair Stage"
                buttons={[
                    {
                        label: "Add Existing Company",
                        onClick: () => {
                            setShowCompanyPicker(true);
                        },
                    },
                    // {
                    //     label: "Add New Company",
                    //     onClick: () => {
                    //         console.log("Add");
                    //     },
                    // },
                ]}
            />
            <KanbanBoard />
            <UserCompanyPicker
                showUsers={false}
                companyList={companiesQuery.data ?? []}
                open={showCompanyPicker}
                setOpen={setShowCompanyPicker}
                setCompany={(company) => {
                    console.log(company);
                    const newEntry = {
                        id: company.id,
                        stage: 52,
                    };
                    console.log(newEntry);
                    createCompanyRepairStage(newEntry);
                }}
            />
        </div>
    );
}
