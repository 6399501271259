import React from "react";
import ModalDialog from "../modal-dialog";
import InputControlled from "../input-controlled";

export default function AddColumn({
    show,
    close,
    save,
}: {
    show: boolean;
    close: () => void;
    save: (name: string) => void;
}) {
    const [name, setName] = React.useState("");
    return (
        <ModalDialog close={close} show={show} title={"Add Column"} okAction={() => save(name ?? "")}>
            <InputControlled label="Column Name" value={name} onChange={(n: string) => setName(n)} />
        </ModalDialog>
    );
}
