export type HelpDocTemplate = {
    id?: number;
    name?: string;
};

import APIService from "./api.service";

class HelpDocsTemplateService extends APIService<HelpDocTemplate> {
    constructor() {
        super("help-docs-templates");
    }
}

export default new HelpDocsTemplateService();
