import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { MagicLinkTypes, magicLinkSchema } from "../domain/magic-link";

export const editMagicLinkSchema = z.object({
    id: z.string().uuid(),
    type: z.nativeEnum(MagicLinkTypes),
    code: z.string().nullish(),
    data: z.record(z.any()),
    usedAt: z.coerce.date().nullish(),
    options: z
        .object({ affiliateUserId: z.string().uuid().optional(), docCollectorId: z.string().uuid().optional() })
        .nullish(),
});

export type EditMagicLinkData = z.infer<typeof editMagicLinkSchema>;

export const editMagicLink = async ({ authToken, data }: { authToken: string; data: EditMagicLinkData }) => {
    const response = await makeRequest({
        method: "patch",
        path: `magic-links/${data.id}/${window.location.hostname}`,
        authToken,
        data,
    });

    const magicLink = parseSchema(magicLinkSchema, response.data);
    return magicLink;
};
