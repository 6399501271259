/* eslint-disable react/no-unescaped-entities */
import { useMutation } from "@tanstack/react-query";
import ModalDialog from "../../layout/modal-dialog";
import { assignMandates } from "../../companies/actions/assign-mandates";
import { useAuth } from "../../auth/use-auth";

export const AutoAssignMandatesModal = ({
    onClose,
    companyId,
    onAssigned,
}: {
    onClose: () => void;
    companyId: number;
    onAssigned?: () => void;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();

    const assignMandatesMutation = useMutation({
        mutationKey: ["assignMandates", { companyId }],
        mutationFn: async () => {
            await assignMandates({ authToken, companyId });
            if (onAssigned) {
                onAssigned();
            }
        },
    });

    return (
        <ModalDialog
            close={onClose}
            show
            title="Auto-Assign Mandates"
            okText="Auto-Assign Mandates"
            isLoading={assignMandatesMutation.isLoading}
            okAction={() => {
                assignMandatesMutation.mutate();
            }}
            disabled={assignMandatesMutation.isLoading}
        >
            <div style={{ marginBottom: 30 }}>
                Would you like to auto-assign mandates to this company? Auto-assigned mandates will appear with a status
                of "suggested" that will then need to be either accepted or rejected.
            </div>
        </ModalDialog>
    );
};
