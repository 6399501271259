import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { helpDocsTemplateSchema } from "../domain/help-docs-template";
import { helpDocsTemplateItemSchema } from "../domain/help-docs-template-item";

const createHelpDocsTemplateSchema = helpDocsTemplateSchema
    .partial({ id: true })
    .extend({ templateItems: z.array(helpDocsTemplateItemSchema) });

export type CreateHelpDocsTemplateData = z.infer<typeof createHelpDocsTemplateSchema>;

export const createHelpDocTemplate = async ({
    authToken,
    data,
}: {
    authToken: string;
    data: CreateHelpDocsTemplateData;
}) => {
    const response = await makeRequest({
        method: "post",
        path: "/help-docs-templates/setup",
        authToken,
        data,
    });

    const helpDocTemplate = parseSchema(helpDocsTemplateSchema, response.data);
    return helpDocTemplate;
};
