import { z } from "zod";

export const mandateEffectSchema = z.object({
    id: z.number(),
    mandateId: z.number(),
    mandateEffectId: z.number(),
    description: z.string().nullish(),
});

export type MandateMandateEffect = z.infer<typeof mandateEffectSchema>;
