import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import businessRulePerGroupService from "../../../services/business-rule-per-group.service";
import businessRuleTypeService from "../../../services/business-rule-type.service";
import businessRuleService from "../../../services/business-rule.service";
import comparisonTypeService from "../../../services/comparison-type.service";
import processflowService from "../../../services/processflow.service";
import { BusinessRule } from "../../../typings/api/business-rule";
import { BusinessRuleGroup } from "../../../typings/api/business-rule-group";
import { BusinessRuleType } from "../../../typings/api/business-rule-type";
import { BusinessRulesPerGroup } from "../../../typings/api/business-rules-per-group";
import { ProcessFlow } from "../../../typings/api/processflow";
import InputControlled from "../../../layout/input-controlled";
import ModalDialog from "../../../layout/modal-dialog";
import PrintPre from "../../../layout/print-pre";
import RadioList from "../../../layout/radio-list";
import SelectNeoGen from "../../../layout/select-neogen";
import SelectNeoGenDescription from "../../../layout/select-neogen-description";
import CalendarWidget from "../../utilities/calendar-widget";
import Loader2 from "../../utilities/Loader2";
import Toggle from "../../../layout/toggle";
import roleGroupService from "../../../services/role-group.service";
import SearchBox from "../../../layout/search-box";
import { SelectField } from "../../../layout/form/select-field";
import { RoleGroup } from "../../../typings/api/role-group";

export function compare(a: RoleGroup, b: RoleGroup) {
    if ((a.name ?? "").toLowerCase() < (b.name ?? "").toLowerCase()) {
        return -1;
    }
    if ((a.name ?? "").toLowerCase() > (b.name ?? "").toLowerCase()) {
        return 1;
    }
    return 0;
}

export default function AddEditBusinessRule(props: AddEditBusinessRuleProps) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState("");
    const [ruleType, setRuleType] = useState<any>();
    const navigate = useNavigate();
    const [processflow, setProcessflow] = useState<any>();
    const [question, setQuestion] = useState<any>();
    const [questionOptions, setQuestionOptions] = useState<any>();
    const [triggerStart, setTriggerStart] = useState("");
    const [triggerEnd, setTriggerEnd] = useState("");
    const [ruleTypeOptions, setRuleTypeOptions] = useState<any>();
    const cache = useQueryClient();
    const { id } = useParams();
    const [questionOrRoleGroup, setQuestionOrRoleGroup] = useState(0);
    const [roleGroup, setRoleGroup] = useState(0);

    useEffect(() => {
        const newEntries =
            JSON.parse(processflow?.entries ?? "[]")
                ?.filter((e: any) => e.type !== "valueCalculator" && e.field)
                .map((pe: any, idx: number) => {
                    return {
                        // id: idx,
                        // id: pe.field ?? pe.value,
                        name: pe.field ?? pe.value,
                        description: pe.description ?? " - ",
                        // description: "hi",
                        ...pe,
                    };
                }) ?? [];
        setQuestionOptions(newEntries);
    }, [processflow]);

    const ruleTypeQuery = useQuery(["business-rule-types2"], async () => {
        const response = await businessRuleTypeService.getAll();
        if (response) {
            return response.data;
        } else {
            return [];
        }
    });

    const roleGroupsQuery = useQuery(["role-groups"], async () => {
        const response = await roleGroupService.getAll();
        if (response) {
            return response.data;
        } else {
            return [];
        }
    });

    useEffect(() => {
        if (question) {
            setRuleTypeOptions(
                ruleTypeQuery.data?.filter((rt: any) => {
                    return rt.dataType === question.type;
                }) ?? [],
            );
        }
    }, [question, ruleTypeQuery.data]);

    const processflowQuery = useQuery(["processFlow"], async () => {
        const response = await processflowService.getAll();
        if (response) {
            return response.data;
        }
    });

    const comparisonTypeQuery = useQuery(["comparisonTypes"], async () => {
        const response = await comparisonTypeService.getAll();
        if (response) {
            return response.data;
        }
    });

    // TODO: Check for edit mode
    async function save() {
        const newEntry = {
            // "id"?: number,
            ruleType: ruleType?.id ?? -1,
            valueTriggerStart: questionOrRoleGroup === 1 ? roleGroup.toString() : triggerStart,
            valueTriggerEnd: triggerEnd,
            processflowId: processflow.id,
            fieldName: questionOrRoleGroup === 1 ? "Role Group" : question.name,
        };
        console.error(newEntry);
        const savedEntry = await businessRuleService.create(newEntry);
        if (savedEntry?.data.id) {
            const rulePerGroup: BusinessRulesPerGroup = {
                businessRule: savedEntry.data.id,
                businessRuleGroup: Number(props.group?.id ?? -1),
            };
            await businessRulePerGroupService.create(rulePerGroup);
            await cache.invalidateQueries(["business-rules-per-group"]);
            await cache.invalidateQueries(["business-products"]);
            await cache.invalidateQueries(["business-products2"]);
            Swal.fire({
                title: "Success",
                text: "Business rule created successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
            }).then(() => {
                props.close();
            });
        }
        // try {
        //     const response = await businessRuleService.create(name, description);
        //     if (response.id) {
        //         navigate("/business-rules");
        //     } else {
        //         setError(response.message);
        //     }
        // } catch (error: any) {
        //     setError(error.message);
        // }
    }

    function drawFields(ruleType: BusinessRuleType, question: any) {
        switch (ruleType.dataType) {
            case "roleGroup":
                return (
                    <>
                        <br />
                        <SelectNeoGen
                            label="Role Group"
                            value={roleGroup}
                            options={roleGroupsQuery.data
                                ?.map((r) => {
                                    return { name: r.name, id: r.id };
                                })
                                .sort(compare)}
                            onChange={(idx: any) => {
                                setRoleGroup(idx);
                            }}
                        />
                        <br />
                    </>
                );
            case "datePicker":
                console.error(triggerStart, Date.parse(triggerStart || "0"));
                return (
                    <div className="form-group">
                        <CalendarWidget
                            onChange={(e: Date) => {
                                setTriggerStart(e.toISOString());
                            }}
                            value={new Date()}
                            label={ruleType.usesField2 ? "First Date" : "Date"}
                            description={"Please select the " + (ruleType.usesField2 ? "first date" : "date")}
                        />
                        {ruleType.usesField2 === 1 && (
                            <CalendarWidget
                                onChange={(e: Date) => {
                                    setTriggerStart(e.toISOString());
                                }}
                                value={new Date(Date.parse(triggerStart || "0"))}
                                label={"Second Date"}
                                description={"Please select the second date"}
                            />
                        )}
                    </div>
                );

            // case "number":
            // case "textField":

            case "yesNo":
                return (
                    <div className="form-group">
                        <SelectNeoGen
                            label={ruleType.usesField2 === 1 ? "First Choice" : "Choice"}
                            value={triggerStart}
                            onChange={(e) => setTriggerStart(e.toString())}
                            options={[
                                // { id: "", name: "Select an option" },
                                { id: "1", name: "Yes" },
                                { id: "0", name: "No" },
                            ]}
                        />
                        {ruleType.usesField2 === 1 && (
                            <SelectNeoGen
                                label={"Second Choice"}
                                value={triggerEnd}
                                onChange={(e) => setTriggerEnd(e.toString())}
                                options={[
                                    // { id: "", name: "Select an option" },
                                    { id: "1", name: "Yes" },
                                    { id: "0", name: "No" },
                                ]}
                            />
                        )}
                    </div>
                );
            case "selectBox":
                return (
                    <div className="form-group">
                        <SelectNeoGen
                            label={"Value Trigger Start"}
                            value={triggerStart}
                            onChange={(e) => setTriggerStart(e.toString())}
                            options={
                                // { id: "", name: "Select an option" },

                                question.selectBoxEntries.map((e: any) => {
                                    return {
                                        id: e,
                                        name: e,
                                    };
                                })
                            }
                        />
                        {ruleType.usesField2 === 1 && (
                            <SelectNeoGen
                                label={"Value Trigger End"}
                                value={triggerEnd}
                                onChange={(e) => setTriggerEnd(e.toString())}
                                options={question.selectBoxEntries.map((e: any) => {
                                    return {
                                        id: e,
                                        name: e,
                                    };
                                })}
                            />
                        )}
                    </div>
                );
            default:
                return (
                    <div className="form-group">
                        <InputControlled
                            label={"Value Trigger Start"}
                            value={triggerStart}
                            setValue={setTriggerStart}
                        />
                        {ruleType.usesField2 === 1 && (
                            <InputControlled label={"Value Trigger End"} value={triggerEnd} setValue={setTriggerEnd} />
                        )}
                    </div>
                );
            // case "checkBox":
            //     return (
            //         <div className="form-group">
        }
    }
    useEffect(() => {
        if (questionOrRoleGroup === 1) {
            setProcessflow(-1);
            setQuestion(-1);
            const rt = ruleTypeQuery.data?.find((rt: BusinessRuleType) => {
                console.error(rt);
                return rt.dataType === "roleGroup";
            });
            setRuleType(rt);
        }
    }, [questionOrRoleGroup, ruleTypeQuery.data]);

    return (
        <>
            <ModalDialog size="md" show={props.show} title="Add/Edit Business Rule" okAction={save} close={props.close}>
                {roleGroupsQuery.isLoading ||
                ruleTypeQuery.isLoading ||
                comparisonTypeQuery.isLoading ||
                processflowQuery.isLoading ? (
                    <Loader2 />
                ) : (
                    <>
                        <Toggle
                            options={["Looks at question", "Looks for Role Group"]}
                            selectedIndex={questionOrRoleGroup}
                            setSelectedIndex={setQuestionOrRoleGroup}
                        />
                        {questionOrRoleGroup === 0 && (
                            <>
                                <SelectNeoGen
                                    label="Process Flow"
                                    className="mt-5"
                                    options={processflowQuery.data
                                        ?.map((pe: ProcessFlow) => {
                                            return {
                                                id: pe.id ?? -1,
                                                name: pe.title + " ID: " + pe.id + " Group: " + pe.group,
                                            };
                                        })
                                        .sort((a: any, b: any) => {
                                            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                                                return -1;
                                            }
                                            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                                                return 1;
                                            }
                                            return 0;
                                        })}
                                    value={processflow?.id}
                                    onChange={function (idx: string | number, text?: string | undefined): void {
                                        const pf = processflowQuery.data?.find((predicate) => predicate.id === idx);
                                        setRuleType(undefined);
                                        setQuestion(undefined);
                                        setProcessflow(pf);
                                    }}
                                />

                                {/* a<PrintPre>{(JSON.parse(processflow?.entries ?? "[]"))}</PrintPre>b */}
                                {processflow && (
                                    <RadioList
                                        className="mb-5"
                                        label="Question"
                                        options={questionOptions}
                                        value={question}
                                        onChange={function (idx: any): void {
                                            setQuestion(idx);
                                            // throw new Error("Function not implemented.");
                                        }}
                                    />
                                )}
                                {question && (
                                    <>
                                        <RadioList
                                            className="mb-5"
                                            label="Rule Type"
                                            options={ruleTypeOptions ?? []}
                                            value={ruleType}
                                            onChange={function (idx: any): void {
                                                setRuleType(idx);
                                                // throw new Error("Function not implemented.");
                                            }}
                                        />
                                    </>
                                )}
                            </>
                        )}
                        <>{ruleType && <>{drawFields(ruleType, question)}</>}</>
                    </>
                )}
            </ModalDialog>
        </>
    );
}

type AddEditBusinessRuleProps = {
    show: boolean;
    close: () => void;
    rule?: BusinessRule;
    group?: BusinessRuleGroup | null;
};
