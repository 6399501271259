import { Td, Tr } from "../../layout/table";
import { startCase } from "lodash";
import { format } from "date-fns";
import { Select } from "../../layout/form/select-input";
import { Link } from "react-router-dom";
import { ResponsiveEllipsis } from "../../layout/text";
import { TextAreaInput } from "../../layout/form/text-area-input";
import { Mandate } from "../../mandates/domain/mandate";
import { MandateMandateEffect } from "../../mandate-mandate-effects/domain/mandate-mandate-effect";
import { IndustryGroup } from "../../industry-groups/domain/industry-group";
import { CompanyMandateAssignment } from "../../company-mandate-assignments/domain/company-mandate-assignment";
import { MandateEffect } from "../../mandate-effects/domain/mandate-effect";

export const MandateRow = ({
    mandate,
    mandateEffect,
    mandateMandateEffect,
    industryGroup,
    companyMandateAssignment,
    onImpactChange,
    onStatusChange,
    onRejectionReasonChange,
    onMandateMandateEffectClick,
}: {
    mandate?: Mandate;
    mandateEffect?: MandateEffect;
    mandateMandateEffect?: MandateMandateEffect;
    industryGroup?: IndustryGroup;
    companyMandateAssignment: CompanyMandateAssignment;
    onImpactChange: (val: string) => void;
    onStatusChange: (val: string) => void;
    onRejectionReasonChange: (val: string) => void;
    onMandateMandateEffectClick: (mandateMandateEffectId: number) => void;
}) => {
    return (
        <Tr key={companyMandateAssignment.id}>
            <Td style={{ verticalAlign: "top" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ fontWeight: 500 }}>
                        <Link to={`/mandates/${mandate?.id}`}>{mandate?.name}</Link>
                    </div>
                    <div style={{ color: "gray", fontSize: 14 }}>
                        Date: {mandate?.date && format(mandate.date, "yyyy-MM-dd")}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ color: "gray", fontSize: 14 }}>
                            {mandate?.state && <div>State: {mandate?.state}</div>}
                            {mandate?.counties && <div>County: {mandate?.counties.join(", ")}</div>}
                            {mandate?.cities && <div>City: {mandate?.cities.join(", ")}</div>}
                        </div>
                    </div>
                </div>
            </Td>
            <Td style={{ verticalAlign: "top" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ fontWeight: 500 }}>
                        {startCase(mandateEffect?.effectName || "")} {industryGroup ? `(${industryGroup.name})` : null}
                    </div>
                    <div
                        style={{
                            fontSize: 13,
                            color: "gray",
                            ...(mandateMandateEffect?.id ? { cursor: "pointer" } : {}),
                        }}
                        onClick={
                            mandateMandateEffect?.id
                                ? () => onMandateMandateEffectClick(mandateMandateEffect.id)
                                : undefined
                        }
                    >
                        <ResponsiveEllipsis
                            text={mandateMandateEffect?.description || ""}
                            maxLine="4"
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                        />
                    </div>
                </div>
            </Td>
            <Td>
                {companyMandateAssignment.status === "rejected" && (
                    <div>
                        <TextAreaInput
                            defaultValue={companyMandateAssignment.rejectionReason || ""}
                            onBlur={(e, value) => {
                                onRejectionReasonChange(value);
                            }}
                        />
                    </div>
                )}
                {companyMandateAssignment.status !== "rejected" && (
                    <div>
                        <TextAreaInput
                            defaultValue={companyMandateAssignment.impact || ""}
                            onBlur={(e, value) => {
                                onImpactChange(value);
                            }}
                        />
                    </div>
                )}
            </Td>
            <Td style={{ verticalAlign: "top" }}>
                <Select
                    options={[
                        { label: "Suggested", value: "suggested" },
                        { label: "Accepted", value: "accepted" },
                        { label: "Rejected", value: "rejected" },
                    ]}
                    value={companyMandateAssignment.status || null}
                    onChange={(value: any) => {
                        onStatusChange(value);
                    }}
                />
            </Td>
        </Tr>
    );
};
