import { useMutation } from "@tanstack/react-query";
import { CreateHelpDocsTemplateData, createHelpDocTemplate } from "../actions/create-help-docs-template";

export const useCreateHelpDocsTemplateMutation = () => {
    const mutation = useMutation({
        mutationFn: async ({ authToken, data }: { authToken: string; data: CreateHelpDocsTemplateData }) => {
            const helpDocsTemplate = await createHelpDocTemplate({ authToken, data });
            return helpDocsTemplate;
        },
    });
    return mutation;
};
