import { z } from "zod";

export const mandateSchema = z.object({
    id: z.number(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    status: z.string().nullish(),
    processedByAiAt: z.coerce.date().nullish(),
    aiVersion: z.number().nullish(),
    needsAiSync: z.boolean().nullish(),
    state: z.string().nullish(),
    counties: z.array(z.string()).nullish(),
    cities: z.array(z.string()).nullish(),
    name: z.string().nullish(),
    summary: z.string().nullish(),
    date: z.coerce.date().nullish(),
    until: z.coerce.date().nullish(),
    extendedUntil: z.coerce.date().nullish(),
    orderText: z.string().nullish(),
    url: z.string().nullish(),
    authority: z.string().nullish(),
});

export type Mandate = z.infer<typeof mandateSchema>;
