import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { HelpDocTemplate } from "../domain/help-docs-template";
import { getHelpDocsTemplates } from "../actions/get-help-docs-templates";

type Filters = { where?: any };

const getHelpDocsTemplatesQueryKey = ({ authToken, filters }: { authToken: string; filters?: Filters }) => {
    return ["help-docs-templates", { authToken, filters }];
};

export const useHelpDocsTemplates = (
    { authToken, filters }: { authToken: string; filters?: Filters },
    options?: UseQueryOptions<HelpDocTemplate[]>,
) => {
    const query = useQuery({
        queryKey: getHelpDocsTemplatesQueryKey({ authToken, filters }),
        queryFn: async () => {
            const helpDocsTemplates = await getHelpDocsTemplates({ authToken, filters });
            return helpDocsTemplates;
        },
        ...options,
    });
    return query;
};
