import { z } from "zod";
import { Form } from "../../layout/form/form";
import ModalDialog from "../../layout/modal-dialog";
import { useForm } from "../../hooks/useForm";
import { SelectField } from "../../layout/form/select-field";
import { useMandates } from "../../mandates/hooks/use-mandates";
import { useAuth } from "../../auth/use-auth";
import { useMemo } from "react";
import { useMandateEffects } from "../../mandate-effects/hooks/use-mandate-effects";
import { useIndustryGroups } from "../../industry-groups/hooks/use-industry-groups";
import { orderBy, startCase } from "lodash";
import { useMutation } from "@tanstack/react-query";
import {
    CreateCompanyMandateAssignment,
    createCompanyMandateAssignment,
} from "../actions/create-company-mandate-assignment";
import { CompanyMandateAssignment } from "../domain/company-mandate-assignment";

const schema = z.object({
    mandateId: z.number(),
    mandateEffectId: z.number(),
    industryGroupId: z.number().optional(),
});

type Data = z.infer<typeof schema>;

export const AddMandateAssignmentModal = ({
    onClose,
    onCreated,
    companyId,
}: {
    onClose: () => void;
    onCreated?: (companyMandateAssignment: CompanyMandateAssignment) => void;
    companyId: number;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const form = useForm({ schema });

    const mandatesQuery = useMandates({ authToken });
    const mandates = useMemo(() => mandatesQuery.data?.data || [], [mandatesQuery.data]);
    const mandateOptions = useMemo(
        () =>
            orderBy(
                mandates.map((m) => ({ label: `${m.state} - ${m.name || "No name"}`, value: m.id })),
                (op) => op.label,
            ),
        [mandates],
    );

    const mandateEffectsQuery = useMandateEffects({ authToken });
    const mandateEffects = useMemo(() => mandateEffectsQuery.data || [], [mandateEffectsQuery.data]);
    const mandateEffectOptions = useMemo(
        () =>
            orderBy(
                mandateEffects.map((m) => ({ label: startCase(m.effectName || "No name"), value: m.id })),
                (op) => op.label,
            ),
        [mandateEffects],
    );

    const industryGroupsQuery = useIndustryGroups({ authToken });
    const industryGroups = useMemo(() => industryGroupsQuery.data || [], [industryGroupsQuery.data]);
    const industryGroupOptions = useMemo(
        () =>
            orderBy(
                industryGroups.map((m) => ({ label: startCase(m.name || "No name"), value: m.id })),
                (op) => op.label,
            ),
        [industryGroups],
    );

    const createCompanyMandateAssignmentMutation = useMutation({
        mutationFn: (data: CreateCompanyMandateAssignment) =>
            createCompanyMandateAssignment({ authToken, data: { ...data, companyId, status: "accepted" } }),
    });

    const handleSubmit = async (data: Data) => {
        const companyMandateAssignment = await createCompanyMandateAssignmentMutation.mutateAsync(data);
        if (companyMandateAssignment && onCreated) {
            onCreated(companyMandateAssignment);
        }
    };

    return (
        <Form onSubmit={form.handleSubmit(handleSubmit)}>
            <ModalDialog
                title={"Add Mandate Effect"}
                close={onClose}
                show
                okText="Add Mandate Effect"
                okAction={form.handleSubmit(handleSubmit)}
            >
                <SelectField isRequired label="Mandate" {...form.getFieldProps("mandateId")} options={mandateOptions} />
                <SelectField
                    isRequired
                    label="Effect"
                    {...form.getFieldProps("mandateEffectId")}
                    options={mandateEffectOptions}
                />
                <SelectField
                    label="Industry Group"
                    {...form.getFieldProps("industryGroupId")}
                    options={industryGroupOptions}
                />
            </ModalDialog>
        </Form>
    );
};
