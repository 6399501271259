import React from "react";
import PaymentStatus from "./paymentStatus";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");
const options = {
    // clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
        /*...*/
    },
};
function PaymentComplete() {
    return (
        <>
            <Elements stripe={stripePromise} options={options}>
                PaymentComplete Status: <PaymentStatus />
            </Elements>
        </>
    );
}

export default PaymentComplete;
