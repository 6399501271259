import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import ModalDialog from "../../../../layout/modal-dialog";
import InputControlled from "../../../../layout/input-controlled";
import TextAreaNeoGenControlled from "../../../../layout/text-area-controlled";
import mandateEffectService, { MandateEffect } from "../../../../services/mandate-effect.service";

export default function AddMandateEffect(props: AddMandateEffectProps) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const queryCache = useQueryClient();

    useEffect(() => {
        setTimeout(() => {
            if (props.mandateEffect?.id) {
                setName(props.mandateEffect.effectName ?? "");
                setDescription(props.mandateEffect.notes ?? "");
            } else {
                setName("");
                setDescription("");
            }
        }, 1);
    }, [props]);

    async function saveMandateEffect() {
        if (props.mandateEffect?.id) {
            // edit
            await mandateEffectService.update(props.mandateEffect.id, { effectName: name, notes: description });
        } else {
            // add
            await mandateEffectService.create({ effectName: name, notes: description });
        }
        queryCache.invalidateQueries(["mandateEffects"]);

        props.close();
    }

    return (
        <ModalDialog
            close={props.close}
            show={props.show}
            title={props.mandateEffect ? "Edit Mandate Effect" : "Add Mandate Effect"}
            okAction={() => {
                saveMandateEffect();
            }}
            okText={props.mandateEffect ? "Save Mandate Effect" : "Add Mandate Effect"}
        >
            <InputControlled
                label="Name"
                value={name}
                onChange={(value) => {
                    setName(value);
                }}
            />
            <TextAreaNeoGenControlled
                label={"Description"}
                value={description}
                setValue={function (e: string): void {
                    setDescription(e);
                }}
            />
        </ModalDialog>
    );
}

type AddMandateEffectProps = {
    show: boolean;
    close: () => void;
    mandateEffect?: MandateEffect | null;
};
