import React, { useState } from "react";
import ModalDialog from "../../layout/modal-dialog";
import { NeoGenHelpDocs } from "../../typings/api";
import PrintPre from "../../layout/print-pre";
import { useQuery } from "@tanstack/react-query";
import packageSectionRulesService, { PackageSectionRule } from "../../services/package-section-rules.service";
import { useNavigate, useParams } from "react-router-dom";
import helpDocsService from "../../services/help-docs.service";
import PageDescription from "../../layout/page-description";
import TableNeogen from "../../layout/table-neogen";
import RulesModal from "./modals/rules-modal";
import SwalNeogenFire from "../../layout/swal-neogen";

export default function Rules() {
    const [showRules, setShowRules] = useState(false);
    const [rule, setRule] = useState<PackageSectionRule | null>(null);
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const helpDocsQuery = useQuery(["HelpDocs", id], async () => {
        const response = await helpDocsService.getOne(id ?? -1);
        return response?.data;
    });
    const rulesQuery = useQuery(
        ["packageSectionRules", id],
        async () => {
            const response = await packageSectionRulesService.getFilteredWhere({ helpDoc: Number(id) });
            return response?.data ?? [];
        },
        { enabled: !!id },
    );
    if (helpDocsQuery.isLoading || rulesQuery.isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <PageDescription
                title={"Rules For " + helpDocsQuery.data?.title}
                description={
                    "Rules turn on or off sections of the generated document based on the answers to questions."
                }
                buttons={[
                    {
                        label: "Go back",
                        type: "info",
                        onClick: () => {
                            navigate("/faq");
                        },
                        icon: "fas fa-chevron-left",
                    },
                    {
                        label: "Add Rule",
                        onClick: () => {
                            setRule(null);
                            setShowRules(true);
                        },
                        icon: "fas fa-plus",
                    },
                ]}
            />
            <TableNeogen
                entries={rulesQuery.data ?? []}
                ignoreFields={["helpDoc"]}
                formatters={[{ field: "isEnabler", type: "Boolean" }]}
                actions={[
                    {
                        label: "Edit",
                        onClick: (entry) => {
                            setRule(rulesQuery.data?.find((r) => r.id === Number(entry)) ?? null);
                            setShowRules(true);
                        },
                        icon: "fas fa-edit",
                    },
                    {
                        label: "Delete",
                        type: "danger",
                        onClick: (entry) => {
                            SwalNeogenFire({
                                title: "Are you sure?" + id,
                                text: "You will not be able to recover this rule!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Yes, delete it!",
                                cancelButtonText: "No, keep it",
                            }).then(async (result) => {
                                if (result.isConfirmed) {
                                    await packageSectionRulesService.deleteByID(entry);
                                    rulesQuery.refetch();
                                    SwalNeogenFire({
                                        title: "Deleted!",
                                        text: "Your rule has been deleted.",
                                        icon: "success",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                } else {
                                    SwalNeogenFire({
                                        title: "Cancelled",
                                        text: "Your rule is safe :)",
                                        icon: "error",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                }
                            });
                        },
                        icon: "fas fa-trash",
                    },
                ]}
            />
            <RulesModal show={showRules} close={() => setShowRules(false)} entry={rule ?? null} />
        </div>
    );

    // const rulesQuery = useQuery(["packageSectionRules"], async () => {
    //     const response = await packageSectionRulesService.getFilteredWhere({ helpDoc: entry?.id });
    //     return response?.data ?? [];
    // });
    // return (
    //     <ModalDialog close={close} show={show} title={entry?.title + " Rules"}>
    //         <PrintPre>{rulesQuery.data}</PrintPre>
    //     </ModalDialog>
    // );
}
