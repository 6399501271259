import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { companyLineItemSchema } from "../domain/company-line-item";

export const getCompanyLineItems = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/company-line-items",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const companyLineItems = parseSchema(z.array(companyLineItemSchema), response.data);

    return companyLineItems;
};
