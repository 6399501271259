import { useAuth } from "../../auth/use-auth";
import ModalDialog from "../../layout/modal-dialog";
import { useDeleteCompanyLineItem } from "../hooks/use-delete-company-line-item";

export const RemoveLineItemModal = ({
    onClose,
    companyLineItemId,
    onRemoved,
}: {
    onClose: () => void;
    companyLineItemId: number;
    onRemoved: () => void;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();

    const deleteLineItemMutation = useDeleteCompanyLineItem();

    const handleRemoveLineItem = async () => {
        await deleteLineItemMutation.mutateAsync({ authToken, id: companyLineItemId });
        onRemoved();
    };

    return (
        <ModalDialog
            title={"Remove Line Item"}
            show
            close={onClose}
            okText="Remove Line Item"
            okAction={handleRemoveLineItem}
        >
            Are you sure you want to remove this line item?
        </ModalDialog>
    );
};
