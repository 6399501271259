import { z } from "zod";
import { userSchema } from "../../jason-proof-of-concept/users/domain/user";
import { companySchema } from "../../companies/domain/company";

export enum PaymentRequestStatus {
    Pending = "pending",
    Complete = "complete",
    Cancelled = "cancelled",
}

export const paymentRequestSchema = z.object({
    id: z.string().uuid(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    description: z.string().nullish(),
    requestedPaymentDate: z.coerce.date().nullish(),
    paymentDate: z.coerce.date().nullish(),
    paymentCapturedById: z.string().nullish(),
    paymentCapturedBy: userSchema.nullish(),
    companyId: z.number(),
    company: companySchema.optional(),
    amount: z.number(),
    to: z.string().nullish(),
    toClearErc: z.boolean().nullish(),
    toUserId: z.string().uuid().nullish(),
    toUser: userSchema.nullish(),
    toCompanyId: z.number().nullish(),
    toCompany: companySchema.nullish(),
    from: z.string().nullish(),
    fromClearErc: z.boolean().nullish(),
    fromUserId: z.string().uuid().nullish(),
    fromUser: userSchema.nullish(),
    fromCompanyId: z.number().nullish(),
    fromCompany: companySchema.nullish(),
    lineItemId: z.number().nullish(),
    status: z.nativeEnum(PaymentRequestStatus).nullish(),
    notes: z.string().nullish(),
    type: z.enum(["fee"]).nullish(),
});

export type PaymentRequest = z.infer<typeof paymentRequestSchema>;
