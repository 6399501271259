import { useQuery } from "@tanstack/react-query";
import { getMandateEffects } from "../actions/get-mandate-effects";

type Params = {
    authToken: string;
    filters?: any;
};

const getMandateEffectsQueryKey = ({ authToken, filters }: Params) => {
    return ["mandateEffects", { authToken, filters }];
};

export const useMandateEffects = ({ authToken, filters }: Params, options?: { enabled?: boolean }) => {
    const query = useQuery({
        queryKey: getMandateEffectsQueryKey({ authToken, filters }),
        queryFn: async () => {
            const mandateEffects = await getMandateEffects({ authToken, filters });
            return mandateEffects;
        },
        keepPreviousData: true,
        ...options,
    });
    return query;
};
