import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { TextField } from "../../../layout/form/text-field";
import { useQuery } from "@tanstack/react-query";
import helpDocsService from "../../../services/help-docs.service";
import CheckBoxNeoGenControlled from "../../../layout/checkbox-controlled";
import { useState, useMemo, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useUpdateHelpDocsTemplateMutation } from "../../../help-docs/hooks/use-update-help-docs-template";
import { useDeleteHelpDocsTemplateMutation } from "../../../help-docs/hooks/use-delete-help-docs-template";

const schema = z.object({
    name: z.string(),
});

type Data = z.infer<typeof schema>;

const ITEM_TYPE = "HELP_DOC";

const DraggableCheckBox = ({
    id,
    title,
    isChecked,
    onToggle,
    onDrag,
}: {
    id: number;
    title: string;
    isChecked: boolean;
    onToggle: (id: number) => void;
    onDrag: (draggedId: number, targetId: number) => void;
}) => {
    // @ts-ignore
    const [{ isDragging }, drag] = useDrag({
        type: ITEM_TYPE,
        item: { id, title },
    });

    const [, drop] = useDrop({
        accept: ITEM_TYPE,
        hover: (draggedItem: { id: number }) => {
            if (draggedItem.id !== id) {
                onDrag(draggedItem.id, id);
            }
        },
    });

    return (
        <div ref={(node) => drag(drop(node))} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <CheckBoxNeoGenControlled label={title} setValue={() => onToggle(id)} value={isChecked} name="" />
        </div>
    );
};

export const EditPackageTemplateModal = ({
    onClose,
    templateId,
    name,
    templateItems,
}: {
    onClose: () => void;
    templateId: number;
    name: string;
    templateItems: any;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const helpDocsQuery = useQuery(["HelpDocs"], async () => {
        const response = await helpDocsService.getAll();
        if (response) {
            return response.data;
        }
    });
    const helpDocs = useMemo(() => helpDocsQuery.data || [], [helpDocsQuery.data]);

    const [enabledDocs, setEnabledDocs] = useState<
        {
            id: number;
            isChecked: boolean;
        }[]
    >(templateItems.map((item: any) => ({ id: item.helpDocId, isChecked: true })));

    useEffect(() => {
        if (helpDocs?.length > 0) {
            const templateItemIds = templateItems.map((item: any) => item.helpDocId);
            setEnabledDocs([
                ...enabledDocs,
                ...helpDocs
                    .filter((item) => !templateItemIds.includes(item.id))
                    .map((doc) => ({ id: doc.id as number, isChecked: false })),
            ]);
        }
    }, [helpDocs]);

    const form = useForm({
        schema,
        defaultValues: {
            name: name,
        },
    });

    const updateHelpDocsTemplateMutation = useUpdateHelpDocsTemplateMutation();

    const handleSubmit = async (data: Data) => {
        const template = await updateHelpDocsTemplateMutation.mutateAsync({
            id: templateId,
            authToken,
            data: {
                name: data.name,
                templateItems: enabledDocs
                    .filter((d) => d.isChecked)
                    .map((item, index) => ({
                        helpDocId: item.id,
                        order: index,
                    })),
            },
        });
        if (template.status === 204) {
            onClose();
        }
    };

    const deleteHelpDocsTemplateMutation = useDeleteHelpDocsTemplateMutation();

    const handleDelete = async () => {
        const template = await deleteHelpDocsTemplateMutation.mutateAsync({
            id: templateId,
            authToken,
        });
        if (template.status === 204) {
            setShowDeleteModal(false);
            onClose();
        }
    };

    const onToggle = (id: number) => {
        const updatedEnabledDocs = [...enabledDocs];
        const docIndex = updatedEnabledDocs.findIndex((doc) => doc.id === id);
        updatedEnabledDocs[docIndex].isChecked = !updatedEnabledDocs[docIndex].isChecked;
        setEnabledDocs(updatedEnabledDocs);
    };

    const onDrag = (draggedId: number, targetId: number) => {
        const updatedEnabledDocs = [...enabledDocs];
        const draggedIndex = updatedEnabledDocs.findIndex((doc) => doc.id === draggedId);
        const targetIndex = updatedEnabledDocs.findIndex((doc) => doc.id === targetId);
        [updatedEnabledDocs[draggedIndex], updatedEnabledDocs[targetIndex]] = [
            updatedEnabledDocs[targetIndex],
            updatedEnabledDocs[draggedIndex],
        ];
        setEnabledDocs(updatedEnabledDocs);
    };
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    return (
        <>
            {showDeleteModal && (
                <ModalDialog
                    show
                    title={"Delete package template"}
                    close={() => setShowDeleteModal(false)}
                    showOk={false}
                    showCancel={false}
                    size="xs"
                >
                    <p className="text-center">Are you sure you want to delete this template?</p>
                    <div className="flex justify-end gap-4 mt-3">
                        <ButtonNeoGen type="outline" onClick={() => setShowDeleteModal(false)}>
                            Cancel
                        </ButtonNeoGen>
                        <ButtonNeoGen onClick={handleDelete}>Delete template</ButtonNeoGen>
                    </div>
                </ModalDialog>
            )}
            <ModalDialog
                show
                title={"Update package template"}
                close={onClose}
                showOk={false}
                showCancel={false}
                size="sm"
            >
                <p className="text-center mb-6 text-gray-400">Select which help docs to include and their order.</p>
                <TextField label="Template name" {...form.getFieldProps("name")} />
                <Form
                    onSubmit={form.handleSubmit(handleSubmit as any)}
                    error={updateHelpDocsTemplateMutation.error as any}
                >
                    {enabledDocs?.map((helpDoc) => (
                        <div key={helpDoc.id}>
                            <DraggableCheckBox
                                id={helpDoc.id}
                                title={helpDocs.find((item) => item.id === helpDoc.id)?.title || ""}
                                isChecked={helpDoc.isChecked}
                                onToggle={onToggle}
                                onDrag={onDrag}
                            />
                        </div>
                    ))}
                    <div className="flex justify-between mt-3">
                        <ButtonNeoGen
                            type="danger"
                            disabled={updateHelpDocsTemplateMutation.isLoading}
                            onClick={() => setShowDeleteModal(true)}
                        >
                            Delete template
                        </ButtonNeoGen>
                        <div className="flex justify-end gap-4">
                            <ButtonNeoGen
                                type="outline"
                                disabled={updateHelpDocsTemplateMutation.isLoading}
                                onClick={() => onClose()}
                            >
                                Cancel
                            </ButtonNeoGen>
                            <ButtonNeoGen type="submit" disabled={updateHelpDocsTemplateMutation.isLoading}>
                                Update template
                            </ButtonNeoGen>
                        </div>
                    </div>
                </Form>
            </ModalDialog>
        </>
    );
};
