import axios from "axios";

export function sendSlackDebug(message: string) {
    const url =
        process.env.REACT_APP_SLACK_DEBUG_ROOM ??
        "https://hooks.slack.com/services/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";
    const data = {
        text: message,
    };
    axios.post(url, JSON.stringify(data), {
        withCredentials: false,
    });
}

export function sendSlackSale(message: string) {
    const url =
        process.env.REACT_APP_SLACK_SALE_ROOM ??
        "https://hooks.slack.com/services/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";
    const data = {
        text: message,
    };
    axios.post(url, JSON.stringify(data), {
        withCredentials: false,
    });
}

export function sendSlackMessage(url: string, header: string, message: string) {
    axios.post(
        url,
        JSON.stringify({
            blocks: [
                {
                    type: "header",
                    text: {
                        type: "plain_text",
                        text: `${header}`,
                        emoji: true,
                    },
                },
                {
                    type: "divider",
                },
                {
                    type: "context",
                    elements: [
                        {
                            type: "plain_text",
                            text: `${message}`,
                            emoji: true,
                        },
                    ],
                },
            ],
        }),
        {
            withCredentials: false,
        },
    );
}
