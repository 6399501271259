import { z } from "zod";
import { CompanyLineItem } from "../domain/company-line-item";
import { useAuth } from "../../auth/use-auth";
import { useForm } from "../../hooks/useForm";
import { useMutation } from "@tanstack/react-query";
import { createCompanyLineItem } from "../actions/create-company-line-item";
import { Form } from "../../layout/form/form";
import ModalDialog from "../../layout/modal-dialog";
import { SelectField } from "../../layout/form/select-field";
import { LineItemsType } from "../../services/line-items.service";
import { orderBy } from "lodash";

const schema = z.object({
    lineItemId: z.number(),
});

type Data = z.infer<typeof schema>;

export const NewLineItemModal = ({
    onClose,
    onCreate,
    companyId,
    lineItems,
}: {
    onClose: () => void;
    onCreate: (companyLineItem: CompanyLineItem) => void;
    companyId: number;
    lineItems: LineItemsType[];
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const form = useForm({ schema });

    const mutation = useMutation({
        mutationFn: async (data: Data) => {
            const companyLineItem = await createCompanyLineItem({ authToken, data: { ...data, companyId } });
            return companyLineItem;
        },
    });

    const handleSubmit = form.handleSubmit(async (data) => {
        const companyLineItem = await mutation.mutateAsync(data);
        onCreate(companyLineItem);
    });

    const lineItemOptions = orderBy(
        lineItems.map((li) => ({ label: li.name, value: li.id as number })),
        (op) => op.label,
    );

    return (
        <Form onSubmit={handleSubmit} error={mutation.error as any}>
            <ModalDialog title="New Location" show close={onClose} okAction={handleSubmit} okText="Add Line Item">
                <SelectField label="Line Item" options={lineItemOptions} {...form.getFieldProps("lineItemId")} />
            </ModalDialog>
        </Form>
    );
};
