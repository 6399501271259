import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { mandateEffectSchema } from "../domain/mandate-mandate-effect";

export const getMandateMandateEffects = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/mandate-mandate-effects",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const mandateMandateEffects = parseSchema(z.array(mandateEffectSchema), response.data || []);

    return mandateMandateEffects;
};
