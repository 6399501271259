import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { companyLocationSchema } from "../domain/company-location";

export const getCompanyLocations = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/company-locations",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const companyLocations = parseSchema(z.array(companyLocationSchema), response.data);

    return companyLocations;
};
