import { z } from "zod";
import ModalDialog from "../../layout/modal-dialog";
import { useForm } from "../../hooks/useForm";
import { Form } from "../../layout/form/form";
import { TextAreaField } from "../../layout/form/text-area-field";

const schema = z.object({
    rejectionReason: z.string(),
});

type Data = z.infer<typeof schema>;

export const MandateEffectRejectionModal = ({
    onClose,
    onReject,
}: {
    onClose: () => void;
    onReject: (data: Data) => void;
}) => {
    const form = useForm({ schema });

    const handleSubmit = (data: Data) => {
        onReject(data);
    };

    return (
        <ModalDialog
            title="Reject Effect"
            show
            close={onClose}
            okAction={form.handleSubmit(handleSubmit)}
            okText="Reject Effect"
        >
            <Form onSubmit={form.handleSubmit(handleSubmit)}>
                <TextAreaField label="Rejection Reason" {...form.getFieldProps("rejectionReason")} />
            </Form>
        </ModalDialog>
    );
};
