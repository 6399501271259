import React from "react";
import ModalDialog from "../../layout/modal-dialog";
import { Mandate } from "../domain/mandate";

export const MandateContentsModal = ({ onClose, mandate }: { onClose: () => void; mandate: Mandate }) => {
    const textWithBreaks = (mandate.orderText || "").split("\n").map((text, index) => (
        <React.Fragment key={index}>
            {text}
            <br />
        </React.Fragment>
    ));

    return (
        <ModalDialog title={`${mandate.state} - ${mandate.name}`} show close={onClose} size="md">
            <div style={{ overflow: "auto", maxHeight: "70vh" }}>{textWithBreaks}</div>
        </ModalDialog>
    );
};
