import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { Company, companySchema } from "../domain/company";

export const assignMandates = async ({ authToken, companyId }: { authToken: string; companyId: number }) => {
    const response = await makeRequest({
        method: "post",
        path: `/companies/${companyId}/assign-mandates`,
        authToken,
    });

    return "ok";
};
