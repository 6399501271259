import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { Mandate, mandateSchema } from "../domain/mandate";
import { getMandate } from "./get-mandate";

export type UpdateMandateData = Partial<Mandate>;

export const updateMandate = async ({
    authToken,
    id,
    data,
}: {
    authToken: string;
    id: number;
    data: UpdateMandateData;
}) => {
    const response = await makeRequest({
        method: "patch",
        path: `/mandates/${id}`,
        authToken,
        data,
    });

    const updatedMandate = parseSchema(mandateSchema, response.data);

    return updatedMandate;
};
