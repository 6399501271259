import { z } from "zod";
import { Form } from "../../layout/form/form";
import ModalDialog from "../../layout/modal-dialog";
import { useForm } from "../../hooks/useForm";
import { SelectField } from "../../layout/form/select-field";
import { useMandates } from "../../mandates/hooks/use-mandates";
import { useAuth } from "../../auth/use-auth";
import { useMemo } from "react";
import { useMandateEffects } from "../../mandate-effects/hooks/use-mandate-effects";
import { useIndustryGroups } from "../../industry-groups/hooks/use-industry-groups";
import { orderBy, startCase } from "lodash";
import { useMutation } from "@tanstack/react-query";
import { MandateMandateEffect } from "../../mandates/domain/mandate-mandate-effect";
import { TextField } from "../../layout/form/text-field";
import { TextAreaField } from "../../layout/form/text-area-field";
import { createMandateMandateEffect } from "../actions/create-mandate-mandate-effect";

const schema = z.object({
    mandateEffectId: z.number(),
    description: z.string().min(1).optional(),
});

type Data = z.infer<typeof schema>;

export const AddMandateEffectModal = ({
    onClose,
    onCreated,
    mandateId,
}: {
    onClose: () => void;
    onCreated?: (mandateMandateEffect: MandateMandateEffect) => void;
    mandateId: number;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const form = useForm({ schema });

    const mandateEffectsQuery = useMandateEffects({ authToken });
    const mandateEffects = useMemo(() => mandateEffectsQuery.data || [], [mandateEffectsQuery.data]);
    const mandateEffectOptions = useMemo(
        () =>
            orderBy(
                mandateEffects.map((m) => ({ label: startCase(m.effectName || "No name"), value: m.id })),
                (op) => op.label,
            ),
        [mandateEffects],
    );

    const createMandateMandateEffectMutation = useMutation({
        mutationFn: (data: Data) => createMandateMandateEffect({ authToken, data: { ...data, mandateId } }),
    });

    const handleSubmit = async (data: Data) => {
        const mandateMandateEffect = await createMandateMandateEffectMutation.mutateAsync(data);
        if (mandateMandateEffect && onCreated) {
            onCreated(mandateMandateEffect);
        }
    };

    return (
        <Form onSubmit={form.handleSubmit(handleSubmit)}>
            <ModalDialog
                title={"Add Mandate Effect"}
                close={onClose}
                show
                okText="Add Mandate Effect"
                okAction={form.handleSubmit(handleSubmit)}
            >
                <SelectField
                    isRequired
                    label="Effect"
                    {...form.getFieldProps("mandateEffectId")}
                    options={mandateEffectOptions}
                />
                <TextAreaField label="Description" {...form.getFieldProps("description")} />
            </ModalDialog>
        </Form>
    );
};
