import APIService from "./api.service";

export type MandateType = {
    id?: number;
    name?: string;
    description?: string;
    legallyBinding: boolean;
};

class MandateTypeService extends APIService<MandateType> {
    constructor() {
        super("/mandate-types");
    }
}

export default new MandateTypeService();
