import APIService from "./api.service";

export type TransactionType = {
    id?: string;
    createdAt: Date;
    updatedAt: Date;
    paymentId: string;
    createdById: string;
    companyId: number;
    toUserId: string;
    fromUserId: string;
    toClearErc: 1 | 0;
    fromClearErc: 1 | 0;
    date: Date;
    amount: number;
    notes: string;
    description: string;
    to: string;
    from: string;
    toCompanyId: number;
    fromCompanyId: number;
    lineItemId: number;
    discountPercentage: number;
};

class TransactionService extends APIService<TransactionType> {
    constructor() {
        super("transactions");
    }
    getMyTransactions() {
        return this.getURL("my-transactions");
    }
    getAll() {
        return this.getURL("transactions-clean");
    }
}

export default new TransactionService();
