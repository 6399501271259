export const formatCurrency = (amount: number, params?: { maximumFractionDigits?: number }) => {
    return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        compactDisplay: "short",
        minimumFractionDigits: 0,
        maximumFractionDigits: params?.maximumFractionDigits || 2,
    });
};

export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}
