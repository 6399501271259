import { useQuery } from "@tanstack/react-query";
import { getCompanyLocations } from "../actions/get-company-locations";

type Params = {
    authToken: string;
    filters?: any;
};

const getCompanyLocationsQueryKey = ({ authToken, filters }: Params) => {
    return ["companyLocations", { authToken, filters }];
};

export const useCompanyLocations = ({ authToken, filters }: Params) => {
    const query = useQuery({
        queryKey: getCompanyLocationsQueryKey({ authToken, filters }),
        queryFn: async () => {
            const companyLocations = await getCompanyLocations({ authToken, filters });
            return companyLocations;
        },
    });
    return query;
};
