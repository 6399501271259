import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { getMandates } from "../actions/get-mandates";
import { Mandate } from "../domain/mandate";
import { useMemo } from "react";
import { uniqBy } from "lodash";

type Params = {
    authToken: string;
    filters?: any;
};

const getMandatesQueryKey = ({ authToken, filters }: Params) => {
    return ["mandates", { authToken, filters }];
};

export const useMandates = ({ authToken, filters }: Params, options?: { enabled?: boolean }) => {
    const query = useQuery({
        queryKey: getMandatesQueryKey({ authToken, filters }),
        queryFn: async () => {
            const response = await getMandates({ authToken, filters });
            return response;
        },
        keepPreviousData: true,
        ...options,
    });
    return query;
};

const getInfiniteMandatesQueryKey = ({ authToken, filters }: Params) => {
    return ["companies", { infinite: true, authToken, filters }];
};

export const useInfiniteMandates = ({ authToken, filters }: Params) => {
    const query = useInfiniteQuery({
        queryKey: getInfiniteMandatesQueryKey({ authToken, filters }),
        getNextPageParam: (lastPage: { hasNextPage: boolean; data: Mandate[]; pageNumber: number }) => {
            return lastPage.hasNextPage ? lastPage.pageNumber + 1 : lastPage.pageNumber;
        },
        keepPreviousData: true,
        queryFn: async ({ pageParam = 0 }) => {
            const limit = 30;
            const offset = pageParam * limit;
            const response = await getMandates({
                authToken,
                filters: { ...filters, limit, offset },
            });
            return { ...response, hasNextPage: true, pageNumber: pageParam };
        },
    });

    const mandatePages = useMemo(() => query.data?.pages || [], [query.data?.pages]);
    const mandates = useMemo(
        () =>
            uniqBy(
                mandatePages.reduce<Mandate[]>((acc, page) => [...acc, ...page.data], []),
                (mandate) => mandate.id,
            ),
        [mandatePages],
    );

    return { ...query, mandates: mandates };
};
