import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { helpDocsTemplateSchema } from "../domain/help-docs-template";

export const getHelpDocsTemplates = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: `/help-docs-templates`,
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });
    const helpDocsTemplates = parseSchema(z.array(helpDocsTemplateSchema), response.data);
    return helpDocsTemplates;
};
