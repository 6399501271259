import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Select } from "./form/select-input";
import mandateIndustriesService from "../services/mandate-industries.service";
import industryService from "../services/industry.service";

export default function IndustryPicker({
    setIndustries,
    industries,
    label = "Industries",
    isMultiple = true,
}: {
    setIndustries: (industries: number[] | null | number) => void;
    industries: number[] | number | null;
    label?: string;
    isMultiple?: boolean;
}) {
    // const [industries, setIndustries] = useState<number[] | null | undefined>(null);
    const industriesQuery = useQuery(["industries"], async () => {
        const result = await industryService.getAll();
        return result?.data ?? [];
    });

    return (
        <Select
            label={label}
            value={industries ?? null}
            isMultiple={isMultiple}
            options={(industriesQuery.data ?? []).map((i) => ({ label: i.name ?? "", value: i.id ?? -1 })) ?? []}
            onChange={function (value?: any): void {
                // console.error({ value });
                // value is an array of ids
                setIndustries(value);
                // setIndustries(industriesQuery.data?.find((i) => i.id === value)?.id ?? null);
            }}
        />
    );
}
