import { CheckIcon } from "@heroicons/react/24/solid";
import { useBreakpoint } from "../../hooks/appMedia";
import BreadCrumbs from "../../layout/breadcrumbs";
import { Entry } from "../processflows/domain/processflow";
import { ProcessFlowProgressData } from "../../typings/api/processflow-progress-data";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export type Step = {
    id: number;
    name: string;
    action: () => void;
    status: "complete" | "current" | "upcoming";
    hideIcon?: boolean;
};

export const Steps = ({
    steps,
    pfIsOptional,
    entries,
    progressData,
    data,
}: {
    steps: Step[];
    pfIsOptional?: number;
    entries?: Entry[];
    progressData?: ProcessFlowProgressData;
    data: Record<string, any>;
}) => {
    const len = steps.length;
    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    const isTablet = breakpoints.breakpoint === "tablet";

    const isEmptyAndNotHiddenEntriesPresent =
        pfIsOptional === 0
            ? (entries || []).map((item: any) => {
                  const id = item?.id;
                  if (
                      [undefined, null, "", " "].includes(data[id]) &&
                      item.required === true &&
                      !progressData?.entriesByField[id]?.isHidden
                  ) {
                      return { id, empty: true };
                  } else {
                      return { id, empty: false };
                  }
              })
            : [];

    const disableStageNavigation = isEmptyAndNotHiddenEntriesPresent.some((v: any) => v.empty === true);

    return isMobile || isTablet ? (
        <BreadCrumbs
            addMb={true}
            pages={steps.map((step) => ({
                name: step.name,
                current: step.status === "current",
                onClick: disableStageNavigation ? undefined : step.action,
            }))}
        />
    ) : (
        <div className=" lg:border-b lg:border-gray-200 dark:lg:border-gray-900 md:rounded-t-xl mb-5 -m-5">
            <nav className="mx-auto " aria-label="Progress">
                <ol
                    role="list"
                    className="overflow-auto md:rounded-t-xl lg:flex  lg:border-gray-200 dark:lg:border-gray-800 "
                >
                    {steps.map((step, stepIdx) => {
                        const stepNumber = stepIdx + 1;
                        return (
                            <li key={step.id} className="relative lg:flex-1 ">
                                <div
                                    className={classNames(
                                        stepIdx === 0 ? "border-b-0 rounded-l-md" : "",
                                        stepIdx === steps.length - 1 ? "border-t-0 rounded-r-md" : "",
                                        "border border-gray-200 h-full  dark:border-gray-800 overflow-hidden lg:border-0 dark:bg-gray-700 ",
                                    )}
                                >
                                    {step.status === "complete" ? (
                                        <button
                                            type="button"
                                            onClick={disableStageNavigation ? undefined : step.action}
                                            className="group w-full mx-auto h-full "
                                        >
                                            <span
                                                className="absolute top-0 left-0 h-full w-1 bg-transparent group-hover:bg-gray-200  dark:group-hover:bg-gray-700   lg:bottom-0 lg:top-auto lg:h-1 mx-auto"
                                                aria-hidden="true"
                                            />
                                            <div
                                                className={classNames(
                                                    stepIdx !== 0 ? "lg:pl-8" : "",
                                                    "px-5 py-5 flex items-center text-sm font-normal w-full flex-row ",
                                                    len === 1 ? " justify-start " : "justify-center",
                                                )}
                                            >
                                                <div className="flex flex-row my-auto ">
                                                    <div
                                                        style={{ width: 40, maxWidth: 40, minWidth: 40 }}
                                                        className="flex w-10 h-10  items-center justify-center rounded-full border-2 bg-indigo-600 dark:bg-indigo-800 dark:border-green-800 "
                                                    >
                                                        <CheckIcon
                                                            className="h-6 w-6 text-white dark:text-gray-300"
                                                            aria-hidden="true"
                                                        />
                                                    </div>
                                                    <div className="ml-2 min-w-0 text-left flex justify-center items-center">
                                                        <h2 className="text-sm font-normal tracking-widest dark:text-gray-400 ">
                                                            {step.name}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    ) : step.status === "current" ? (
                                        <button
                                            type="button"
                                            onClick={disableStageNavigation ? undefined : step.action}
                                            aria-current="step"
                                            className={classNames(
                                                step.hideIcon ? "border-b-2 " : "",
                                                "group w-full h-full mx-auto",
                                            )}
                                        >
                                            <span
                                                className="absolute top-0 left-0 h-full w-1 bg-indigo-600 dark:bg-green-500 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                                                aria-hidden="true"
                                            />
                                            <div
                                                className={classNames(
                                                    stepIdx !== 0 ? "lg:pl-8" : "",
                                                    "px-5 py-5 flex text-sm font-normal w-full flex-row my-auto ",
                                                    len === 1 ? " justify-start " : "justify-center",
                                                )}
                                            >
                                                <div className="flex flex-row">
                                                    {!step.hideIcon && (
                                                        <div
                                                            style={{ width: 40, maxWidth: 40, minWidth: 40 }}
                                                            className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-indigo-600 dark:border-indigo-500"
                                                        >
                                                            <div className="text-indigo-600 dark:text-indigo-200">
                                                                {stepNumber}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="ml-2 min-w-0 text-left flex justify-center items-center">
                                                        <h2 className="tracking-widest text-sm font-normal text-indigo-600 dark:text-green-400">
                                                            {step.name}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            onClick={disableStageNavigation ? undefined : step.action}
                                            className="group w-full mx-auto h-full "
                                        >
                                            <span
                                                className="absolute top-0 left-0 h-full w-1 bg-transparent group-hover:bg-gray-200  dark:group-hover:bg-gray-700 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                                                aria-hidden="true"
                                            />
                                            <div
                                                className={classNames(
                                                    stepIdx !== 0 ? "lg:pl-8" : "",
                                                    "px-5 py-5 flex items-center text-sm font-normal w-full flex-row",
                                                    len === 1 ? " justify-start " : "justify-center",
                                                )}
                                            >
                                                <div className="flex flex-row">
                                                    <div
                                                        style={{ width: 40, maxWidth: 40, minWidth: 40 }}
                                                        className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300 dark:border-gray-600"
                                                    >
                                                        <span className="text-gray-400">{stepNumber}</span>
                                                    </div>
                                                    <div className="ml-2 min-w-0 text-left flex justify-center items-center">
                                                        <h2 className="tracking-widest text-sm font-normal text-gray-400 mx-auto text-left">
                                                            {step.name}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    )}
                                    {stepIdx !== 0 ? (
                                        <>
                                            <div
                                                className="absolute inset-0 top-0 left-0 hidden w-3 lg:block text-center"
                                                aria-hidden="true"
                                            >
                                                <svg
                                                    className="h-full w-full text-gray-300"
                                                    viewBox="0 0 12 82"
                                                    fill="none"
                                                    preserveAspectRatio="none"
                                                >
                                                    <path
                                                        d="M0.5 0V31L10.5 41L0.5 51V82"
                                                        stroke="currentcolor"
                                                        vectorEffect="non-scaling-stroke"
                                                    />
                                                </svg>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </li>
                        );
                    })}
                </ol>
            </nav>
        </div>
    );
};
