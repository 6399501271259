import { z } from "zod";
import ModalDialog from "../../layout/modal-dialog";
import { useForm } from "../../hooks/useForm";
import { Form } from "../../layout/form/form";
import { SelectField } from "../../layout/form/select-field";
import { Mandate } from "../domain/mandate";
import { orderBy } from "lodash";
import { cmaStatusEnum } from "../../company-mandate-assignments/domain/company-mandate-assignment";
import { MandateEffect } from "../../mandate-effects/domain/mandate-effect";

const schema = z.object({
    mandateIds: z.array(z.number()).nullish(),
    statuses: z.array(cmaStatusEnum).nullish(),
    effectIds: z.array(z.number()).nullish(),
});

export type MandateFilters = z.infer<typeof schema>;

export const MandateFiltersModal = ({
    onClose,
    onSave,
    mandates,
    mandateEffects,
    mandateFilters,
}: {
    onClose: () => void;
    onSave: (filters: MandateFilters) => void;
    mandates: Mandate[];
    mandateEffects: MandateEffect[];
    mandateFilters?: MandateFilters;
}) => {
    const form = useForm({ schema, defaultValues: mandateFilters });

    const handleSubmit = form.handleSubmit((data: MandateFilters) => {
        onSave(data);
    });

    const mandateOptions = orderBy(
        mandates.map((m) => ({ value: m.id, label: `${m.state} - ${m.name || ""}` })),
        (o) => o.label,
    );

    const effectOptions = orderBy(
        mandateEffects.map((m) => ({ value: m.id, label: `${m.effectName}` })),
        (o) => o.label,
    );

    const statusOptions = [
        { label: "Accepted", value: "accepted" },
        { label: "Rejected", value: "rejected" },
        { label: "Suggested", value: "suggested" },
    ];

    return (
        <Form onSubmit={handleSubmit}>
            <ModalDialog
                size="sm"
                title="Mandate Filters"
                show
                close={onClose}
                okAction={handleSubmit}
                okText="Save Filters"
            >
                <SelectField
                    label="Mandates"
                    {...form.getFieldProps("mandateIds")}
                    options={mandateOptions}
                    isMultiple
                />
                <SelectField label="Effects" {...form.getFieldProps("effectIds")} options={effectOptions} isMultiple />
                <SelectField label="Statuses" {...form.getFieldProps("statuses")} options={statusOptions} isMultiple />
            </ModalDialog>
        </Form>
    );
};
