import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { useAuth } from "../../../auth/use-auth";
import { TextField } from "../../../layout/form/text-field";
import { useQuery } from "@tanstack/react-query";
import helpDocsService from "../../../services/help-docs.service";
import CheckBoxNeoGenControlled from "../../../layout/checkbox-controlled";
import { useState, useMemo, useEffect } from "react";
import { useCreateHelpDocsTemplateMutation } from "../../../help-docs/hooks/use-create-help-docs-template";
import { useDrag, useDrop } from "react-dnd";

const schema = z.object({
    name: z.string(),
});

type Data = z.infer<typeof schema>;

const ITEM_TYPE = "HELP_DOC";

const DraggableCheckBox = ({
    id,
    title,
    isChecked,
    onToggle,
    onDrag,
}: {
    id: number;
    title: string;
    isChecked: boolean;
    onToggle: (id: number) => void;
    onDrag: (draggedId: number, targetId: number) => void;
}) => {
    // @ts-ignore
    const [{ isDragging }, drag] = useDrag({
        type: ITEM_TYPE,
        item: { id, title },
    });

    const [, drop] = useDrop({
        accept: ITEM_TYPE,
        hover: (draggedItem: { id: number }) => {
            if (draggedItem.id !== id) {
                onDrag(draggedItem.id, id);
            }
        },
    });

    return (
        <div ref={(node) => drag(drop(node))} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <CheckBoxNeoGenControlled label={title} setValue={() => onToggle(id)} value={isChecked} name="" />
        </div>
    );
};

export const CreatePackageTemplateModal = ({ onClose }: { onClose: () => void }) => {
    const auth = useAuth();
    const authToken = getAuthTokenNoThrow() || "no-auth-token";

    const helpDocsQuery = useQuery(["HelpDocs"], async () => {
        const response = await helpDocsService.getAll();
        if (response) {
            return response.data;
        }
    });
    const helpDocs = useMemo(() => helpDocsQuery.data || [], [helpDocsQuery.data]);

    const [enabledDocs, setEnabledDocs] = useState<
        {
            id: number;
            isChecked: boolean;
        }[]
    >([]);

    useEffect(() => {
        if (helpDocs?.length > 0) {
            setEnabledDocs(helpDocs.map((doc) => ({ id: doc.id as number, isChecked: true })));
        }
    }, [helpDocs]);

    const form = useForm({
        schema,
    });

    const createHelpDocsTemplateMutation = useCreateHelpDocsTemplateMutation();

    const handleSubmit = async (data: Data) => {
        const template = await createHelpDocsTemplateMutation.mutateAsync({
            authToken,
            data: {
                name: data.name,
                templateItems: enabledDocs
                    .filter((d) => d.isChecked)
                    .map((item, index) => ({
                        helpDocId: item.id,
                        order: index,
                    })),
            },
        });
        if (template) {
            onClose();
        }
    };

    const onToggle = (id: number) => {
        const updatedEnabledDocs = [...enabledDocs];
        const docIndex = updatedEnabledDocs.findIndex((doc) => doc.id === id);
        updatedEnabledDocs[docIndex].isChecked = !updatedEnabledDocs[docIndex].isChecked;
        setEnabledDocs(updatedEnabledDocs);
    };

    const onDrag = (draggedId: number, targetId: number) => {
        const updatedEnabledDocs = [...enabledDocs];
        const draggedIndex = updatedEnabledDocs.findIndex((doc) => doc.id === draggedId);
        const targetIndex = updatedEnabledDocs.findIndex((doc) => doc.id === targetId);
        [updatedEnabledDocs[draggedIndex], updatedEnabledDocs[targetIndex]] = [
            updatedEnabledDocs[targetIndex],
            updatedEnabledDocs[draggedIndex],
        ];
        setEnabledDocs(updatedEnabledDocs);
    };

    return (
        <ModalDialog show title={"Create package template"} close={onClose} showOk={false} showCancel={false} size="sm">
            <p className="text-center mb-6 text-gray-400">Select which help docs to include and their order.</p>
            <TextField label="Template name" {...form.getFieldProps("name")} />
            <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={createHelpDocsTemplateMutation.error as any}>
                {enabledDocs?.map((helpDoc) => (
                    <div key={helpDoc.id}>
                        <DraggableCheckBox
                            id={helpDoc.id}
                            title={helpDocs.find((item) => item.id === helpDoc.id)?.title || ""}
                            isChecked={helpDoc.isChecked}
                            onToggle={onToggle}
                            onDrag={onDrag}
                        />
                    </div>
                ))}
                <div className="flex justify-end gap-4">
                    <ButtonNeoGen
                        type="outline"
                        disabled={createHelpDocsTemplateMutation.isLoading}
                        onClick={() => onClose()}
                    >
                        Cancel
                    </ButtonNeoGen>
                    <ButtonNeoGen type="submit" disabled={createHelpDocsTemplateMutation.isLoading}>
                        Create template
                    </ButtonNeoGen>
                </div>
            </Form>
        </ModalDialog>
    );
};
