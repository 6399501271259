type HelpDocsTemplateItem = {
    id?: number;
    helpDocId: number;
    templateId: number;
    order: number;
};

import APIService from "./api.service";

class HelpDocsTemplateItemService extends APIService<HelpDocsTemplateItem> {
    constructor() {
        super("help-docs-template-items");
    }
}

export default new HelpDocsTemplateItemService();
