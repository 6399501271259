import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ModalDialog from "../../../../layout/modal-dialog";
import InputControlled from "../../../../layout/input-controlled";
import TextAreaNeoGenControlled from "../../../../layout/text-area-controlled";
import mandateEffectService, { MandateEffect } from "../../../../services/mandate-effect.service";
import mandateEffectIndustryService, {
    MandateEffectIndustry,
} from "../../../../services/mandate-effect-industry.service";
import Toggle from "../../../../layout/toggle";
import { MenuList } from "../../../../layout/select-virtualized";
import Select from "react-select";
import industryService from "../../../../services/industry.service";
import industryGroupsService from "../../../../services/industry-groups.service";
import { useRecoilState } from "recoil";
import userAtom from "../../../../atoms/userAtom";
import IndustryPicker from "../../../../layout/IndustryPicker";
import IndustryGroupPicker from "../../../../layout/industry-group-picker";
import mandateEffectIndustryGroupService, {
    MandateEffectIndustryGroup,
} from "../../../../services/mandate-effect-industry-group.service";
// export type MandateEffectIndustry = {
//     id?: number;
//     effect: number;
//     industry: number;
//     causes_rev_reduction: number;
//     effect_description: string;
//     isApproved: number;
//     isPublic: number;
//     effect_title: string;
//     createdBy: string;
// };

export default function AddImpact(props: AddImpactProps) {
    const [name, setName] = useState("");
    const [user] = useRecoilState(userAtom);
    const userId = user.id;
    const [description, setDescription] = useState("");
    const [id, setId] = useState<number | undefined>();
    // const [effect, setEffect] = useState<number>(0);
    // const [industry, setIndustry] = useState<number>(0);
    const [causesRevReduction, setCausesRevReduction] = useState<number>(1);
    const [effectDescription, setEffectDescription] = useState<string>("");
    const [effectedHoursOfOperation, setEffectedHoursOfOperation] = useState<number>(0);
    const [potentialRevenueLost, setPotentialRevenueLost] = useState<number>(0);
    const [isApproved, setIsApproved] = useState<number>(1);
    const [isPublic, setIsPublic] = useState<number>(1);
    const [effectTitle, setEffectTitle] = useState<string>("");
    const [createdBy, setCreatedBy] = useState<string>("");
    const queryCache = useQueryClient();
    const [industry, setIndustry] = useState<number>(0);

    const industriesQuery = useQuery(["industries"], async () => {
        const result = await industryService.getAll();
        return result?.data ?? [];
    });

    const industryGroupsQuery = useQuery(["industryGroups"], async () => {
        const result = await industryGroupsService.getAll();
        return result?.data ?? [];
    });

    useEffect(() => {
        setTimeout(() => {
            // If we are passed in an impact set all the fields
            console.log({ props });
            if (props.impact?.id) {
                setId(props.impact.id);
                setIndustry(props.impact.industry ?? props.impact.industryGroup ?? 0);
                setCausesRevReduction(props.impact.causesRevReduction);
                setEffectDescription(props.impact.effectDescription);
                setIsApproved(props.impact.isApproved);
                setIsPublic(props.impact.isPublic);
                setEffectTitle(props.impact.effectTitle);
                setCreatedBy(props.impact.createdBy);
                setPotentialRevenueLost(props.impact.potentialRevenueLost ?? 0);
                setEffectedHoursOfOperation(props.impact.effectedHoursOfOperation ?? 0);
            } else {
                // Otherwise clear all the fields
                setId(undefined);
                setIndustry(0);
                setCausesRevReduction(1);
                setEffectDescription("");
                setIsApproved(1);
                setIsPublic(1);
                setEffectTitle("");
                setCreatedBy("");
                setPotentialRevenueLost(0);
                setEffectedHoursOfOperation(0);
            }
        }, 1);
    }, [props]);

    async function saveMandateEffect() {
        if (props.impact?.id) {
            // edit
            if (props.type === "industry") {
                const mandateEffectIndustry: MandateEffectIndustry = {
                    effect: props.effect.id ?? -1,
                    industry: industry,
                    causesRevReduction: causesRevReduction,
                    effectDescription: effectDescription,
                    isApproved,
                    isPublic,
                    effectTitle: effectTitle,
                    createdBy: userId ?? "",
                    potentialRevenueLost,
                    effectedHoursOfOperation,
                };
                console.error({ industry, mandateEffectIndustry });

                await mandateEffectIndustryService.update(id, mandateEffectIndustry);
            } else {
                const mandateEffectIndustryGroup: MandateEffectIndustryGroup = {
                    effect: props.effect.id ?? -1,
                    industryGroup: industry,
                    causesRevReduction: causesRevReduction,
                    effectDescription: effectDescription,
                    isApproved,
                    isPublic,
                    effectTitle: effectTitle,
                    createdBy: userId ?? "",
                    potentialRevenueLost,
                    effectedHoursOfOperation,
                };

                await mandateEffectIndustryGroupService.update(id, mandateEffectIndustryGroup);
            }
        } else {
            // add
            if (props.type === "industry") {
                const mandateEffectIndustry: MandateEffectIndustry = {
                    effect: props.effect.id ?? -1,
                    industry: industry,
                    causesRevReduction: causesRevReduction,
                    effectDescription: effectDescription,
                    isApproved,
                    isPublic,
                    effectTitle: effectTitle,
                    createdBy: userId ?? "",
                    potentialRevenueLost,
                    effectedHoursOfOperation,
                };
                await mandateEffectIndustryService.create(mandateEffectIndustry);
            } else {
                const mandateEffectIndustryGroup: MandateEffectIndustryGroup = {
                    effect: props.effect.id ?? -1,
                    industryGroup: industry,
                    causesRevReduction: causesRevReduction,
                    effectDescription: effectDescription,
                    isApproved,
                    isPublic,
                    effectTitle: effectTitle,
                    createdBy: userId ?? "",
                    potentialRevenueLost,
                    effectedHoursOfOperation,
                };
                await mandateEffectIndustryGroupService.create(mandateEffectIndustryGroup);
            }
        }
        queryCache.invalidateQueries(["mandateEffectIndustry"]);
        queryCache.invalidateQueries(["mandateEffectIndustryGroup"]);

        props.close();
    }
    // if ()

    return (
        <ModalDialog
            close={props.close}
            show={props.show}
            size="md"
            title={
                (props.impact ? "Edit Impact For " : "Add Impact For ") +
                props.effect.effectName +
                " (" +
                props.type +
                ")"
            }
            okAction={() => {
                saveMandateEffect();
            }}
            okText={props.impact ? "Save Impact" : "Add Impact"}
        >
            <div className="w-full bg-white pb-5 rounded-lg dark:bg-slate-700">
                {/* Industry */}
                {/* {industry} */}
                {props.type === "industry" ? (
                    <IndustryPicker
                        isMultiple={false}
                        setIndustries={(industries) => {
                            setIndustry(Number(industries) ?? 0);
                        }}
                        industries={industry}
                        label="Industry"
                    />
                ) : (
                    <IndustryGroupPicker
                        isMultiple={false}
                        setIndustries={(industries) => {
                            setIndustry(Number(industries) ?? 0);
                        }}
                        industries={industry}
                        label="Industry Group"
                    />
                )}
            </div>
            <InputControlled
                label="Impact Title"
                value={effectTitle}
                onChange={(value) => {
                    setEffectTitle(value);
                }}
            />
            <TextAreaNeoGenControlled
                label="Impact Description"
                value={effectDescription}
                setValue={(value) => {
                    setEffectDescription(value);
                }}
            />

            {/* <InputControlled
                label="Created By"
                value={createdBy}
                onChange={(value) => {
                    setCreatedBy(value);
                }}
            /> */}

            {/* <Toggle
                label="Effect"
                checked={effect !== 0}
                onChange={(checked) => {
                    setEffect(checked ? 1 : 0);
                }}
            />
            <Toggle
                label="Industry"
                checked={industry !== 0}
                onChange={(checked) => {
                    setIndustry(checked ? 1 : 0);
                }}
            /> */}
            <div className="mb-5">
                <Toggle
                    options={["Does Not Cause Revenue Reduction", "Causes Revenue Reduction"]}
                    selectedIndex={causesRevReduction}
                    setSelectedIndex={(checked) => {
                        setCausesRevReduction(checked ? 1 : 0);
                    }}
                />
            </div>
            <div className="mb-5">
                <Toggle
                    options={["Is Not Approved", "Is Approved"]}
                    selectedIndex={isApproved}
                    setSelectedIndex={(checked) => {
                        setIsApproved(checked ? 1 : 0);
                    }}
                />
            </div>
            <div className="mb-5">
                <Toggle
                    options={["Is Not Public", "Is Public"]}
                    selectedIndex={isPublic}
                    setSelectedIndex={(checked) => {
                        setIsPublic(checked ? 1 : 0);
                    }}
                />
            </div>
            <div className="mb-5">
                <Toggle
                    options={["Does Not Reduce Potential Revenue", "Reduces Potential Revenue"]}
                    selectedIndex={potentialRevenueLost}
                    setSelectedIndex={(checked) => {
                        setPotentialRevenueLost(checked ? 1 : 0);
                    }}
                />
            </div>
            <div className="mb-5">
                <Toggle
                    options={["Does Not Effect Hours Of Operation", "Effects Hours Of Operation"]}
                    selectedIndex={effectedHoursOfOperation}
                    setSelectedIndex={(checked) => {
                        setEffectedHoursOfOperation(checked ? 1 : 0);
                    }}
                />
            </div>
        </ModalDialog>
    );
}

type AddImpactProps = {
    show: boolean;
    close: () => void;
    impact?: MandateEffectIndustry | MandateEffectIndustryGroup | null;
    effect: MandateEffect;
    type: "industry" | "industryGroup";
};
