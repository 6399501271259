import APIService from "./api.service";

export type PackageSectionRule = {
    id?: number;
    isEnabler: number;
    helpDoc: number;
    fieldName: string;
    fieldValue: string;
    title: string;
};

class PackageSectionRulesService extends APIService<PackageSectionRule> {
    constructor() {
        super("/package-section-rules");
    }
}

export default new PackageSectionRulesService();
