import APIService from "./api.service";

export type MandateEffectIndustryGroup = {
    id?: number;
    effect: number;
    industryGroup: number;
    causesRevReduction: number;
    effectDescription: string;
    isApproved: number;
    isPublic: number;
    effectTitle: string;
    createdBy: string;
    industry?: number;
    effectedHoursOfOperation?: number;
    potentialRevenueLost?: number;
};

class MandateEffectIndustryGroupService extends APIService<MandateEffectIndustryGroup> {
    constructor() {
        super("/mandate-effect-industry-groups");
    }
}

export default new MandateEffectIndustryGroupService();
