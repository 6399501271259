import { useMutation } from "@tanstack/react-query";
import { deleteHelpDocTemplate } from "../actions/delete-help-docs-template";

export const useDeleteHelpDocsTemplateMutation = () => {
    const mutation = useMutation({
        mutationFn: async ({ authToken, id }: { authToken: string; id: number }) => {
            const helpDocsTemplate = await deleteHelpDocTemplate({ authToken, id });
            return helpDocsTemplate;
        },
    });
    return mutation;
};
