import React from "react";
import PageDescription from "../../../layout/page-description";
import { Table } from "../../../layout/table";
import TableNeogen from "../../../layout/table-neogen";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import industryService from "../../../services/industry.service";
import industryGroupsService, { IndustryGroup } from "../../../services/industry-groups.service";
import industryGroupMembersService from "../../../services/industry-group-members.service";
import Loader3 from "../../utilities/Loader3";
import AddMandateType from "./modals/add-mandate-type";
import SwalNeogenFire from "../../../layout/swal-neogen";
import mandateTypeService, { MandateType } from "../../../services/mandate-type.service";
import { Mandate } from "../../../services/mandate.service";
import { useParams } from "react-router-dom";
import mandateEffectService from "../../../services/mandate-effect.service";
import mandateEffectIndustryService, { MandateEffectIndustry } from "../../../services/mandate-effect-industry.service";
import PrintPre from "../../../layout/print-pre";
import { MenuList } from "../../../layout/select-virtualized";
import Select from "react-select";
import AddImpact from "./modals/add-impact";
import { set } from "lodash";
import mandateEffectIndustryGroupService, {
    MandateEffectIndustryGroup,
} from "../../../services/mandate-effect-industry-group.service";
export default function Impacts({ type }: { type: "industry" | "industryGroup" }) {
    const { id } = useParams();
    const [showAddImpact, setShowAddImpact] = React.useState(false);
    const [mandate, setMandate] = React.useState<Mandate | null>(null);
    const [filters, setFilters] = React.useState<any[]>([]);
    const [impact, setImpact] = React.useState<MandateEffectIndustry | MandateEffectIndustryGroup | null>(null);

    const options = [];
    for (let i = 0; i < 10000; i = i + 1) {
        options.push({ value: i, label: `Option ${i}` });
    }

    const queryCache = useQueryClient();
    const mandateEffectsQuery = useQuery(["mandateEffects", id], async () => {
        const result = await mandateEffectService.getOne(id ?? "");
        return result?.data;
    });

    const industriesQuery = useQuery(["industries"], async () => {
        const result = await industryService.getAll();
        return result?.data ?? [];
    });

    const industryGroupsQuery = useQuery(["industryGroups"], async () => {
        const result = await industryGroupsService.getAll();
        return result?.data ?? [];
    });

    const impactsQuery = useQuery(
        [type === "industry" ? "mandateEffectIndustry" : "mandateEffectIndustryGroup", id],
        async () => {
            const result = await (type === "industry"
                ? mandateEffectIndustryService.getFilteredWhere({ effect: id ?? "" })
                : mandateEffectIndustryGroupService.getFilteredWhere({ effect: id ?? "" }));
            return result?.data ?? [];
        },
    );

    if (mandateEffectsQuery.isLoading || industriesQuery.isLoading || impactsQuery.isLoading) {
        return <Loader3 />;
    }

    return (
        <>
            <PageDescription
                title={
                    (type === "industry" ? "Industry Impacts" : "Industry Group Impacts") +
                    " from " +
                    (mandateEffectsQuery.data?.effectName ?? "")
                }
                description={"Manage Mandate Types " + id}
                buttons={[
                    {
                        label: "Go Back",
                        onClick: () => {
                            window.location.href = "/admin/mandate-effects";
                        },
                        type: "secondary",
                        icon: "fas fa-angle-left",
                    },
                    {
                        label: "Add Impact",
                        onClick: () => {
                            setImpact(null);
                            setShowAddImpact(true);
                        },
                        icon: "fas fa-plus",
                    },
                ]}
                // filters={[
                //     {
                //         label: "Filter by Industry Group",
                //         value: "",
                //         options:
                //             industriesQuery.data?.map((i) => {
                //                 return { id: (i.id ?? "").toString(), name: i.name ?? "" };
                //             }) ?? [],
                //         onChange: (idx) => {
                //             console.log({ idx });
                //         },
                //     },
                // ]}
            ></PageDescription>
            <div className="w-full bg-white p-5 rounded-lg dark:bg-slate-700">
                Filter
                <Select
                    components={{ MenuList }}
                    isMulti
                    defaultValue={filters}
                    className=" dark:bg-slate-900  dark:border-slate-900"
                    classNames={{
                        clearIndicator: () => "",
                        control: () => "dark:bg-slate-700 dark:border-slate-900",
                        // control: () => "bg-red-300",
                        // dropdownIndicator: () => "bg-red-300",
                        // group: () => "bg-red-300",
                        // groupHeading: () => "bg-red-300",
                        indicatorsContainer: () => "dark:bg-slate-800",
                        indicatorSeparator: () => "dark:bg-slate-600",
                        input: () => "dark:bg-slate-700 border-slate-700",
                        // loadingIndicator: () => "bg-red-300",
                        // loadingMessage: () => "bg-red-300",
                        // menu: () => "bg-red-300",
                        // menuList: () => "bg-red-300",
                        // menuPortal: () => "bg-red-300",
                        // multiValue: () => "bg-red-300",
                        multiValueLabel: () => "dark:bg-slate-500",
                        multiValueRemove: () => "dark:bg-slate-900",
                        noOptionsMessage: () => "dark:bg-slate-300",
                        // option: () => "dark:bg-slate-300",
                        // placeholder: () => "text-red-300",
                        // singleValue: () => "bg-red-300",
                        valueContainer: () => "dark:bg-slate-800",
                    }}
                    onChange={(e) => {
                        setFilters(Array.isArray(e) ? e : []);
                    }}
                    options={
                        type === "industry"
                            ? industriesQuery.data
                                  ?.map((i) => {
                                      return { value: i.id, label: i.name };
                                  })
                                  .sort((a, b) => (a.label > b.label ? 1 : -1))
                            : industryGroupsQuery.data
                                  ?.map((i) => {
                                      return { value: i.id, label: i.name };
                                  })
                                  .sort((a, b) => (a.label > b.label ? 1 : -1))
                    }
                />
            </div>
            {impactsQuery.isLoading ? (
                <Loader3 />
            ) : (
                <div className="mt-5">
                    {/* <PrintPre>{filters}</PrintPre>
                    {filters.map((f) => {
                        return (
                            <div key={f.value}>
                                {f.value} {f.label}
                                <PrintPre>{industriesQuery.data?.[f.value - 1]}</PrintPre>
                            </div>
                        );
                    })} */}
                    <TableNeogen
                        formatters={[
                            {
                                field: "isPublic",
                                type: "Boolean",
                            },
                            {
                                field: "isApproved",
                                type: "Boolean",
                            },
                        ]}
                        entries={(
                            impactsQuery.data?.map((i) => {
                                if (type === "industry") {
                                    return {
                                        id: i.id,
                                        title: i.effectTitle,
                                        description: i.effectDescription,
                                        industry: industriesQuery.data?.find((x) => x.id === i.industry)?.name,
                                        industryId: i.industry,
                                        isPublic: i.isPublic,
                                        isApproved: i.isApproved,
                                        //         : industryGroupsQuery.data?.find((x) => x.id === i.industryGroup)?.name,
                                    };
                                } else {
                                    return {
                                        id: i.id,
                                        title: i.effectTitle,
                                        description: i.effectDescription,
                                        industryGroup: industryGroupsQuery.data?.find((x) => x.id === i.industryGroup)
                                            ?.name,
                                        industryGroupId: i.industryGroup,
                                        isPublic: i.isPublic,
                                        isApproved: i.isApproved,
                                    };
                                }
                            }) ?? []
                        ).filter((i) => {
                            if (filters.length === 0) {
                                return true;
                            }
                            if (type === "industry") {
                                return filters.some((f) => f.value === i.industryId);
                            } else {
                                return filters.some((f) => f.value === i.industryGroupId);
                            }
                        })}
                        ignoreFields={["industryId", "industryGroupId"]}
                        actions={[
                            {
                                label: "Edit",
                                onClick: (entry) => {
                                    setImpact(
                                        (impactsQuery.data as any)?.find(
                                            (i: MandateEffectIndustry | MandateEffectIndustryGroup) => i.id === entry,
                                        ) ?? null,
                                    );
                                    setShowAddImpact(true);
                                },

                                icon: "fas fa-edit",
                            },
                            {
                                label: "Delete",
                                type: "danger",

                                onClick: (entry) => {
                                    SwalNeogenFire({
                                        title: "Are you sure?",
                                        text: "You won't be able to revert this!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonText: "Yes, delete it!",
                                        cancelButtonText: "No, keep it",
                                        confirmButtonColor: "#d33",
                                        reverseButtons: true,
                                    }).then(async (result) => {
                                        if (result.isConfirmed) {
                                            if (type === "industry") {
                                                await mandateEffectIndustryService.deleteByID(entry);
                                            } else {
                                                await mandateEffectIndustryGroupService.deleteByID(entry);
                                            }
                                            queryCache.invalidateQueries(["mandateEffectIndustry"]);
                                            queryCache.invalidateQueries(["mandateEffectIndustryGroup"]);
                                            impactsQuery.refetch();
                                            SwalNeogenFire({
                                                title: "Deleted!",
                                                text: "Your impact has been deleted.",
                                                icon: "success",
                                                showConfirmButton: false,
                                                timer: 1500,
                                            });
                                        }
                                    });
                                },
                                icon: "fas fa-trash",
                            },
                        ]}
                    />
                </div>
            )}
            {showAddImpact && (
                <AddImpact
                    effect={mandateEffectsQuery.data ?? {}}
                    show={showAddImpact}
                    close={() => setShowAddImpact(false)}
                    impact={impact}
                    type={type}
                    // mandateType={mandateType}
                />
            )}
        </>
    );
}
