import { makeRequest, parseSchema } from "../../shared/utils";
import { userSchema } from "../domain/user";
import { userResponseSchema } from "./get-users";

export const triggerRegistration = async ({
    userId,
    email,
    name,
    firstName,
    lastName,
    phoneNumber,
    utm,
    affiliateUserId,
    defaultProcessflowId,
    site,
    isUnderAudit,
    totalRefund,
}: {
    userId: string;
    email: string;
    name: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    utm: string;
    affiliateUserId: string;
    defaultProcessflowId: number;
    site: "clear-erc" | "erc-repair";
    isUnderAudit: string;
    totalRefund: string;
}) => {
    const response = await makeRequest({
        method: "post",
        path: "/trigger-registration",
        data: {
            userId,
            email,
            name,
            firstName,
            lastName,
            phoneNumber,
            utm,
            affiliateUserId,
            defaultProcessflowId,
            site,
            isUnderAudit,
            totalRefund,
        },
    });
    const userDto = parseSchema(userResponseSchema, response.data);
    const user = parseSchema(userSchema, userDto);
    return user;
};
