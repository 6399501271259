import { makeRequest } from "../../jason-proof-of-concept/shared/utils";
import { CompanyMandateAssignment, companyMandateAssignmentSchema } from "../domain/company-mandate-assignment";

export type CreateCompanyMandateAssignment = Partial<CompanyMandateAssignment>;

export const createCompanyMandateAssignment = async ({
    data,
    authToken,
}: {
    data: CreateCompanyMandateAssignment;
    authToken: string;
}) => {
    const response = await makeRequest({
        method: "post",
        path: `/company-mandate-assignments`,
        data,
        authToken,
    });
    const companyMandateAssignment = companyMandateAssignmentSchema.parse(response.data);
    return companyMandateAssignment;
};
