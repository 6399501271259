import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { industryGroupSchema } from "../domain/industry-group";

export const getIndustryGroups = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/industry-groups",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const industryGroups = parseSchema(z.array(industryGroupSchema), response.data || []);

    return industryGroups;
};
