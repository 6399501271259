import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { userSchema } from "../../jason-proof-of-concept/users/domain/user";
import { magicLinkSchema } from "../domain/magic-link";

export const actionMagicLink = async ({
    authToken,
    code,
    data,
}: {
    authToken: string;
    code: string;
    data: Record<string, any>;
}) => {
    const response = await makeRequest({
        method: "post",
        path: `/magic-links/${code}/${window.location.hostname}`,
        authToken,
        data,
    });
    const magicLink = parseSchema(magicLinkSchema, response.data.magicLink);
    const user = response.data.user ? parseSchema(userSchema, response.data.user) : undefined;
    return { magicLink, user };
};
