import React, { useEffect, useState } from "react";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import ModalDialog from "../../layout/modal-dialog";
import { loadStripe } from "@stripe/stripe-js";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");
export default function CheckoutFormContents({ clientSecret }: { clientSecret: string }): JSX.Element {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState(null);
    const [returnUrl, setReturnUrl] = useState("");

    useEffect(() => {
        switch (window.location.hostname) {
            case "localhost":
                setReturnUrl("http://localhost:3000/paymentComplete");
                break;
            case "login.clearerc.com":
                setReturnUrl("https://login.clearerc.com/paymentComplete");
                break;
            case "login.ercrepair.com":
                setReturnUrl("https://login.ercrepair.com/paymentComplete");
                break;
            default:
                setReturnUrl("https://login.ercrepair.com/paymentComplete");
        }
    }, []);

    const handleSubmit = async (event: { preventDefault: () => void }) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: returnUrl,
            },
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage((error as any).message ?? "");
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    const options = {
        // passing the client secret obtained in step 3
        clientSecret: clientSecret,
        // Fully customizable with appearance API.
        appearance: {
            /*...*/
        },
    };
    return (
        // <Elements stripe={stripePromise} options={options}>
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <button disabled={!stripe}>Submit</button>
            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
        </form>
        // </Elements>
    );
}
