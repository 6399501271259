import { useInfiniteQuery } from "@tanstack/react-query";
import { useAuth } from "../../auth/use-auth";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { debounce, groupBy, orderBy, startCase, uniq } from "lodash";
import ButtonNeoGen from "../../layout/button-neogen";
import { useIndustryGroups } from "../../industry-groups/hooks/use-industry-groups";
import { useMandateEffects } from "../../mandate-effects/hooks/use-mandate-effects";
import { MandateEffectModal } from "../../mandate-effects/components/mandate-effect-modal";
import { ViewportList } from "react-viewport-list";
import React from "react";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import SelectNeoGen from "../../layout/select-neogen";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { ResponsiveEllipsis } from "../../layout/text";
import { Mandate } from "../domain/mandate";
import { MandateMandateEffect } from "../../mandate-mandate-effects/domain/mandate-mandate-effect";
import { IndustryGroup } from "../../industry-groups/domain/industry-group";
import { CompanyMandateAssignment } from "../../company-mandate-assignments/domain/company-mandate-assignment";
import { MandateEffect } from "../../mandate-effects/domain/mandate-effect";
import { Form } from "../../layout/form/form";
import { SelectField } from "../../layout/form/select-field";
import { useForm } from "../../hooks/useForm";
import { z } from "zod";
import { getVirtualMandateAssignments } from "../../company-mandate-assignments/actions/get-virtual-mandate-assignments";
import { getMandates } from "../actions/get-mandates";
import { getMandateEffects } from "../../mandate-effects/actions/get-mandate-effects";
import { getMandateMandateEffects } from "../../mandate-mandate-effects/actions/get-mandate-mandate-effects";
import { getIndustryGroups } from "../../industry-groups/actions/get-industry-groups";
import { states } from "../../states";
import qs from "qs";
const { cities } = await import("../../cities");
const { counties } = await import("../../counties");

const schema = z.object({
    states: z.array(z.string()).nullish(),
    countyIds: z.array(z.coerce.number()).nullish(),
    cityIds: z.array(z.coerce.number()).nullish(),
    effectIds: z.array(z.coerce.number()).nullish(),
    industryGroupIds: z.array(z.coerce.number()).nullish(),
    quarters: z.array(z.coerce.number()).nullish(),
    authorities: z.array(z.enum(["State", "County", "City"])).nullish(),
});

export type MandateFilters = z.infer<typeof schema>;

export const MandateRow = ({
    mandate,
    mandateEffect,
    mandateMandateEffect,
    industryGroup,
    companyMandateAssignment,
    onMandateMandateEffectClick,
}: {
    mandate?: Mandate;
    mandateEffect?: MandateEffect;
    mandateMandateEffect?: MandateMandateEffect;
    industryGroup?: IndustryGroup;
    companyMandateAssignment: CompanyMandateAssignment;
    onMandateMandateEffectClick: (mandateMandateEffectId: number) => void;
}) => {
    return (
        <Tr key={companyMandateAssignment.id}>
            <Td style={{ verticalAlign: "top" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ fontWeight: 500 }}>
                        <Link to={`/mandates/${mandate?.id}`}>{mandate?.name}</Link>
                    </div>
                </div>
            </Td>
            <Td>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ color: "gray", fontSize: 14 }}>
                        {mandate?.state && <div>State: {mandate?.state}</div>}
                        {mandate?.counties && mandate.counties.length > 0 && (
                            <div>County: {mandate?.counties.join(", ")}</div>
                        )}
                        {mandate?.cities && mandate.cities.length > 0 && <div>City: {mandate?.cities?.join(", ")}</div>}
                        {mandate?.authority && <div>Authority: {startCase(mandate?.authority)}</div>}
                    </div>
                </div>
            </Td>
            <Td>
                <div className="flex flex-col" style={{ color: "gray" }}>
                    <div>From: {mandate?.date && format(mandate.date, "yyyy-MM-dd")}</div>
                    <div>To: {mandate?.until && format(mandate.until, "yyyy-MM-dd")}</div>
                </div>
            </Td>
            <Td style={{ verticalAlign: "top" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ fontWeight: 500 }}>
                        {startCase(mandateEffect?.effectName || "")} {industryGroup ? `(${industryGroup.name})` : null}
                    </div>
                    <div
                        style={{
                            fontSize: 13,
                            color: "gray",
                            ...(mandateMandateEffect?.id ? { cursor: "pointer" } : {}),
                        }}
                        onClick={
                            mandateMandateEffect?.id
                                ? () => onMandateMandateEffectClick(mandateMandateEffect.id)
                                : undefined
                        }
                    >
                        <ResponsiveEllipsis
                            text={mandateMandateEffect?.description || ""}
                            maxLine="2"
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                        />
                    </div>
                </div>
            </Td>
        </Tr>
    );
};

export const MandateLookupPage = () => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const searchParams = schema.parse(qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {});
    const [mandateMandateEffectId, setMandateEffectId] = useState<number | undefined>();
    const ref = useRef<HTMLDivElement | null>(null);
    const [filters, setFilters] = useState<MandateFilters>(searchParams);
    const [expanded, setExpanded] = useState(JSON.parse(localStorage.getItem("expanded") || "{}"));
    const [sortedBy, setSortedBy] = useState<string>("name");
    const [groupedBy, setGroupedBy] = useState<string>("none");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

    useEffect(() => {
        localStorage.setItem("expanded", JSON.stringify(expanded));
    }, [expanded]);

    const query = useInfiniteQuery({
        queryKey: ["virtualMandateAssignments", { authToken, filters }],
        getNextPageParam: (lastPage: { hasNextPage: boolean; pageNumber: number; rows: any[] }) => {
            return lastPage.hasNextPage ? lastPage.pageNumber + 1 : lastPage.pageNumber;
        },
        keepPreviousData: true,
        queryFn: async ({ pageParam = 0 }) => {
            const limit = 1000;
            const offset = pageParam * limit;
            const response = await getVirtualMandateAssignments({
                authToken,
                filters: {
                    limit,
                    offset,
                    where: {
                        effectIds: filters.effectIds,
                        industryGroupIds: filters.industryGroupIds,
                        states: filters.states,
                        countyIds: filters.countyIds,
                        cityIds: filters.cityIds,
                        authorities: filters.authorities,
                        quarters: {
                            q12020: filters.quarters?.includes(1),
                            q22020: filters.quarters?.includes(2),
                            q32020: filters.quarters?.includes(3),
                            q42020: filters.quarters?.includes(4),
                            q12021: filters.quarters?.includes(5),
                            q22021: filters.quarters?.includes(6),
                            q32021: filters.quarters?.includes(7),
                            q42021: filters.quarters?.includes(8),
                        },
                    },
                },
            });

            const companyMandateAssignments = response.data || [];

            const mandateIds = uniq(companyMandateAssignments.map((assignment) => assignment.mandateId));
            const mandateEffectIds = uniq(companyMandateAssignments.map((a) => a.mandateEffectId));
            const i = companyMandateAssignments.filter((a) => !!a.industryGroupId);
            const industryGroupIds = uniq(i.map((assignment) => assignment.industryGroupId));

            const [mandatesResponse, mandateEffectsResponse, mandateMandateEffectsResponse, industryGroupsResponse] =
                await Promise.all([
                    getMandates({ authToken, filters: { where: { id: { inq: mandateIds } } } }),
                    getMandateEffects({ authToken, filters: { where: { id: { inq: mandateEffectIds } } } }),
                    getMandateMandateEffects({
                        authToken,
                        filters: {
                            where: { mandateId: { inq: mandateIds }, mandateEffectId: { inq: mandateEffectIds } },
                        },
                    }),
                    getIndustryGroups({ authToken, filters: { where: { id: { inq: industryGroupIds } } } }),
                ]);

            const mandates = mandatesResponse.data || [];
            const mandateEffects = mandateEffectsResponse || [];
            const mandateMandateEffects = mandateMandateEffectsResponse || [];
            const industryGroups = industryGroupsResponse || [];

            const rows = companyMandateAssignments.map((cma) => {
                return {
                    companyMandateAssignment: cma,
                    mandate: mandates.find((m) => m.id === cma.mandateId),
                    mandateEffect: mandateEffects.find((me) => me.id === cma.mandateEffectId),
                    mandateMandateEffect: mandateMandateEffects.find(
                        (me) => me.mandateId === cma.mandateId && me.mandateEffectId === cma.mandateEffectId,
                    ),
                    industryGroup: industryGroups.find((ig) => ig.id === cma.industryGroupId),
                };
            });

            return { rows, hasNextPage: true, pageNumber: pageParam };
        },
    });

    const allMandateEffectsQuery = useMandateEffects({ authToken });
    const allMandateEffects = useMemo(() => allMandateEffectsQuery.data || [], [allMandateEffectsQuery.data]);

    const allIndustryGroupsQuery = useIndustryGroups({ authToken });
    const allIndustryGroups = useMemo(() => allIndustryGroupsQuery.data || [], [allIndustryGroupsQuery.data]);

    const quarterOptions = [
        { label: "2020 Q1", value: 1 },
        { label: "2020 Q2", value: 2 },
        { label: "2020 Q3", value: 3 },
        { label: "2020 Q4", value: 4 },
        { label: "2021 Q1", value: 5 },
        { label: "2021 Q2", value: 6 },
        { label: "2021 Q3", value: 7 },
        { label: "2021 Q4", value: 8 },
    ];

    const allRows = useMemo(
        () => query.data?.pages.reduce<any[]>((acc, curr) => [...acc, ...curr.rows], []) || [],
        [query.data?.pages],
    );

    const rows = useMemo(
        () =>
            orderBy(
                allRows,
                (row) => {
                    if (sortedBy === "name") {
                        return row.mandate?.name?.toLocaleLowerCase();
                    }
                    if (sortedBy === "date") {
                        return row.mandate?.date;
                    }
                    if (sortedBy === "state") {
                        return row.mandate?.state?.toLocaleLowerCase();
                    }
                    if (sortedBy === "effect") {
                        return row.mandateEffect?.effectName.toLowerCase();
                    }
                    return row.mandate?.name;
                },
                sortDirection,
            ),
        [allRows, sortDirection, sortedBy],
    );

    const mandateMandateEffectToView = useMemo(
        () => rows.find((mme) => mme.mandateMandateEffect?.id === mandateMandateEffectId)?.mandateMandateEffect,
        [rows, mandateMandateEffectId],
    );

    const groupedRows =
        groupedBy !== "none"
            ? groupBy(rows, (row) => {
                  if (groupedBy === "date") {
                      return format(row.mandate?.date || new Date(), "yyyy-MM-dd");
                  }
                  if (groupedBy === "name") {
                      return row.mandate?.name;
                  }
                  if (groupedBy === "effect") {
                      return row.mandateEffect?.effectName;
                  }
              })
            : [];

    const effectOptions = orderBy(
        allMandateEffects.map((m) => ({ value: m.id, label: `${m.effectName}` })),
        (o) => o.label,
    );

    const industryGroupOptions = orderBy(
        allIndustryGroups.map((ig) => ({ value: ig.id, label: `${ig.name}` })),
        (o) => o.label,
    );

    const form = useForm({ schema, defaultValues: searchParams as any });

    const navigate = useNavigate();

    const handleSubmit = form.handleSubmit((data: MandateFilters) => {
        setFilters(data);
        const queryString = qs.stringify(data, { encode: false, skipNulls: true, arrayFormat: "brackets" });
        navigate(`/mandate-lookup?${queryString}`, { replace: true });
    });

    const formValues = form.watch();
    const formStates: string[] = useMemo(() => (formValues.states as string[]) || [], [formValues.states]);
    const formCounties = useMemo(
        () =>
            ((formValues.countyIds as number[]) || [])
                .map((cId) => counties.find((d) => d.id === cId)?.county)
                .filter((c) => !!c) as string[],
        [formValues.countyIds],
    );

    const applicableCounties = useMemo(
        () => counties.filter((c) => (formStates.length > 0 ? formStates.includes(c.state) : true)),
        [formStates],
    );
    const applicableCities = useMemo(
        () =>
            cities.filter((c) => {
                const inState = formStates.length > 0 ? formStates.includes(c.state) : true;
                const inCounty = formCounties.length > 0 ? formCounties.includes(c.county) : true;
                return inState && inCounty;
            }),
        [formCounties, formStates],
    );

    return (
        <>
            {mandateMandateEffectToView && (
                <MandateEffectModal
                    mandateMandateEffect={mandateMandateEffectToView}
                    onClose={() => setMandateEffectId(undefined)}
                    mandateEffect={
                        allMandateEffects.find((m) => m.id === mandateMandateEffectToView.mandateEffectId)
                            ?.effectName || ""
                    }
                />
            )}
            <div style={{ padding: 12 }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Mandate Lookup</div>
                    <div className="flex flex-row gap-1">
                        <div className="flex flex-row items-center gap-1">
                            <div>Group By</div>
                            <div>
                                <SelectNeoGen
                                    value={groupedBy}
                                    options={[
                                        { id: "none", name: "None" },
                                        { id: "name", name: "Name" },
                                        { id: "date", name: "Date" },
                                        { id: "effect", name: "Effect" },
                                    ]}
                                    onChange={(val) => {
                                        return setGroupedBy(val as string);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-1">
                            <div>Sort By</div>
                            <div style={{ minWidth: 100 }}>
                                <SelectNeoGen
                                    value={sortedBy}
                                    options={[
                                        { id: "name", name: "Name" },
                                        { id: "date", name: "Date" },
                                        { id: "state", name: "State" },
                                        { id: "effect", name: "Effect" },
                                    ]}
                                    onChange={(val) => {
                                        return setSortedBy(val as string);
                                    }}
                                />
                            </div>
                            <div style={{ minWidth: 80 }}>
                                <SelectNeoGen
                                    value={sortDirection}
                                    options={[
                                        { id: "asc", name: "Asc" },
                                        { id: "desc", name: "Desc" },
                                    ]}
                                    onChange={(val) => {
                                        return setSortDirection(val as any);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ padding: 12 }}>
                    <Form onSubmit={handleSubmit}>
                        <div className="flex flex-row gap-4">
                            <div style={{ width: 300 }}>
                                <SelectField
                                    label="Mandate Authority"
                                    {...form.getFieldProps("authorities")}
                                    options={[
                                        { label: "State", value: "State" },
                                        { label: "County", value: "County" },
                                        { label: "City", value: "City" },
                                    ]}
                                    isMultiple
                                />
                            </div>
                        </div>
                        <div className="flex flex-row gap-4">
                            <div className="flex-1">
                                <SelectField
                                    label="States"
                                    {...form.getFieldProps("states")}
                                    options={states.map((s) => ({ value: s.name, label: s.name }))}
                                    isMultiple
                                />
                            </div>
                            <div className="flex-1">
                                <SelectField
                                    label="Counties"
                                    {...form.getFieldProps("countyIds")}
                                    options={applicableCounties.map((c) => ({
                                        value: c.id,
                                        label: `${c.county}, ${c.state}`,
                                    }))}
                                    isMultiple
                                />
                            </div>
                            <div className="flex-1">
                                <SelectField
                                    label="Cities"
                                    {...form.getFieldProps("cityIds")}
                                    options={applicableCities.map((c) => ({
                                        value: c.id,
                                        label: `${c.city}, ${c.county}, ${c.state}`,
                                    }))}
                                    isMultiple
                                />
                            </div>
                        </div>
                        <div className="flex flex-row gap-4">
                            <div className="flex-1">
                                <SelectField
                                    label="Effects"
                                    {...form.getFieldProps("effectIds")}
                                    options={effectOptions}
                                    isMultiple
                                />
                            </div>
                            <div className="flex-1">
                                <SelectField
                                    label="Industry Groups"
                                    {...form.getFieldProps("industryGroupIds")}
                                    options={industryGroupOptions}
                                    isMultiple
                                />
                            </div>
                            <div className="flex-1">
                                <SelectField
                                    label="Quarters"
                                    {...form.getFieldProps("quarters")}
                                    options={quarterOptions}
                                    isMultiple
                                />
                            </div>
                        </div>
                        <ButtonNeoGen type="submit">Apply</ButtonNeoGen>
                    </Form>
                </div>
                <div ref={ref} style={{ height: "calc(100vh - 380px)", overflow: "auto" }}>
                    <Table style={{ tableLayout: "fixed" }}>
                        <THead>
                            <Tr>
                                <Th style={{ width: 220 }}>Mandate</Th>
                                <Th style={{ width: 250 }}>Location</Th>
                                <Th style={{ width: 220 }}>Date</Th>
                                <Th>Effect</Th>
                            </Tr>
                        </THead>
                        <TBody>
                            {groupedBy !== "none" && (
                                <>
                                    {Object.keys(groupedRows).map((key) => {
                                        const rowsToShow = (groupedRows as any)?.[key] || [];
                                        const isExpanded = expanded[key] === true;
                                        return (
                                            <React.Fragment key={key}>
                                                {rowsToShow.length === 0 && (
                                                    <Tr>
                                                        <Td colSpan={5} style={{ textAlign: "center" }}>
                                                            No Mandate Effects
                                                        </Td>
                                                    </Tr>
                                                )}
                                                {rowsToShow.length > 0 && (
                                                    <React.Fragment key={key}>
                                                        <Tr>
                                                            <Td
                                                                colSpan={4}
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setExpanded({
                                                                        ...expanded,
                                                                        [key]: isExpanded ? false : !expanded[key],
                                                                    });
                                                                }}
                                                            >
                                                                <div className="flex flex-row gap-1">
                                                                    <div style={{ width: 20 }}>
                                                                        {!isExpanded ? (
                                                                            <ChevronRightIcon />
                                                                        ) : (
                                                                            <ChevronDownIcon />
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <b>
                                                                            {key} ({rowsToShow.length})
                                                                        </b>
                                                                    </div>
                                                                </div>
                                                            </Td>
                                                        </Tr>
                                                        {isExpanded && (
                                                            <ViewportList
                                                                key={key}
                                                                viewportRef={ref}
                                                                items={rowsToShow}
                                                                renderSpacer={({ ref, style }) => (
                                                                    <Tr ref={ref} style={style} />
                                                                )}
                                                                initialPrerender={25}
                                                                overscan={10}
                                                            >
                                                                {(row: any) => {
                                                                    return (
                                                                        <MandateRow
                                                                            key={row.companyMandateAssignment.id}
                                                                            companyMandateAssignment={
                                                                                row.companyMandateAssignment
                                                                            }
                                                                            industryGroup={row.industryGroup}
                                                                            mandate={row.mandate}
                                                                            mandateEffect={row.mandateEffect}
                                                                            mandateMandateEffect={
                                                                                row.mandateMandateEffect
                                                                            }
                                                                            onMandateMandateEffectClick={(id) =>
                                                                                setMandateEffectId(id)
                                                                            }
                                                                        />
                                                                    );
                                                                }}
                                                            </ViewportList>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </>
                            )}
                            {groupedBy === "none" && (
                                <>
                                    {rows.length === 0 && (
                                        <Tr>
                                            <Td colSpan={4} style={{ textAlign: "center" }}>
                                                No Mandate Effects
                                            </Td>
                                        </Tr>
                                    )}
                                    {rows.length > 0 && (
                                        <ViewportList
                                            viewportRef={ref}
                                            items={rows}
                                            renderSpacer={({ ref, style }) => <Tr ref={ref} style={style} />}
                                            initialPrerender={25}
                                            overscan={10}
                                            itemSize={220}
                                        >
                                            {(row: any) => {
                                                return (
                                                    <MandateRow
                                                        key={row.companyMandateAssignment.id}
                                                        companyMandateAssignment={row.companyMandateAssignment}
                                                        industryGroup={row.industryGroup}
                                                        mandate={row.mandate}
                                                        mandateEffect={row.mandateEffect}
                                                        mandateMandateEffect={row.mandateMandateEffect}
                                                        onMandateMandateEffectClick={(id) => setMandateEffectId(id)}
                                                    />
                                                );
                                            }}
                                        </ViewportList>
                                    )}
                                </>
                            )}
                        </TBody>
                    </Table>
                </div>
            </div>
        </>
    );
};
