import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { mandateMandateEffectSchema } from "../domain/mandate-mandate-effect";

export const getMandateMandateEffect = async ({ authToken, filters }: { authToken: string; filters: any }) => {
    const response = await makeRequest({
        method: "get",
        path: `/mandate-mandate-effects`,
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });
    const mandateMandateEffect = parseSchema(z.array(mandateMandateEffectSchema), response.data);
    return mandateMandateEffect;
};
