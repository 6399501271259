import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { companyMandateAssignmentSchema } from "../domain/company-mandate-assignment";

export const getCompanyMandateAssignments = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/company-mandate-assignments",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const companyMandateAssignments = parseSchema(
        z.array(companyMandateAssignmentSchema),
        response.data.map((data: any) => ({ ...data })),
    );

    return companyMandateAssignments;
};
