import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { mandateEffectSchema } from "../domain/mandate-mandate-effect";

export const deleteMandateMandateEffect = async ({ authToken, id }: { authToken: string; id: number }) => {
    const response = await makeRequest({
        method: "delete",
        path: `/mandate-mandate-effects/${id}`,
        authToken,
    });

    return;
};
