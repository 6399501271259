import APIService from "./api.service";

export type RepairStageHistoryType = {
    id?: number;
    processflow: number;
    stageFrom: number;
    stageTo: number;
    changeWhen: Date;
    syncChange: 0 | 1;
    changeBy: string;
    notes: string;
};

class RepairStageHistoryService extends APIService<RepairStageHistoryType> {
    constructor() {
        super("/repair-stage-histories");
    }
}

export default new RepairStageHistoryService();
