import { useQuery } from "@tanstack/react-query";
import { getMandateMandateEffect } from "../../mandates/actions/get-mandate-mandate-effects";

type Params = {
    authToken: string;
    filters?: any;
};

const getMandateMandateEffectsQueryKey = ({ authToken, filters }: Params) => {
    return ["mandate-mandate-effects", { authToken, filters }];
};

export const useMandateMandateEffects = ({ authToken, filters }: Params, options?: { enabled?: boolean }) => {
    const query = useQuery({
        queryKey: getMandateMandateEffectsQueryKey({ authToken, filters }),
        queryFn: async () => {
            const mandateMandateEffects = await getMandateMandateEffect({ authToken, filters });
            return mandateMandateEffects;
        },
        keepPreviousData: true,
        ...options,
    });
    return query;
};
