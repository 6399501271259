/* eslint-disable react/display-name */
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { forwardRef, InputHTMLAttributes, useState } from "react";
import { formatCurrency } from "../../billing/utils";

export type CurrencyInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "form">;

export const CurrencyInput = forwardRef(
    (props: CurrencyInputProps & { hasError?: boolean; value: number }, ref: any) => {
        const [value, setValue] = useState(props.value !== undefined ? props.value : null);
        const [hasDecimal, setHasDecimal] = useState(false);
        const isControlled = props.value !== undefined;

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const formattedValue = e.target.value;
            const regex = /[+-]?\d+(\.\d*)?/g;

            const v = Number.parseFloat(formattedValue.replace(/,/g, "").match(regex)?.[0] || "0");

            if (props.onChange) {
                const updatedE = { ...e, target: { ...e.target, value: v as any } };
                if (formattedValue.endsWith(".")) {
                    setHasDecimal(true);
                    updatedE.target.value = updatedE.target.value + ".";
                } else {
                    setHasDecimal(false);
                }
                props.onChange(updatedE);
            }

            if (!isControlled) {
                setValue(v);
            }
        };

        const finalFormattedValue =
            (isControlled
                ? formatCurrency(props.value)
                : value !== undefined && value !== null
                ? formatCurrency(value)
                : undefined) + (hasDecimal ? "." : "");

        return (
            <div style={{ position: "relative" }}>
                {props.hasError && (
                    <div className={"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none "}>
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>
                )}
                <input
                    type="text"
                    {...props}
                    onChange={handleChange}
                    value={finalFormattedValue}
                    className={
                        ` dark:bg-gray-800  ${props.disabled ? "bg-gray-100 text-gray-500" : ""} ` +
                        (props.hasError
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-900  dark:border-gray-900 dark:text-gray-300")
                    }
                    ref={ref}
                />
            </div>
        );
    },
);
