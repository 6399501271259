export type PaymentType = object;

import APIService from "./api.service";

class PaymentService extends APIService<PaymentType> {
    constructor() {
        super("make-payment");
    }
    makePayment() {
        return this.postURL("make-payment", {});
    }
}

export default new PaymentService();
