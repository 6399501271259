import { useEffect, useState } from "react";
import ModalDialog from "../../../layout/modal-dialog";
import { useQuery } from "@tanstack/react-query";
import packageSectionRulesService, { PackageSectionRule } from "../../../services/package-section-rules.service";
import { useParams } from "react-router-dom";
import helpDocsService from "../../../services/help-docs.service";
import Toggle from "../../../layout/toggle";
import InputControlled from "../../../layout/input-controlled";
import SelectNeoGen from "../../../layout/select-neogen";
import processflowGroupService from "../../../services/processflow-group.service";
import processflowService from "../../../services/processflow.service";

export default function RulesModal({
    close,
    show,
    entry,
}: {
    close: () => void;
    show: boolean;
    entry: PackageSectionRule | null;
}) {
    const { id } = useParams<{ id: string }>();
    const [isEnabler, setIsEnabler] = useState(entry?.isEnabler || 0);
    const [helpDoc, setHelpDoc] = useState(entry?.helpDoc || 0);
    const [fieldName, setFieldName] = useState(entry?.fieldName || "");
    const [fieldValue, setFieldValue] = useState(entry?.fieldValue || "");
    const [title, setTitle] = useState(entry?.title || "");
    const [processflowGroup, setProcessflowGroup] = useState(7); // TODO: [2021-10-06 Wed 14:06
    const [entries, setEntries] = useState<any[]>([]);
    // const [entries, setEntries] = useState<any[]>([]);
    const processflowGroupQuery = useQuery(["ProcessflowGroup"], async () => {
        const response = await processflowGroupService.getAll();
        if (response) {
            return response.data;
        }
    });
    const rulesQuery = useQuery(
        ["packageSectionRules", id],
        async () => {
            const response = await packageSectionRulesService.getFilteredWhere({ helpDoc: id });
            return response?.data ?? [];
        },
        { enabled: !!id },
    );

    useEffect(() => {
        if (entry) {
            const inEntry = rulesQuery.data?.find((r) => r.id === entry.id);
            setIsEnabler(inEntry?.isEnabler ?? 0);
            setHelpDoc(inEntry?.helpDoc ?? 0);
            setFieldName(inEntry?.fieldName ?? "");
            setFieldValue(inEntry?.fieldValue ?? "");
            setTitle(inEntry?.title ?? "");
        }
    }, [entry, rulesQuery.data]);
    const [processflow, setProcessflow] = useState(7);
    const processflowQuery = useQuery(["Processflow", processflowGroup], async () => {
        const response = await processflowService.getAllByGroupId(processflowGroup);
        if (response) {
            return response.data;
        }
    });
    const helpDocsQuery = useQuery(["helpDocs", id], async () => {
        const response = await helpDocsService.getOne(id ?? -1);
        return response?.data;
    });

    const handleSubmit = async () => {
        if (entry) {
            await packageSectionRulesService.update(entry.id, {
                isEnabler,
                helpDoc: Number(id),
                fieldName,
                fieldValue,
                title,
            });
            await helpDocsQuery.refetch();
            await rulesQuery.refetch();
            close();
            return;
        } else {
            await packageSectionRulesService.create({
                isEnabler,
                helpDoc: Number(id),
                fieldName,
                fieldValue,
                title,
            });
        }
        await helpDocsQuery.refetch();
        await rulesQuery.refetch();
        close();
    };
    useEffect(() => {
        if (processflow) {
            const entry = processflowQuery?.data?.find((p) => p.id === processflow)?.entries;
            if (entry) {
                const entryTemp = JSON.parse(entry);
                console.error({ entryTemp: entryTemp.map((e: any) => e.type) });
                setEntries(
                    entryTemp.filter((e: any) =>
                        [
                            "checkbox",
                            "state",
                            "textarea",
                            "textfield",
                            "selectBox",
                            "industryPicker",
                            "reviewedBy",
                        ].includes(e.type),
                    ),
                );
            }
        }
    }, [processflow, processflowQuery?.data]);

    return (
        <ModalDialog
            close={close}
            show={show}
            title={(entry ? "Edit " : "Add") + " Rule for " + helpDocsQuery.data?.title}
            okAction={handleSubmit}
        >
            {/* <PrintPre>{entry}</PrintPre> */}
            <form onSubmit={handleSubmit}>
                <InputControlled value={title} onChange={setTitle} label="Title" />

                <div className="mb-5">
                    <Toggle
                        options={["Disables The Section", "Enables the Section"]}
                        selectedIndex={isEnabler}
                        setSelectedIndex={setIsEnabler}
                    />
                </div>
                <SelectNeoGen
                    className="mb-5"
                    label="Processflow Group"
                    options={
                        processflowGroupQuery.data?.map((p) => ({
                            name: p.name,
                            id: p.id,
                        })) ?? []
                    }
                    value={processflowGroup}
                    onChange={(idx) => setProcessflowGroup(Number(idx))}
                />
                <SelectNeoGen
                    className="mb-5"
                    label="Processflow"
                    options={
                        processflowQuery.data?.map((p) => ({
                            name: p.title,
                            id: p.id,
                        })) ?? []
                    }
                    value={processflow}
                    onChange={(idx) => setProcessflow(Number(idx))}
                />
                <SelectNeoGen
                    className="mb-5"
                    label="Field"
                    options={
                        entries.map((p) => ({
                            name: p.value,
                            id: p.id,
                        })) ?? []
                    }
                    value={fieldName}
                    onChange={(idx) => setFieldName(String(idx))}
                />
                <InputControlled value={fieldValue} onChange={setFieldValue} label="Field Value" />
            </form>
        </ModalDialog>
    );
}
