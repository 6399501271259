import APIService from "./api.service";

export type CompanyRepairStagesType = {
    id?: number;
    stage: number;
};

class CompanyRepairStages extends APIService<CompanyRepairStagesType> {
    constructor() {
        super("/company-repair-stages");
    }
}

export default new CompanyRepairStages();
