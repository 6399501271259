import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { mandateSchema } from "../domain/mandate";

export const getMandate = async ({ authToken, id }: { authToken: string; id: number }) => {
    const response = await makeRequest({
        method: "get",
        path: `/mandates/${id}`,
        authToken,
    });

    const mandate = parseSchema(mandateSchema, response.data);

    return mandate;
};
