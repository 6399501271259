import "react-tabulator/lib/styles.css";
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import { ColumnDefinition, ReactTabulator } from "react-tabulator";
import { useEffect, useState } from "react";
import ButtonNeoGen from "./button-neogen";
import PrintPre from "./print-pre";
import React from "react";
import AddColumn from "./modals/add-column";
import _ from "lodash";

export default function CustomTable({
    data,
    columns,
    setData,
    setColumns,
}:
    | {
          data: any[];
          columns: ColumnDefinition[];
          setData: (values: any[]) => void;
          setColumns: (columns: ColumnDefinition[]) => void;
      }
    | any) {
    const [internalColumns, setInternalColumns] = useState<ColumnDefinition[]>(columns);
    // const [data, setData] = useState<any[]>([]);
    // const [numColumns, setNumColumns] = useState(1);
    // const [numRows, setNumRows] = useState(1);
    const [showAddColumn, setShowAddColumn] = useState(false);
    let ref = React.useRef<any>();
    // function updateData() {
    //     // setColumns(
    //     //     Array.from(Array(numColumns).keys()).map((i) => {
    //     //         return { title: `col${i}`, field: `col${i}`, editor: "input" };
    //     //     }),
    //     // );
    //     setData(
    //         Array.from(Array(numRows).keys()).map((i) => {
    //             const row = { id: i, bla: 1 };
    //             Array.from(Array(numColumns).keys()).forEach((j) => {
    //                 row[`col${j}` as keyof typeof row] = `col${j} row${i}` as any;
    //             });
    //             return row;
    //         }),
    //     );
    // }

    // useEffect(() => {
    //     console.log(numColumns, numRows);
    //     updateData();
    // }, [numColumns, numRows]);

    // useEffect(() => {
    //     if (value) {
    //         if (!(_.isEqual(columns, value.columns) && _.isEqual(data, value.data))) {
    //             setColumns(value.columns);
    //             setData(value.data);
    //         }
    //     }
    // }, [columns, data, value]);

    // useEffect(() => {
    //     setValue({ columns, data });
    // }, [columns, data]);
    // useEffect(() => {
    //     ref?.current?.table.on("cellClick", () => {
    //         // setValue({ columns, data });
    //         alert("cell edited");
    //     });
    // }, [ref]);
    // useEffect(() => {
    //     alert("Changed " + JSON.stringify(data));
    // }, [data, columns]);

    // useEffect(() => {
    //     ref?.current?.table.on("dataChanged", () => {
    //         // setValue({ columns, data });
    //         alert("data changed");
    //     });
    // }, [columns, data, ref, setValue]);

    const addColumn = (name: string) => {
        // setNumColumns((c) => c + 1);
        // alert("add column: " + name);
        setShowAddColumn(false);
        // setColumns((c) => {
        setColumns([...columns, { title: name, field: name, editor: "input" }]);
        setInternalColumns([...columns, { title: name, field: name, editor: "input" }]);
        setData([...data, { [name]: "Empty" }]);

        //     return [
        //         ...c,
        //         {
        //             title: name,
        //             field: name,
        //             editor: "input",
        //         },
        //     ];
        // });
        // Go through the existing data and add the new column to each row
        // setData((d) => {
        //     return d.map((row) => {
        //         return { ...row, [name]: "Empty" };
        //     });
        // });
        // setValue({ columns, data });
    };

    return (
        <div>
            <ButtonNeoGen
                onClick={() => {
                    // setNumColumns((c) => c + 1);
                    setShowAddColumn(true);
                    // ref?.current?.table.redraw(true);
                }}
            >
                Add Column
            </ButtonNeoGen>
            <ButtonNeoGen
                onClick={() => {
                    // setNumColumns((c) => c + 1);
                    const newColumns = [...columns];
                    newColumns.pop();
                    console.log({ newColumns });
                    setColumns(newColumns);

                    // Remove the last column from each row
                    // setData((d: any) => {
                    //     return d.map((row: any) => {
                    //         const newRow = { ...row };
                    //         delete newRow[newRow.length - 1];
                    //         return newRow;
                    //     });
                    // });
                }}
            >
                Remove Column
            </ButtonNeoGen>
            <ButtonNeoGen
                label="Add Row"
                onClick={() => {
                    // setNumRows((c) => c + 1);
                    // setValue({
                    //     columns: [...value.columns],
                    //     data: [...value.data, { id: value.data.length }],
                    // });
                    setColumns([...columns, { title: name, field: name, editor: "input" }]);
                    setData([...data, { id: data.length }]);
                }}
            >
                Add Row
            </ButtonNeoGen>
            <ButtonNeoGen
                onClick={() => {
                    setData([]);
                }}
            >
                Reset Data
            </ButtonNeoGen>
            <ReactTabulator
                onRef={(r) => (ref = r)}
                data={data ?? []}
                columns={internalColumns ?? []}
                options={{ data: data ?? {}, reactiveData: true }}
                events={{
                    dataChanged: function (dataIn: any) {
                        console.log("dataChanged", data);
                        // if (!(_.isEqual(columns, value.columns) && _.isEqual(data, value.data))) {
                        //     setValue({ columns, data });
                        // }
                        // setValue({
                        //     columns: [...value.columns],
                        //     data: [data],
                        // });
                        setData(dataIn);
                        setColumns(internalColumns);
                    },
                    dataLoaded: function (data: any) {
                        console.log("dataLoaded", data, columns);
                        console.log({ internalColumns });
                        // if (!(_.isEqual(columns, value.columns) && _.isEqual(data, value.data))) {
                        //     setValue({ columns, data });
                        // }
                        // setValue({ columns, data });
                        // setValue({
                        //     columns: [...value.columns],
                        //     data: [data],
                        // });
                    },
                }}
                // autoColumns={true}
                // reactiveData={true}
            />
            <div></div>
            <AddColumn show={showAddColumn} close={() => setShowAddColumn(false)} save={(name) => addColumn(name)} />
        </div>
    );
}
