import { z } from "zod";
import ModalDialog from "../../layout/modal-dialog";
import { Mandate } from "../domain/mandate";
import { TextField } from "../../layout/form/text-field";
import { useForm } from "../../hooks/useForm";
import { Form } from "../../layout/form/form";
import { useMutation } from "@tanstack/react-query";
import { updateMandate } from "../actions/update-mandate";
import { useAuth } from "../../auth/use-auth";
import { DateTimeField } from "../../layout/form/date-field";

const schema = z.object({
    name: z.string().nullish(),
    date: z.coerce.date().nullish(),
});

type Data = z.infer<typeof schema>;

export const EditMandateModal = ({
    onClose,
    mandate,
    onUpdate,
}: {
    onClose: () => void;
    mandate: Mandate;
    onUpdate: (updatedMandate: Mandate) => void;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const form = useForm({ schema, defaultValues: { name: mandate.name, date: mandate.date } });

    const editMandateMutation = useMutation({
        mutationFn: async (data: Data) => {
            const updatedMandate = await updateMandate({ authToken, id: mandate.id, data });
            onUpdate(updatedMandate);
        },
    });

    const handleSubmit = form.handleSubmit((data: Data) => {
        editMandateMutation.mutate(data);
    });

    return (
        <ModalDialog title="Edit Mandate" show close={onClose} okAction={handleSubmit}>
            <div className="pb-4">
                <Form onSubmit={handleSubmit}>
                    <TextField label="Name" {...form.getFieldProps("name")} />
                    <DateTimeField
                        label="Date"
                        {...form.getFieldProps("date")}
                        showTimeInput={false}
                        showTimeSelect={false}
                    />
                </Form>
            </div>
        </ModalDialog>
    );
};
