import { ReactNode } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";

export const EllipseText = (props: any & { children: ReactNode }) => {
    return (
        <span
            {...props}
            style={{
                display: "inline-block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                ...props.style,
            }}
        />
    );
};

export const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);
