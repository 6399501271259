import { makeRequest } from "../../jason-proof-of-concept/shared/utils";

export const deleteCompanyLocation = async ({ authToken, id }: { authToken: string; id: number }) => {
    const response = await makeRequest({
        method: "delete",
        path: `/company-locations/${id}`,
        authToken,
    });

    return;
};
