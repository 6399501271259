import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { TextField } from "../../../layout/form/text-field";
import { TextAreaField } from "../../../layout/form/text-area-field";
import { useMutation } from "@tanstack/react-query";
import { createCompanyLocation } from "../../../company-locations/actions/create-company-location";
import { useAuth } from "../../../auth/use-auth";
import { CompanyLocation } from "../../../company-locations/domain/company-location";
import { updateCompanyLocation } from "../../../company-locations/actions/update-company-location";
import { NumberField } from "../../../layout/form/number-field";

const schema = z.object({
    city: z.string().nullish(),
    state: z.string(),
    notes: z.string().nullish(),
    percentOfBusiness: z.number(),
});

type Data = z.infer<typeof schema>;

export const EditCompanyLocationModal = ({
    onClose,
    onUpdate,
    companyId,
    companyLocation,
}: {
    onClose: () => void;
    onUpdate: (location: CompanyLocation) => void;
    companyId: number;
    companyLocation: CompanyLocation;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const form = useForm({
        schema,
        defaultValues: {
            city: companyLocation?.city,
            state: companyLocation?.state || undefined,
            notes: companyLocation?.notes,
            percentOfBusiness: companyLocation?.percentOfBusiness || undefined,
        },
    });

    const mutation = useMutation({
        mutationFn: async (data: Data) => {
            const updatedCompanyLocation = await updateCompanyLocation({
                authToken,
                id: companyLocation.id,
                data: { ...data, companyId },
            });
            return updatedCompanyLocation;
        },
    });

    const handleSubmit = form.handleSubmit(async (data) => {
        const location = await mutation.mutateAsync(data);
        onUpdate(location);
    });

    return (
        <Form onSubmit={handleSubmit} error={mutation.error as any}>
            <ModalDialog title="Edit Location" show close={onClose} okAction={handleSubmit} okText="Update Location">
                <TextField label="City" {...form.getFieldProps("city")} />
                <TextField label="State" {...form.getFieldProps("state")} />
                <NumberField label="Percent of Business" {...form.getFieldProps("percentOfBusiness")} />
                <TextAreaField label="Notes" {...form.getFieldProps("notes")} />
            </ModalDialog>
        </Form>
    );
};
