import APIService from "./api.service";

export type RepairStagesType = {
    id?: number;
    title: string;
    description: string;
    isPublic: number;
    isAccountant: number;
    parent: number;
    requiredRoleGroup: number;
};

class RepairStageService extends APIService<RepairStagesType> {
    constructor() {
        super("/repair-stages");
    }
}

export default new RepairStageService();
